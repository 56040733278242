import slugify from 'slugify';

export function createSlug(text: string): string {
  return slugify(text, {
    locale: 'ru',
    lower: true,
    replacement: '-',
    strict: true,
    trim: true,
  });
}
