import React, { FC } from 'react';

import { Grid } from '~atoms';
import { Big } from '~postCards';
import { GridLayoutProps } from '~types';

export const OneBig: FC<GridLayoutProps> = ({ posts = [] }) => (
  <Grid
    $margin="auto"
    $maxWidth="1200px"
    $width="100%"
    $display="flex"
    $flexFlow="column"
    $gap="30px"
    $padding="0"
    $zIndex="10"
    $xl={{ $maxWidth: '100%', $padding: '0 15px' }}
  >
    {posts.slice(0, 1).map((post) => (
      <Big key={post.id} post={post} />
    ))}
  </Grid>
);
