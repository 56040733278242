import classnames from 'classnames';
import { useRouter } from 'next/router';
import React, { ChangeEventHandler, FC, KeyboardEventHandler } from 'react';

import { SearchIconSmall } from '~assets';
import { Grid } from '~atoms';
import { useLayout } from '~hooks';

import styles from './styles.module.scss';

interface Props {
  autoFocus?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  value: string;
}

export const SearchInput: FC<Props> = ({ autoFocus = false, onChange, onKeyDown, value }) => {
  const { isBlackScience, isSport, isTest } = useLayout();

  const className = classnames(
    isBlackScience ? styles.blackscience : undefined,
    isSport ? styles.sport : undefined,
    isTest ? styles.test : undefined,
  );

  const { pathname } = useRouter();
  const isSearchPage = pathname.search('search') >= 1;

  return (
    <Grid
      $display="grid"
      $gridTemplateColumns="auto min-content"
      $gap="10px"
      $margin={isSearchPage ? '10px 0 30px' : '42px 0'}
      className={className}
      $sm={{ $alignItems: 'center', $margin: '0' }}
    >
      <Grid
        $display="grid"
        $gridTemplateColumns="min-content auto"
        $gap="20px"
        $color={isBlackScience || isSport ? 'white' : 'black'}
        className={styles.input}
      >
        <SearchIconSmall className={styles.icon} />
        <input
          name="term"
          placeholder="поиск"
          autoFocus={autoFocus}
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
          autoComplete="off"
        />
      </Grid>
    </Grid>
  );
};
