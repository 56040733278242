export function toBase64(input: string): string {
  if (typeof Buffer !== 'undefined') {
    return Buffer.from(input).toString('base64');
  }

  if (typeof btoa !== 'undefined') {
    return btoa(input);
  }

  return input;
}
