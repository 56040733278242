import pino, { LoggerOptions } from 'pino';
import { logflarePinoVercel } from 'pino-logflare';

import { config, isDev } from './config';

const { send, stream } = logflarePinoVercel({
  apiKey: config.LOGFLARE_API_KEY,
  sourceToken: config.LOGFLARE_TOKEN,
});

const transportConfig = {};

if (isDev) {
  Object.assign(transportConfig, {
    transport: {
      options: {
        colorize: true,
      },
      target: 'pino-pretty',
    },
  });
}

export const logger = pino({
  base: {
    env: config.NODE_ENV,
    revision: config.VERSION,
  },
  browser: {
    transmit: {
      level: isDev ? 'debug' : 'info',
      send: (level, logEvent) => {
        const headers = {
          'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
          'Access-Control-Allow-Origin': '*',
          type: 'application/json',
        };
        const blob = new Blob([JSON.stringify({ level, messages: logEvent.messages })], headers);
        // ToDo Support logging
        // navigator.sendBeacon(`http://localhost:3000/log`, blob);
      },
    },
  },
  level: isDev ? 'debug' : 'info',
  ...transportConfig,
});
