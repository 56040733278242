import { BlockType } from 'pn-backend';
import React, { FC } from 'react';

import { Grid, H2 } from '~atoms';
import { Tooltips } from '~components';
import { BlockProps } from '~types';

const defaultProps = {};

export const CardTitleBlock: FC<BlockProps<BlockType.H2>> = ({ compiled: { html } }) => {
  if (!html) return null;

  return (
    <Grid {...defaultProps}>
      <Tooltips html={html} />
      <H2
        $textTransform="uppercase"
        $size={20}
        $lineHeight={24}
        $weight={600}
        $xs={{ $hyphens: 'auto' }}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </Grid>
  );
};
