import Link from 'next/link';
import { useRouter } from 'next/router';
import { SectionPublic } from 'pn-backend';
import { not, propEq } from 'ramda';
import React, { FC, useContext, useState } from 'react';

import { Close, Logo, SearchIcon } from '~assets';
import { Badge, Grid, Header } from '~atoms';
import { MOBILE_HEADER_HEIGHT } from '~constants';
import { useLayout, useSection } from '~hooks';
import { StateContext } from '~providers';
import { getSectionLink } from '~utils';

import { Socials } from '../../../Socials';
import { SearchLine } from '../../SearchLine';
import { HeaderMobile } from '../HeaderMobile';

const isShownInMenu = propEq<keyof SectionPublic>('isShownInMenu', true);

export const HeaderWithBigLogo: FC = () => {
  const {
    state: { sections = [] },
  } = useContext(StateContext);
  const { currentSection } = useSection();
  const { isMain } = useLayout();
  const [isOpenSearch, setIsOpenSearch] = useState(false);

  const { pathname } = useRouter();
  const isSearchPage = pathname.search('search') >= 1;

  const toggleSearch = () => {
    if (!isSearchPage) setIsOpenSearch(not);
  };
  const closeSearch = () => {
    if (!isSearchPage) setIsOpenSearch(false);
  };

  const visibleInMenu = sections.filter((section) => section.isShownInMenu);

  return (
    <>
      <Grid $width="1286px" $background="white" $zIndex={1000} $margin="0 auto">
        <Grid
          $marginTop="30px"
          $width="1200px"
          $height="154px"
          $color="black"
          $margin="0 auto"
          $hoverColor={!isMain ? 'black-08' : 'black'}
          $transition="0.2s ease-in-out"
          $xl={{ $display: 'none' }}
        >
          <Link href="/" passHref>
            <Logo />
          </Link>
        </Grid>
      </Grid>

      <Header $width="1286px" $margin="0 auto" $xl={{ $display: 'none' }}>
        <Grid
          $gridAutoFlow="column"
          $margin="auto"
          $width="1200px"
          $justifyContent="space-between"
          $gridAutoColumns="1fr"
          $alignItems="center"
          $gap="40px"
          $xl={{ $maxWidth: '768px', $padding: '0 15px' }}
          $sm={{ $height: MOBILE_HEADER_HEIGHT }}
        >
          <Grid $width="inherit" $display="block" $transition="all 0.2s ease-in-out" $gap="18px">
            <Grid
              $justifyContent="space-between"
              $alignItems="center"
              $gridArea="logo"
              $sm={{
                $alignItems: 'center',
                $display: 'flex',
                $height: MOBILE_HEADER_HEIGHT,
                $justifyContent: 'center',
                $width: '100%',
              }}
            />

            <Grid
              $display="flex"
              $overflow="hidden"
              $gridAutoFlow="column"
              $gap="8px"
              $marginLeft="-15px"
              $alignItems="center"
              $padding="16px 0"
              $justifyContent="space-between"
            >
              <Grid $overflow="hidden" $display="flex" $justifyContent="flex-start" $gap="5px">
                {visibleInMenu
                  .slice(0, 7)
                  .filter(isShownInMenu)
                  .map((section) => (
                    <Badge
                      key={section.alias}
                      $selected={section.alias === currentSection?.alias}
                      $textTransform="lowercase"
                      $fontSize="24px"
                      $lineHeight="26px"
                    >
                      <Link href={getSectionLink(section.alias)} passHref>
                        {section.title}
                      </Link>
                    </Badge>
                  ))}
              </Grid>
              <Grid $display="flex" $justifySelf="flex-end">
                <Socials inverse inHeader />
                <Grid $margin="0 0 0 10px" $cursor="pointer" $userSelect="none">
                  <Grid
                    $transform={isOpenSearch ? 'scale(0)' : 'scale(1)'}
                    $opacity={isOpenSearch ? 0 : 1}
                    $position={isOpenSearch ? 'absolute' : 'relative'}
                    $transition="0.2s ease-in-out"
                  >
                    <SearchIcon id="searchIcon" onClick={toggleSearch} />
                  </Grid>
                  <Grid
                    $width="32px"
                    $transform={isOpenSearch ? 'scale(1)' : 'scale(0)'}
                    $opacity={isOpenSearch ? 1 : 0}
                    $position={isOpenSearch ? 'relative' : 'absolute'}
                    $zIndex={isOpenSearch ? '-10' : '10'}
                    $transition="0.2s ease-in-out"
                  >
                    <Close onClick={toggleSearch} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <SearchLine isOpen={isOpenSearch} closeSearch={closeSearch} />
      </Header>

      <HeaderMobile />
    </>
  );
};
