import React, { FC } from 'react';

import { Grid } from '~atoms';
import { SpecialProject } from '~postCards';
import { GridLayoutProps } from '~types';

const defaultProps = {
  $margin: 'auto',
  $maxWidth: '1200px',
  $width: '100%',
};

export const SomeSpecProjects: FC<GridLayoutProps> = ({ posts = [] }) => (
  <Grid
    {...defaultProps}
    $gap="20px"
    $zIndex="10"
    $xl={{ $maxWidth: '100%', $padding: '0 15px' }}
    $lg={{ $gap: '30px' }}
    $sm={{ $gap: '10px' }}
  >
    {posts.map((post) => (
      <SpecialProject post={post} key={post.id} />
    ))}
  </Grid>
);
