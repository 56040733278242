import React, { FC } from 'react';

import { Grid, Text } from '~atoms';
import { WatchAll } from '~components';
import { DEFAULT_GRID_PROPS, SectionAlias } from '~constants';
import { SubsectionCard } from '~postCards';
import { ButtonType, GridLayoutProps } from '~types';
import { getSectionLink } from '~utils';

// ToDo Точно такой же как и BlackScience
export const MainOnSport: FC<GridLayoutProps> = ({ posts = [], title }) => (
  <Grid
    $background="gray-light"
    $margin="30px -50vw 20px"
    $zIndex="10"
    $width="100vw"
    $left="50%"
    $right="50%"
    $position="relative"
    $xl={{ $maxWidth: '100%', $padding: '0 15px' }}
    $lg={{ $left: 'unset', $margin: '20px 0 10px', $padding: '0', $right: 'unset' }}
    $sm={{ $margin: '0' }}
  >
    <Grid
      {...DEFAULT_GRID_PROPS}
      $padding="30px 0 60px"
      $gridTemplateAreas={'"title link" "posts posts"'}
      $lg={{ $padding: '30px 15px 30px 15px' }}
      $sm={{ $gridTemplateAreas: '"title" "posts" "link"', $padding: '20px 15px 30px 15px' }}
    >
      <Grid
        $gridAutoFlow="column"
        $justifyContent="space-between"
        $alignItems="center"
        $padding="0 0 30px 0"
        $gridArea="title"
        $sm={{ $padding: '0 0 20px 0' }}
      >
        <Text
          $size={32}
          $lineHeight={35}
          $weight={600}
          $textTransform="uppercase"
          $lg={{ $size: 30 }}
          $sm={{ $size: 26 }}
        >
          {title}
        </Text>
      </Grid>
      <Grid
        $gridArea="link"
        $justifyContent="flex-end"
        $alignItems="flex-start"
        $sm={{
          $alignItems: 'center',
          $justifyContent: 'center',
          $padding: '30px 0 0',
        }}
      >
        <WatchAll type={ButtonType.SPORT} href={getSectionLink(SectionAlias.OnSport)} />
      </Grid>
      <Grid
        $gridArea="posts"
        $columnGap="20px"
        $gridTemplateColumns="1fr 1fr"
        $lg={{ $columnGap: '10px' }}
        $sm={{ $gap: '20px', $gridTemplateColumns: '1fr' }}
      >
        {posts.slice(0, 2).map((post) => (
          <SubsectionCard key={post.id} post={post} mainSport />
        ))}
      </Grid>
    </Grid>
  </Grid>
);
