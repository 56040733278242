import React, { useEffect } from 'react';

import { Grid } from '~atoms';

export const SMI2 = () => {
  useEffect(() => {
    const sc = document.createElement('script');
    sc.type = 'text/javascript';
    sc.async = true;
    sc.src = '//smi2.ru/data/js/102353.js';
    const s = document.getElementsByTagName('script')[0];
    if (s.parentNode) s.parentNode.insertBefore(sc, s);
  }, []);
  return (
    <Grid
      id="unit_102353"
      $width="100%"
      $background="#F0F0F0"
      $maxWidth="1200px"
      $margin="0 auto !important"
    />
  );
};
