import { BlockType } from 'pn-backend';
import React, { FC } from 'react';

import { usePost } from '~hooks';
import { Meta } from '~meta';
import { BlockProps } from '~types';
import { getFirstString, getPostSummary } from '~utils';

export const SeoTitleBlock: FC<BlockProps<BlockType.SeoTitle>> = ({ compiled: { text } }) => {
  const { post } = usePost();

  const {
    modifiedAt,
    postAuthors,
    postId,
    postIntro,
    postShareCover,
    postSubtitle,
    postTags,
    postText,
    postTitle,
    publishedAt,
  } = getPostSummary(post);

  const introFirstString = getFirstString(postIntro).trim();
  const textFirstString = getFirstString(postText).trim();

  const title = text.trim().length > 0 ? text : postTitle;
  const ogTitle = postTitle;

  const description = postSubtitle.trim() || introFirstString || textFirstString;

  return (
    <Meta
      isPublication
      id={postId}
      title={title}
      ogTitle={ogTitle}
      tags={postTags}
      authors={postAuthors}
      description={description}
      image={postShareCover}
      publishedAt={publishedAt}
      modifiedAt={modifiedAt}
    />
  );
};
