import { BlockType, ColumnsTextPosition } from 'pn-backend';
import React, { FC } from 'react';

import { Grid, Image, Text } from '~atoms';
import { Tooltips } from '~components';
import { BlockProps } from '~types';

const defaultProps = {
  $columnGap: '30px',
  $gridTemplateColumns: '1fr 1fr',
  $margin: 'auto',
  $marginTop: '40px',
  $maxWidth: '1200px',
  $width: '100%',
};

export const ColumnsBlock: FC<BlockProps<BlockType.Columns>> = ({
  compiled: { html, images, textPosition, title },
}) => {
  const areas =
    textPosition === ColumnsTextPosition.Left
      ? '"text image" "text image"'
      : '"image text" "image text"';

  const images2max = images.slice(0, 2);

  return (
    <Grid
      {...defaultProps}
      $gridTemplateAreas={areas}
      $xl={{
        $gridTemplateAreas: '"text" "image"',
        $gridTemplateColumns: '1fr',
        $maxWidth: '768px',
        $padding: '0 15px',
      }}
    >
      <Grid
        $gridArea="image"
        $rowGap="25px"
        $position="relative"
        $minHeight="678px"
        $xl={{
          $height: '864px',
          $marginTop: '40px',
        }}
        $sm={{
          $height: '404px',
          $marginTop: '30px',
          $minHeight: 'unset',
        }}
      >
        {images2max?.map(({ caption, imageURL }, i) => {
          return (
            <Grid key={i} $position="relative" $imageObjectFit="cover">
              <Image src={imageURL} alt={caption || ''} />
              <Grid $position="absolute" $left="10px" $bottom="5px">
                <Text $weight={400} $size={12} $lineHeight={17} $color="gray-light" $textWithLink>
                  {caption}
                </Text>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      <Grid $gridArea="text" $display="flex" $flexDirection="column" $position="relative">
        <Grid>
          <Text
            $weight={600}
            $size={30}
            $lineHeight={36}
            $color="black"
            $textWithLink
            $sm={{ $lineHeight: 29, $size: 24 }}
          >
            {title}
          </Text>
        </Grid>
        <Grid $marginTop="20px">
          <Tooltips html={html} />
          <Text
            $weight={400}
            $size={20}
            $lineHeight={30}
            $color="black"
            $textWithLink
            $sm={{ $lineHeight: 27, $size: 18 }}
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
