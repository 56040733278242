export enum ImageFormat {
  // Height: 750px
  ContentHeight = 'contentHeight',
  // Width: 750px
  ContentWidth = 'contentWidth',
  Fullscreen = 'fullscreen',
  // Height: 450px
  HalfContentHeight = 'halfContentHeight',
  // Width: 1200px
  HeaderWidth = 'headerWidth',
}
