// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { FC, useLayoutEffect } from 'react';

export const AdvBlockLongMain: FC = () => {
  useLayoutEffect(() => {
    window.yaContextCb?.push(() => {
      window.Ya?.adfoxCode.create({
        containerId: 'adfox_168932731884975728',
        ownerId: 1479532,
        params: {
          p1: 'cyqxt',
          p2: 'ilut',
        },
      });
    });
  }, []);

  // yandex_pn.glavred
  // Площадка: Postnews / Главная / Главная - Хэдер 1440х250
  // Категория: <не задана>
  // Тип баннера: Главная - Хэдер 1440х250

  return <div id="adfox_168932731884975728" />;
};
