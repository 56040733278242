import Link from 'next/link';
import { BlockType, CoverFormat, PostContentCompiled, PostType } from 'pn-backend';
import { not } from 'ramda';
import React, { FC, useEffect, useState } from 'react';

import { Grid, Tag, Text } from '~atoms';
import { usePost } from '~hooks';
import {
  CoverBlock,
  DescriptionBlock,
  H1Block,
  MetaBlock,
  SeoTitleBlock,
  SubTitleBlock,
} from '~postBlocks';
import { BlockProps } from '~types';
import {
  formatFullDate,
  getPostSummary,
  isCoverBlock,
  isDefined,
  isDescriptionBlock,
  isEmptyString,
  isH1Block,
  isMetaBlock,
  isSeoTitleBlock,
  isSubtitleBlock,
} from '~utils';

export const ArticleCoverBlock: FC<
  PostContentCompiled<PostType.Longread | PostType.Article | PostType.NewsFull>
> = ({ blocks }) => {
  const { post } = usePost();
  const { isModificationDateVisible, modifiedAt, publishedAt, tagLink, tagTitle } =
    getPostSummary(post);

  const [currentPublishedAt, setCurrentPublishedAt] = useState<Nullable<string>>(null);
  const [currentModifiedAt, setCurrentModifiedAt] = useState<Nullable<string>>(null);

  const h1Block = blocks.find(isH1Block);
  const metaBlock = blocks.find(isMetaBlock);
  const seoTitleBlock = blocks.find(isSeoTitleBlock);
  const descriptionBlock = blocks.find(isDescriptionBlock);
  const subtitleBlock = blocks.find(isSubtitleBlock);
  const coverBlock = blocks.find(isCoverBlock);

  let renderType = CoverFormat.CaptionAbove;
  let isCoverVisible = true;

  if (coverBlock) {
    const { format, hideInPost, imageURL } = coverBlock.compiled;
    if (format) renderType = format;
    if (hideInPost || !imageURL) {
      renderType = CoverFormat.CaptionAbove;
      isCoverVisible = false;
    }
  } else {
    renderType = CoverFormat.CaptionAbove;
    isCoverVisible = false;
  }

  useEffect(() => {
    setCurrentPublishedAt(publishedAt);
    setCurrentModifiedAt(modifiedAt);
  }, [publishedAt, modifiedAt]);

  switch (renderType) {
    case CoverFormat.CaptionAbove:
      return (
        <>
          {metaBlock && <MetaBlock {...metaBlock} />}
          {seoTitleBlock && <SeoTitleBlock {...seoTitleBlock} />}
          {descriptionBlock && <DescriptionBlock {...descriptionBlock} />}

          <Grid
            $maxWidth="750px"
            $width="100%"
            $margin="30px auto 0"
            $display="flex"
            $flexDirection="column"
            $justifyContent="space-between"
            $alignItems="center"
            $sm={{ $padding: '0' }}
          >
            <Grid
              $display="flex"
              $width="100%"
              $alignItems="center"
              $padding="0"
              $sm={{
                $alignItems: 'flex-start',
                $display: 'flex',
                $flexDirection: 'column',
                $padding: '0 15px',
              }}
            >
              {tagLink && (
                <Link href={tagLink} passHref legacyBehavior>
                  <Tag
                    $transparent
                    $position="relative"
                    $top="unset"
                    $right="unset"
                    $sm={{ $fontSize: '14px', $padding: '3px 11px' }}
                  >
                    {tagTitle}
                  </Tag>
                </Link>
              )}

              <Grid
                $gridAutoFlow="column"
                $alignItems="center"
                $gap="5px"
                $sm={{ $gap: '3px', $marginBottom: '0', $width: '100%' }}
              >
                {isDefined(currentPublishedAt) && not(isEmptyString(currentPublishedAt)) && (
                  <Grid $marginLeft={tagLink ? '15px' : '0'} $sm={{ $margin: '20px 0 0' }}>
                    <Text $size={14} $weight={500} $lineHeight={17} $textTransform="uppercase">
                      {formatFullDate(currentPublishedAt)}
                    </Text>
                  </Grid>
                )}
                {isModificationDateVisible &&
                  isDefined(currentModifiedAt) &&
                  not(isEmptyString(currentModifiedAt)) && (
                    <>
                      <Grid $sm={{ $margin: '20px 0 0' }}>
                        <Text $display="inline-flex" $alignItems="center">
                          &#8226;
                        </Text>
                      </Grid>
                      <Grid $sm={{ $margin: '20px 0 0' }}>
                        <Text
                          $size={14}
                          $lineHeight={17}
                          $weight={500}
                          $textTransform="uppercase"
                          $maxWidth="min-content"
                        >
                          UPD:
                        </Text>
                      </Grid>
                      <Grid $sm={{ $margin: '20px 0 0' }}>
                        <Text $size={14} $weight={500} $lineHeight={17} $textTransform="uppercase">
                          {formatFullDate(currentModifiedAt)}
                        </Text>
                      </Grid>
                    </>
                  )}
              </Grid>
            </Grid>

            {h1Block && (
              <Grid $width="100%" $sm={{ $margin: '0 15px', $width: 'calc(100% - 30px)' }}>
                <H1Block {...h1Block} renderType={renderType} />
              </Grid>
            )}
            {subtitleBlock && (
              <Grid
                $width="100%"
                $margin={isCoverVisible ? '0 0 20px' : '0'}
                $sm={{ $margin: '0' }}
              >
                <SubTitleBlock {...subtitleBlock} />
              </Grid>
            )}

            {coverBlock && isCoverVisible && <CoverBlock {...coverBlock} />}
          </Grid>
        </>
      );

    case CoverFormat.CaptionUnder:
      return (
        <>
          {metaBlock && <MetaBlock {...metaBlock} />}
          {seoTitleBlock && <SeoTitleBlock {...seoTitleBlock} />}
          {descriptionBlock && <DescriptionBlock {...descriptionBlock} />}

          <Grid
            $maxWidth="750px"
            $width="100%"
            $margin="30px auto 0"
            $display="flex"
            $flexDirection="column"
            $justifyContent="space-between"
            $alignItems="center"
            $sm={{ $padding: '0' }}
          >
            <Grid
              $display="flex"
              $width="100%"
              $alignItems="center"
              $padding="0 0 20px"
              $sm={{
                $alignItems: 'flex-start',
                $display: 'flex',
                $flexDirection: 'column',
                $padding: '0 15px',
              }}
            >
              {tagLink && (
                <Link href={tagLink} passHref legacyBehavior>
                  <Tag
                    $transparent
                    $position="relative"
                    $top="unset"
                    $right="unset"
                    $sm={{ $fontSize: '14px', $padding: '3px 11px' }}
                  >
                    {tagTitle}
                  </Tag>
                </Link>
              )}

              <Grid
                $gridAutoFlow="column"
                $alignItems="center"
                $gap="5px"
                $sm={{ $gap: '3px', $marginBottom: '0', $width: '100%' }}
              >
                {isDefined(currentPublishedAt) && not(isEmptyString(currentPublishedAt)) && (
                  <Grid $marginLeft={tagLink ? '15px' : '0'} $sm={{ $margin: '20px 0 0' }}>
                    <Text $size={14} $weight={500} $lineHeight={17} $textTransform="uppercase">
                      {formatFullDate(currentPublishedAt)}
                    </Text>
                  </Grid>
                )}
                {isModificationDateVisible &&
                  isDefined(currentModifiedAt) &&
                  not(isEmptyString(currentModifiedAt)) && (
                    <>
                      <Grid $sm={{ $margin: '20px 0 0' }}>
                        <Text $display="inline-flex" $alignItems="center">
                          &#8226;
                        </Text>
                      </Grid>
                      <Grid $sm={{ $margin: '20px 0 0' }}>
                        <Text
                          $size={14}
                          $lineHeight={17}
                          $weight={500}
                          $textTransform="uppercase"
                          $maxWidth="min-content"
                        >
                          UPD:
                        </Text>
                      </Grid>
                      <Grid $sm={{ $margin: '20px 0 0' }}>
                        <Text $size={14} $weight={500} $lineHeight={17} $textTransform="uppercase">
                          {formatFullDate(currentModifiedAt)}
                        </Text>
                      </Grid>
                    </>
                  )}
              </Grid>
            </Grid>

            {coverBlock && isCoverVisible && <CoverBlock {...coverBlock} />}

            {h1Block && (
              <Grid $width="100%" $sm={{ $margin: '0 15px', $width: 'calc(100% - 30px)' }}>
                <H1Block {...h1Block} renderType={renderType} />
              </Grid>
            )}

            {subtitleBlock && <SubTitleBlock {...subtitleBlock} />}
          </Grid>
        </>
      );

    default:
      return null;
  }
};
