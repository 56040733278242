/* eslint-disable import/no-cycle */
import { BlockType, PostType } from 'pn-backend';

import {
  CardBlock,
  ColumnsBlock,
  CoverBlock,
  DescriptionBlock,
  GalleryBlock,
  GifBlock,
  H1Block,
  H2Block,
  H3Block,
  ImageBlock,
  IncutBlock,
  IntroBlock,
  LineBlock,
  ListBlock,
  MetaBlock,
  MoreOnTopicBlock,
  PreviewBlock,
  QuizBlock,
  QuoteBlock,
  SeoTitleBlock,
  SubTitleBlock,
  TestBlock,
  TestQuestionBlock,
  TestResultsBlock,
  TextBlock,
  VideoBlock,
  WidgetBlock,
} from '~postBlocks';
import { PostTypeToBlockComponentMap } from '~types';

export const articleBlockMap: PostTypeToBlockComponentMap<PostType.Article> = {
  [BlockType.Cover]: CoverBlock,
  [BlockType.Description]: DescriptionBlock,
  [BlockType.Gallery]: GalleryBlock,
  [BlockType.Gif]: GifBlock,
  [BlockType.H1]: H1Block,
  [BlockType.H2]: H2Block,
  [BlockType.H3]: H3Block,
  [BlockType.Image]: ImageBlock,
  [BlockType.Incut]: IncutBlock,
  [BlockType.Intro]: IntroBlock,
  [BlockType.List]: ListBlock,
  [BlockType.Meta]: MetaBlock,
  [BlockType.MoreOnTopic]: MoreOnTopicBlock,
  [BlockType.Preview]: PreviewBlock,
  [BlockType.Quiz]: QuizBlock,
  [BlockType.Quote]: QuoteBlock,
  [BlockType.SeoTitle]: SeoTitleBlock,
  [BlockType.Subtitle]: SubTitleBlock,
  [BlockType.Text]: TextBlock,
  [BlockType.Video]: VideoBlock,
  [BlockType.Widget]: WidgetBlock,
};

export const cardBlockMap: PostTypeToBlockComponentMap<PostType.Card> = {
  [BlockType.Card]: CardBlock,
  [BlockType.Cover]: CoverBlock,
  [BlockType.Description]: DescriptionBlock,
  [BlockType.H1]: H1Block,
  [BlockType.Intro]: IntroBlock,
  [BlockType.Meta]: MetaBlock,
  [BlockType.Preview]: PreviewBlock,
  [BlockType.SeoTitle]: SeoTitleBlock,
  [BlockType.Subtitle]: SubTitleBlock,
};

export const customBlockMap: PostTypeToBlockComponentMap<PostType.Custom> = {
  [BlockType.Columns]: ColumnsBlock,
  [BlockType.Description]: DescriptionBlock,
  [BlockType.Gallery]: GalleryBlock,
  [BlockType.Gif]: GifBlock,
  [BlockType.H1]: H1Block,
  [BlockType.H2]: H2Block,
  [BlockType.H3]: H3Block,
  [BlockType.Image]: ImageBlock,
  [BlockType.Incut]: IncutBlock,
  [BlockType.Intro]: IntroBlock,
  [BlockType.Line]: LineBlock,
  [BlockType.List]: ListBlock,
  [BlockType.Quote]: QuoteBlock,
  [BlockType.SeoTitle]: SeoTitleBlock,
  [BlockType.Subtitle]: SubTitleBlock,
  [BlockType.Text]: TextBlock,
  [BlockType.Video]: VideoBlock,
  [BlockType.Widget]: WidgetBlock,
};

export const longreadBlockMap: PostTypeToBlockComponentMap<PostType.Longread> = {
  [BlockType.Columns]: ColumnsBlock,
  [BlockType.Cover]: CoverBlock,
  [BlockType.Description]: DescriptionBlock,
  [BlockType.Gallery]: GalleryBlock,
  [BlockType.Gif]: GifBlock,
  [BlockType.H1]: H1Block,
  [BlockType.H2]: H2Block,
  [BlockType.H3]: H3Block,
  [BlockType.Image]: ImageBlock,
  [BlockType.Incut]: IncutBlock,
  [BlockType.Intro]: IntroBlock,
  [BlockType.Line]: LineBlock,
  [BlockType.List]: ListBlock,
  [BlockType.Meta]: MetaBlock,
  [BlockType.MoreOnTopic]: MoreOnTopicBlock,
  [BlockType.Preview]: PreviewBlock,
  [BlockType.Quiz]: QuizBlock,
  [BlockType.Quote]: QuoteBlock,
  [BlockType.SeoTitle]: SeoTitleBlock,
  [BlockType.Subtitle]: SubTitleBlock,
  [BlockType.Text]: TextBlock,
  [BlockType.Video]: VideoBlock,
  [BlockType.Widget]: WidgetBlock,
};

export const testsBlockMap: PostTypeToBlockComponentMap<PostType.Test> = {
  [BlockType.Cover]: CoverBlock,
  [BlockType.Description]: DescriptionBlock,
  [BlockType.H1]: H1Block,
  [BlockType.Intro]: IntroBlock,
  [BlockType.Meta]: MetaBlock,
  [BlockType.Preview]: PreviewBlock,
  [BlockType.SeoTitle]: SeoTitleBlock,
  [BlockType.Subtitle]: SubTitleBlock,
  [BlockType.Test]: TestBlock,
  [BlockType.TestQuestion]: TestQuestionBlock,
  [BlockType.TestResults]: TestResultsBlock,
};
