import { useLayoutEffect, useState } from 'react';

// Нужно, чтобы избежать лагания при переходе с главной. useLayout запаздывает
export const useIsMain = () => {
  const [isClient, setClient] = useState<boolean>(false);

  useLayoutEffect(() => {
    setClient(true);
  }, []);

  const isMain =
    isClient &&
    window.location.href.indexOf('/a/') === -1 &&
    window.location.href.indexOf('/s/') === -1 &&
    window.location.href.indexOf('/t/') === -1 &&
    window.location.href.indexOf('/n/') === -1 &&
    window.location.href.indexOf('/search') === -1;

  return isMain;
};
