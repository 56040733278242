import getConfig from 'next/config';

import packageJSON from '../../package.json';

const { version: VERSION } = packageJSON;

const { publicRuntimeConfig, serverRuntimeConfig } = getConfig();

export const config = {
  API_URL: serverRuntimeConfig.API_URL,
  CACHE_CONTROL: serverRuntimeConfig.CACHE_CONTROL,
  ENABLE_ROBOTS:
    serverRuntimeConfig.IS_PRODUCTION === 'yes' && serverRuntimeConfig.IS_PREVIEW !== 'yes',
  IS_PREVIEW: serverRuntimeConfig.IS_PREVIEW === 'yes',
  IS_PRODUCTION: publicRuntimeConfig.IS_PRODUCTION === 'yes',
  LOGFLARE_API_KEY: publicRuntimeConfig.LOGFLARE_API_KEY,
  LOGFLARE_TOKEN: publicRuntimeConfig.LOGFLARE_TOKEN,
  LOG_LEVEL: publicRuntimeConfig.LOG_LEVEL,
  NEXT_PUBLIC_GTAG_ID: publicRuntimeConfig.NEXT_PUBLIC_GTAG_ID,
  NEXT_PUBLIC_YM_ID: publicRuntimeConfig.NEXT_PUBLIC_YM_ID,
  NODE_ENV: process.env.NODE_ENV,
  ORIGIN_URL: publicRuntimeConfig.ORIGIN_URL,
  REVALIDATION_TOKEN: process.env.REVALIDATION_TOKEN,
  SENTRY_DSN: serverRuntimeConfig.SENTRY_DSN,
  VERSION,
  ZEN_VERIFICATION: publicRuntimeConfig.ZEN_VERIFICATION,
};

export const isDev = config.NODE_ENV === 'development';
