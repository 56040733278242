import Link from 'next/link';
import { PostType } from 'pn-backend';
import { not } from 'ramda';
import React, { FC, useEffect, useState } from 'react';

import { Grid, Image, Tag, Text } from '~atoms';
import { GridPostCardProps } from '~types';
import { formatDate, formatTime, getPostPreviewSummary, isDefined, isEmptyString } from '~utils';

import styles from './styles.module.scss';

interface Props extends GridPostCardProps {
  withTag?: boolean;
}

export const SearchItem: FC<Props> = ({ post, withTag = false }) => {
  const { type } = post;
  const { coverUrl, postLink, publishedAt, specLink, tagLink, tagTitle, title } =
    getPostPreviewSummary(post);
  const [currentPublishedAt, setCurrentPublishedAt] = useState<Nullable<string>>(null);

  let href = postLink;

  if (type === PostType.Spec) {
    href = specLink || postLink;
  }

  useEffect(() => {
    setCurrentPublishedAt(publishedAt);
  }, [publishedAt]);

  return (
    <Link href={href} passHref className={styles.tag}>
      <Grid
        $gridTemplateColumns="250px 1fr"
        // $lg={{ $gridTemplateColumns: '27% 73%', $height: '133px' }}
        $sm={{
          $borderBottom: '1px solid var(--color-gray-04)',
          $gridTemplateAreas: '"top top" "right left"',
          $gridTemplateColumns: '72% 28%',
          $height: '100%',
        }}
        className={styles.wrapper}
      >
        {withTag && tagLink && (
          <Grid
            $display="none"
            $position="relative"
            $height="35px"
            $sm={{ $display: 'block', $gridArea: 'top', $marginBottom: '15px' }}
          >
            <Link href={tagLink} passHref legacyBehavior>
              <Tag
                $position="absolute"
                $top="0"
                $left="0"
                $width="max-content"
                $transparent
                $lg={{
                  $fontSize: '14px',
                  $lineHeight: '22px',
                  $padding: '3px 10px',
                }}
              >
                {tagTitle}
              </Tag>
            </Link>
          </Grid>
        )}
        {coverUrl && title ? (
          <Grid
            $width="250px"
            $height="167px"
            $position="relative"
            $sm={{ $gridArea: 'left', $height: '60px', $width: '90px' }}
          >
            <Image src={coverUrl.small} alt={title} />
          </Grid>
        ) : null}
        <Grid $padding="0 20px" $sm={{ $gridArea: 'right', $padding: '0 10px 0 0' }}>
          <Grid
            $borderBottom="1px solid var(--color-gray-04)"
            $sm={{ $borderBottom: 'none', $rowGap: '20px' }}
          >
            {withTag && isDefined(tagTitle) && (
              <Grid $position="relative" $height="35px" $sm={{ $display: 'none' }}>
                {tagLink && (
                  <Link href={tagLink} passHref legacyBehavior>
                    <Tag
                      $display="table"
                      $position="absolute"
                      $top="0"
                      $left="0"
                      $width="max-content"
                      $transparent
                      $lg={{ $fontSize: '14px', $lineHeight: '22px' }}
                    >
                      {tagTitle}
                    </Tag>
                  </Link>
                )}
              </Grid>
            )}
            {title && (
              <Text
                $weight={600}
                $size={26}
                $lineHeight={29}
                $color="black"
                $lg={{ $lineHeight: 20, $size: 18 }}
                $sm={{ $lineHeight: 20, $size: 18, $weight: 600 }}
                className={styles.title}
                dangerouslySetInnerHTML={{ __html: title }}
              />
            )}
            {isDefined(currentPublishedAt) && not(isEmptyString(currentPublishedAt)) && (
              <Grid
                $gridTemplateColumns="max-content max-content max-content"
                $columnGap="5px"
                $alignContent="end"
                $color="gray"
                $padding="0 0 15px"
                $sm={{
                  $padding: '0',
                }}
                className={styles.date}
              >
                <Text
                  $size={16}
                  $lineHeight={22}
                  $weight={500}
                  $lg={{ $lineHeight: 18, $size: 16 }}
                  $sm={{ $lineHeight: 22, $size: 14 }}
                >
                  {formatDate(publishedAt)}
                </Text>
                <Text
                  $size={16}
                  $lineHeight={22}
                  $weight={500}
                  $sm={{ $lineHeight: 22, $size: 14 }}
                >
                  &#8226;
                </Text>
                <Text
                  $size={16}
                  $lineHeight={22}
                  $weight={500}
                  $lg={{ $lineHeight: 20, $size: 16 }}
                  $sm={{ $lineHeight: 22, $size: 14 }}
                >
                  <Text
                    $size={16}
                    $lineHeight={22}
                    $weight={500}
                    $lg={{ $lineHeight: 18, $size: 16 }}
                    $sm={{ $lineHeight: 22, $size: 14 }}
                  >
                    {formatTime(publishedAt)}
                  </Text>
                </Text>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Link>
  );
};
