// eslint-disable-next-line import/no-extraneous-dependencies
import { uniq } from 'lodash';

import { BlockChildMap, BlockType, blockTypeChildMap } from '../types';

export function getAvailableChildBlockTypes<T extends BlockType>(
  blockType: T,
): BlockChildMap<T>[] {
  const [, defaultBlockTypes, additionalBlockTypes] =
    blockTypeChildMap[blockType];

  return uniq([...defaultBlockTypes, ...additionalBlockTypes]);
}
