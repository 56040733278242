import Link from 'next/link';
import { SectionPublic } from 'pn-backend';
import { propEq } from 'ramda';
import React, { FC, useContext, useEffect } from 'react';

import { Close } from '~assets';
import { Grid, Text } from '~atoms';
import { StateContext } from '~providers';
import { getSectionLink } from '~utils';

import { Socials } from '../Socials';

interface Props {
  isOpenBurger: boolean;
  onClose: () => void;
}

export const BurgerMenu: FC<Props> = ({ isOpenBurger, onClose }) => {
  const {
    state: { sections = [] },
  } = useContext(StateContext);

  const isShownInMenu = propEq<keyof SectionPublic>('isShownInMenu', true);

  useEffect(() => {
    if (isOpenBurger) document.body.style.overflow = 'hidden';
    if (!isOpenBurger) document.body.style.overflow = 'auto';
  }, [isOpenBurger]);

  const visibleInMenu = sections.filter((section) => section.isShownInMenu);

  return (
    <>
      <Grid
        $display="none"
        $zIndex={isOpenBurger ? '1000001' : '-10'}
        $background={isOpenBurger ? 'black' : undefined}
        $opacity={isOpenBurger ? '0.7' : '0'}
        $xl={{
          $bottom: 0,
          $display: 'flex',
          $left: 0,
          $position: 'fixed',
          $right: isOpenBurger ? '0' : 'unset',
          $top: 0,
          $transform: 'translateZ(1px)',
        }}
      />
      <Grid
        $display="none"
        $transition="all 0.4s ease-in-out"
        $position="fixed"
        $zIndex="2000002"
        $left={isOpenBurger ? '0' : '-100vw'}
        $top="0"
        $padding="15px"
        $xl={{
          $background: '#151515',
          $display: 'flex',
          $flexDirection: 'column',
          $height: '100vh',
          $justifyContent: 'space-between',
          $transform: 'translateZ(1px)',
          $width: '280px',
        }}
      >
        <Grid $width="32px" $height="32px" $color="white">
          <Close onClick={onClose} />
        </Grid>
        <Grid $height="65vh" $alignItems="flex-start" $display="flex" $flexDirection="column">
          {visibleInMenu
            .slice(0, 7)
            .filter(isShownInMenu)
            .map((section) => (
              <Grid
                key={section.alias}
                $display="flex"
                $flexDirection="column"
                $margin="0 0 20px"
                onClick={onClose}
              >
                <Text
                  key={section.alias}
                  $weight={600}
                  $size={32}
                  $lineHeight={35}
                  $textTransform="lowercase"
                  $color="white"
                >
                  <Link href={getSectionLink(section.alias)} passHref>
                    {section.title}
                  </Link>
                </Text>
              </Grid>
            ))}
        </Grid>
        <Grid $marginBottom="15vh">
          <Socials inHeader />
        </Grid>
      </Grid>
    </>
  );
};
