import { CompileOptions } from '../types/common/CompileOptions';
import { Post } from '../types/db/post/Post';
import { PostContentCompiled } from '../types/db/post/PostContentCompiled';
import { PostType } from '../types/db/post/PostType';
import { compileBlock } from './compileBlock';
import { syncBlockPreviewWithCover } from './syncBlockPreviewWithCover';

export function compilePostContent<T extends PostType>(
  post: Post<T>,
  options: CompileOptions,
): PostContentCompiled<T> {
  syncBlockPreviewWithCover(post.blocks, options);

  return {
    blocks: post.blocks
      .sort((a, b) => a.sortOrder - b.sortOrder)
      .map((block) => compileBlock(block, options)),
  };
}
