// eslint-disable-next-line import/no-extraneous-dependencies
import { faker } from '@faker-js/faker';
// eslint-disable-next-line import/no-extraneous-dependencies
import { omit } from 'lodash';

import {
  Block,
  BlockChildMap,
  BlockType,
  BlockTypeContentMap,
  ColumnsTextPosition,
  CoverFormat,
  FileType,
  GalleryType,
  ImageFormat,
  ImageProxyGravity,
  ImageProxyResize,
  IncutType,
  LineType,
  ListType,
  MoreOnTopicType,
  PreviewImageFormat,
  QuoteType,
  TestAnswerType,
  VideoBlockType,
  VideoFormat,
  WidgetFormat,
} from '../types';
import { blockTypeChildMap } from '../types/db/block/blockTypeChildMap';
import { createArray, HTMLToDraft } from '../util';
import { createEmptyBlocks } from './createEmptyBlocks';
import { BlockFactories } from './types/BlockFactories';

faker.setLocale('ru');

function blockDefaults<T extends BlockType>(
  type: T,
  content: BlockTypeContentMap[T],
  data: Partial<Block<T>>,
): Block<T> {
  const block: Partial<Block<T>> = {
    blocks: [],
    createdById: data.createdBy?.id,
    files: [],
    id: faker.datatype.number(),
    parentId: data.parent?.id,
    postId: data.post?.id,
    sortOrder: 0,
    type,
    updatedById: data.updatedBy?.id,
    ...data,
    content: {
      ...content,
      ...data.content,
    },
  };

  return block as Block<T>;
}

export const blockFactoryMap: {
  [T in BlockType]: (
    data: Partial<Block<T>>,
    factories: BlockFactories,
  ) => Block<T>;
} = {
  card(data, { createBlock }): Block<BlockType.Card> {
    const [defaultFixedBlockTypes, defaultBlockTypes, additinalBlockTypes] =
      blockTypeChildMap[BlockType.Card];

    const blocks = createEmptyBlocks<BlockChildMap<BlockType.Card>>(
      defaultFixedBlockTypes,
      defaultBlockTypes,
      additinalBlockTypes,
    ).map((block) => createBlock(block.type, omit(block, 'content')));

    return blockDefaults(
      BlockType.Card,
      {
        blockIds: blocks.map((block) => block.id),
      },
      {
        ...data,
        blocks,
      },
    );
  },

  columns(data, factories): Block<BlockType.Columns> {
    const imageCount = faker.datatype.number({ max: 5, min: 1 });
    const files = createArray(imageCount).map(() =>
      factories.createFile(FileType.Image),
    );

    return blockDefaults(
      BlockType.Columns,
      {
        content: HTMLToDraft(`${faker.lorem.sentence()}`),
        images: createArray(imageCount).map((o, index) => ({
          caption: faker.lorem.sentence(),
          fileId: files[index].id,
        })),
        textPosition: faker.helpers.objectValue(ColumnsTextPosition),
        title: faker.lorem.sentence(),
      },
      {
        ...data,
        files: [...files],
      },
    );
  },
  cover(data, factories): Block<BlockType.Cover> {
    const file = factories.createFile(FileType.Image);

    return blockDefaults(
      BlockType.Cover,
      {
        caption: faker.lorem.sentence(),
        fileId: file.id,
        format: faker.helpers.objectValue(CoverFormat),
        hideInPost: faker.datatype.boolean(),
      },
      {
        ...data,
        files: [file],
      },
    );
  },
  description(data): Block<BlockType.Description> {
    return blockDefaults(
      BlockType.Description,
      {
        text: faker.lorem.sentence(),
      },
      data,
    );
  },
  gallery(data, factories): Block<BlockType.Gallery> {
    const files = createArray(faker.datatype.number({ max: 5, min: 1 })).map(
      () => factories.createFile(FileType.Image),
    );
    return blockDefaults(
      BlockType.Gallery,
      {
        images: files.map((file) => ({
          caption: faker.lorem.sentence(),
          fileId: file.id,
          height: 600,
          width: 800,
        })),
        type: faker.helpers.objectValue(GalleryType),
      },
      {
        ...data,
        files,
      },
    );
  },
  gif(data, factories): Block<BlockType.Gif> {
    const file = factories.createFile(FileType.Image);

    return blockDefaults(
      BlockType.Gif,
      {
        caption: faker.lorem.sentence(),
        fileId: file.id,
      },
      {
        ...data,
        files: [file],
      },
    );
  },
  h1(data): Block<BlockType.H1> {
    return blockDefaults(
      BlockType.H1,
      {
        text: faker.lorem.sentence(),
      },
      data,
    );
  },
  h2(data): Block<BlockType.H2> {
    return blockDefaults(
      BlockType.H2,
      {
        content: HTMLToDraft(`${faker.lorem.sentence()}`),
      },
      data,
    );
  },
  h3(data): Block<BlockType.H3> {
    return blockDefaults(
      BlockType.H3,
      {
        content: HTMLToDraft(`${faker.lorem.sentence()}`),
      },
      data,
    );
  },
  image(data, factories): Block<BlockType.Image> {
    const file = factories.createFile(FileType.Image);

    return blockDefaults(
      BlockType.Image,
      {
        caption: faker.lorem.sentence(),
        fileId: file.id,
        format: faker.helpers.objectValue(ImageFormat),
      },
      {
        ...data,
        files: [file],
      },
    );
  },
  incut(data): Block<BlockType.Incut> {
    return blockDefaults(
      BlockType.Incut,
      {
        caption: faker.lorem.sentence(),
        content: HTMLToDraft(`${faker.lorem.sentence()}`),
        items: createArray(faker.datatype.number({ max: 5, min: 1 })).map(() =>
          faker.lorem.sentence(),
        ),
        listType: faker.helpers.objectValue(ListType),
        type: faker.helpers.objectValue(IncutType),
      },
      data,
    );
  },
  intro(data): Block<BlockType.Intro> {
    return blockDefaults(
      BlockType.Intro,
      {
        content: HTMLToDraft(`${faker.lorem.sentence()}`),
      },
      data,
    );
  },
  line(data): Block<BlockType.Line> {
    return blockDefaults(
      BlockType.Line,
      {
        type: faker.helpers.objectValue(LineType),
      },
      data,
    );
  },
  list(data): Block<BlockType.List> {
    return blockDefaults(
      BlockType.List,
      {
        options: createArray(faker.datatype.number({ max: 5, min: 1 })).map(
          () => ({
            content: HTMLToDraft(`${faker.lorem.sentence()}`),
          }),
        ),
        type: faker.helpers.objectValue(ListType),
      },
      data,
    );
  },
  meta(data): Block<BlockType.Meta> {
    return blockDefaults(
      BlockType.Meta,
      {
        canonical: faker.lorem.sentence(),
      },
      data,
    );
  },
  moreOnTopic(data, factories): Block<BlockType.MoreOnTopic> {
    const file = factories.createFile(FileType.Image);

    return blockDefaults(
      BlockType.MoreOnTopic,
      {
        fileId: file.id,
        items: createArray(3).map(() => ({
          caption: faker.lorem.sentence(),
          link: faker.internet.url(),
        })),
        type: faker.helpers.objectValue(MoreOnTopicType),
      },
      {
        ...data,
        files: [file],
      },
    );
  },
  newsSource(data): Block<BlockType.NewsSource> {
    return blockDefaults(
      BlockType.NewsSource,
      {
        name: faker.lorem.sentence(3),
        url: faker.internet.url(),
      },
      data,
    );
  },
  preview(data, factories): Block<BlockType.Preview> {
    const file = factories.createFile(FileType.Image);

    return blockDefaults(
      BlockType.Preview,
      {
        fileId: file.id,
        images: {
          [PreviewImageFormat.Big]: {
            gravity: faker.helpers.objectValue(ImageProxyGravity),
            height: faker.datatype.number({ max: 1080, min: 320 }),
            resize: faker.helpers.objectValue(ImageProxyResize),
            width: faker.datatype.number({ max: 1080, min: 320 }),
          },
          [PreviewImageFormat.Medium]: {
            gravity: faker.helpers.objectValue(ImageProxyGravity),
            height: faker.datatype.number({ max: 1080, min: 320 }),
            resize: faker.helpers.objectValue(ImageProxyResize),
            width: faker.datatype.number({ max: 1080, min: 320 }),
          },
          [PreviewImageFormat.Mobile]: {
            gravity: faker.helpers.objectValue(ImageProxyGravity),
            height: faker.datatype.number({ max: 1080, min: 320 }),
            resize: faker.helpers.objectValue(ImageProxyResize),
            width: faker.datatype.number({ max: 1080, min: 320 }),
          },
          [PreviewImageFormat.Small]: {
            gravity: faker.helpers.objectValue(ImageProxyGravity),
            height: faker.datatype.number({ max: 1080, min: 320 }),
            resize: faker.helpers.objectValue(ImageProxyResize),
            width: faker.datatype.number({ max: 1080, min: 320 }),
          },
          [PreviewImageFormat.Wide]: {
            gravity: faker.helpers.objectValue(ImageProxyGravity),
            height: faker.datatype.number({ max: 1080, min: 320 }),
            resize: faker.helpers.objectValue(ImageProxyResize),
            width: faker.datatype.number({ max: 1080, min: 320 }),
          },
        },
      },
      {
        ...data,
        files: [file],
      },
    );
  },
  quiz(data, factories): Block<BlockType.Quiz> {
    const file = factories.createFile(FileType.Image);

    return blockDefaults(
      BlockType.Quiz,
      {
        answerOptions: createArray(
          faker.datatype.number({ max: 5, min: 1 }),
        ).map(() => faker.lorem.sentence()),
        caption: faker.lorem.sentence(),
        fileId: file.id,
        question: faker.lorem.sentence(),
      },
      {
        ...data,
        files: [file],
      },
    );
  },
  quote(data, factories): Block<BlockType.Quote> {
    const file = factories.createFile(FileType.Image);

    return blockDefaults(
      BlockType.Quote,
      {
        caption: faker.lorem.sentence(),
        content: HTMLToDraft(`${faker.lorem.sentence()}`),
        expertName: faker.name.fullName(),
        fileId: file.id,
        type: faker.helpers.objectValue(QuoteType),
      },
      {
        ...data,
        files: [file],
      },
    );
  },
  seoTitle(data): Block<BlockType.SeoTitle> {
    return blockDefaults(
      BlockType.SeoTitle,
      {
        text: faker.lorem.sentence(),
      },
      data,
    );
  },
  specLink(data): Block<BlockType.SpecLink> {
    return blockDefaults(
      BlockType.SpecLink,
      {
        url: faker.internet.url(),
      },
      data,
    );
  },
  subtitle(data): Block<BlockType.Subtitle> {
    return blockDefaults(
      BlockType.Subtitle,
      {
        text: faker.lorem.sentence(),
      },
      data,
    );
  },
  test(data, { createBlock }): Block<BlockType.Test> {
    const [defaultFixedBlockTypes, defaultBlockTypes, additinalBlockTypes] =
      blockTypeChildMap[BlockType.Test];

    const blocks = createEmptyBlocks<BlockChildMap<BlockType.Test>>(
      defaultFixedBlockTypes,
      defaultBlockTypes,
      additinalBlockTypes,
    ).map((block) => createBlock(block.type, omit(block, 'content')));

    return blockDefaults(
      BlockType.Test,
      {
        blockIds: blocks.map((block) => block.id),
      },
      {
        ...data,
        blocks,
      },
    );
  },
  testQuestion(data, factories): Block<BlockType.TestQuestion> {
    const answerCount = faker.datatype.number({ max: 5, min: 1 });
    const answerFiles = createArray(answerCount).map(() =>
      factories.createFile(FileType.Image),
    );
    const file = factories.createFile(FileType.Image);
    const rightAnswerIndex = faker.datatype.number({
      max: answerCount - 1,
      min: 0,
    });

    return blockDefaults(
      BlockType.TestQuestion,
      {
        answerOptions: createArray(answerCount).map((o, index) => ({
          fileId: answerFiles[index].id,
          isRightAnswer: rightAnswerIndex === index,
          text: faker.lorem.sentence(),
        })),
        answerType: faker.helpers.objectValue(TestAnswerType),
        coverCaption: faker.lorem.sentence(),
        fileId: file.id,
        hideRightAnswer: faker.datatype.boolean(),
        question: faker.lorem.sentence(),
        rightAnswerCaption: faker.lorem.sentence(),
        wrongAnswerCaption: faker.lorem.sentence(),
      },
      {
        ...data,
        files: [file, ...answerFiles],
      },
    );
  },
  testResults(data, factories): Block<BlockType.TestResults> {
    const file1 = factories.createFile(FileType.Image);
    const file2 = factories.createFile(FileType.Image);
    const file3 = factories.createFile(FileType.Image);

    return blockDefaults(
      BlockType.TestResults,
      {
        average: {
          caption: faker.lorem.sentence(),
          fileId: file2.id,
          text: faker.lorem.sentence(),
          title: faker.lorem.sentence(),
          valuesFrom: faker.datatype.number(),
          valuesTo: faker.datatype.number(),
        },

        maximum: {
          caption: faker.lorem.sentence(),
          fileId: file3.id,
          text: faker.lorem.sentence(),
          title: faker.lorem.sentence(),
          valuesFrom: faker.datatype.number(),
          valuesTo: faker.datatype.number(),
        },

        minimum: {
          caption: faker.lorem.sentence(),
          fileId: file1.id,
          text: faker.lorem.sentence(),
          title: faker.lorem.sentence(),
          valuesFrom: faker.datatype.number(),
          valuesTo: faker.datatype.number(),
        },
      },
      {
        ...data,
        files: [file1, file2, file3],
      },
    );
  },
  text(data): Block<BlockType.Text> {
    return blockDefaults(
      BlockType.Text,
      {
        content: HTMLToDraft(`${faker.lorem.sentence()}`),
      },
      data,
    );
  },
  video(data, factories): Block<BlockType.Video> {
    const file = factories.createFile(FileType.Video);
    const poster = factories.createFile(FileType.Image);

    return blockDefaults(
      BlockType.Video,
      {
        caption: faker.lorem.sentence(),
        cycle: faker.datatype.boolean(),
        fileId: file.id,
        format: faker.helpers.objectValue(VideoFormat),
        posterId: poster.id,
        type: faker.helpers.objectValue(VideoBlockType),
      },
      {
        ...data,
        files: [file],
      },
    );
  },
  widget(data): Block<BlockType.Widget> {
    return blockDefaults(
      BlockType.Widget,
      {
        format: faker.helpers.objectValue(WidgetFormat),
        html: `${faker.lorem.sentence()}`,
        text: `${faker.lorem.sentence()}`,
      },
      data,
    );
  },
};
