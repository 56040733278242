import { BlockType, VideoBlockType, VideoFormat } from 'pn-backend';
import React, { FC } from 'react';

import { Grid, Text } from '~atoms';
import { useLayout } from '~hooks';
import { BlockProps } from '~types';

import styles from './styles.module.scss';

const getCaption = (caption: string) => {
  return (
    <Text
      $size={16}
      $lineHeight={22}
      $weight={400}
      $opacity="0.5"
      $sm={{ $lineHeight: 17, $size: 12 }}
    >
      {caption}
    </Text>
  );
};

export const VideoBlock: FC<BlockProps<BlockType.Video>> = ({
  compiled: { caption, cycle, format, posterURL, type, videoURL },
}) => {
  const { isArticle, isCard } = useLayout();

  let videoFormat = format;

  if (isCard || isArticle) {
    if (format === VideoFormat.Fullscreen) videoFormat = VideoFormat.Center;
  }

  const renderVideoPlayer = () => {
    switch (type) {
      case VideoBlockType.Video:
        return (
          <video controls src={videoURL} poster={posterURL} loop={cycle} width="100%" height="100%">
            <track kind="captions" />
          </video>
        );
      case VideoBlockType.Iframe:
        return (
          <Grid className={styles.iframeWrapper} $iframeNoBorder>
            <iframe title={caption} src={videoURL} />
          </Grid>
        );
      default:
        return null;
    }
  };

  switch (videoFormat) {
    case VideoFormat.Fullscreen:
      if (isCard) return null;
      return (
        <Grid $overflow="hidden" $marginTop="40px" $width="100vw" $sm={{ $marginTop: '30px' }}>
          {renderVideoPlayer()}
          <Grid $width="750px" $margin="10px auto 0" $lg={{ $padding: '0 15px', $width: '100%' }}>
            {caption && getCaption(caption)}
          </Grid>
        </Grid>
      );

    case VideoFormat.Center:
      return (
        <Grid
          $margin={isCard ? '0' : 'auto'}
          $marginTop={isCard ? 'auto' : '40px'}
          $maxWidth="750px"
          $lg={{ $maxWidth: 'unset', $padding: isCard ? '0' : '0 15px', $width: '100%' }}
          $sm={{ $marginTop: isCard ? '0' : '30px' }}
        >
          {renderVideoPlayer()}
          <Grid $width={isCard ? '100%' : '750px'} $margin="10px auto 0" $lg={{ $width: '100%' }}>
            {caption && getCaption(caption)}
          </Grid>
        </Grid>
      );

    default:
      return null;
  }
};
