import loadScript from 'load-script';
import { identity } from 'ramda';
import { RefObject } from 'react';

export const vkWidget = (html: string, parent: RefObject<HTMLDivElement>) => {
  const trimmedHtml = html.replace(/([\r\n])/gm, '');

  const scriptRegexp = /(<script.*?<\/script>)/gm;

  const vkWidgetScripts = trimmedHtml.match(scriptRegexp);
  const vkWidgetRest = trimmedHtml.replace(scriptRegexp, '');

  if (parent?.current) {
    // eslint-disable-next-line no-param-reassign
    parent.current.innerHTML = vkWidgetRest;
  }

  // const scriptId = /id\s*=\s*"(.+?)"/gm.exec(vkWidgetRest);

  if (vkWidgetScripts) {
    vkWidgetScripts.forEach((script) => {
      const scriptSrc = /src\s*=\s*"(.+?)"/gm.exec(script);
      const scriptType = /type\s*=\s*"(.+?)"/gm.exec(script);
      const scriptHasFunction = script.match(/function/gm);
      if (scriptSrc) {
        loadScript(scriptSrc[1], { attrs: { type: scriptType ? scriptType[1] : '' } }, identity);
      }
      if (scriptHasFunction) {
        const scriptFunction = /(?:<script type="text\/javascript">)(.*?)(?:<\/script>)/gm.exec(
          script,
        );
        if (scriptFunction) {
          const scriptToExecute = document.createElement('script');
          scriptToExecute.type = scriptType ? scriptType[1] : '';
          // eslint-disable-next-line prefer-destructuring
          scriptToExecute.text = scriptFunction[1];
          if (parent?.current) {
            setTimeout(() => parent?.current?.appendChild(scriptToExecute), 3000);
          }
        }
      }
    });
  }
};
