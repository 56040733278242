import { BlockType } from 'pn-backend';
import React, { FC } from 'react';

import { Grid, Image, Text } from '~atoms';
import { useLayout } from '~hooks';
import { BlockProps } from '~types';

const getCaption = (caption: string) => {
  return (
    <Text
      $size={16}
      $lineHeight={22}
      $weight={400}
      $opacity="0.5"
      $sm={{ $lineHeight: 17, $size: 12 }}
    >
      {caption}
    </Text>
  );
};

export const GifBlock: FC<BlockProps<BlockType.Gif>> = ({
  compiled: { caption, height, imageURL, width },
}) => {
  const { isArticle, isCard } = useLayout();

  // ToDo Refactor: перед рендером блока прогонять через схемы валидации
  if (!imageURL) return null;

  const aspectRatio = width / height || '16/9';

  return (
    <Grid
      $margin={isCard ? '0 auto 0' : '40px auto 0'}
      $maxWidth="750px"
      $width="100%"
      $md={{ $maxWidth: 'unset', $padding: '0 15px' }}
      $sm={{ $margin: '30px auto 0' }}
    >
      <Grid $position="relative" $width="100%" $aspectRatio={aspectRatio}>
        <Image src={imageURL} alt={caption} />
      </Grid>
      <Grid
        $width="100%"
        $maxWidth="750px"
        $margin="10px auto 0"
        $lg={{ $maxWidth: '100%', $padding: isCard || isArticle ? '0' : '0 15px', $width: '100%' }}
      >
        {caption && getCaption(caption)}
      </Grid>
    </Grid>
  );
};
