export enum GridLayout {
  FullPost = 'FullPost',
  MainBlackScience = 'MainBlackScience',
  MainCompilation = 'MainCompilation',
  MainNews = 'MainNews',
  MainNewsFull = 'MainNewsFull',
  MainOnSport = 'MainOnSport',
  MainPostOfTheWeek = 'MainPostOfTheWeek',
  MainTop = 'MainTop',
  News = 'News',
  OneBig = 'OneBig',
  OneSpecProject = 'OneSpecProject',
  SomeSpecProjects = 'SomeSpecProjects',
  SomeTests = 'SomeTests',
  ThreeSmall = 'ThreeSmall',
  TwoStandard = 'TwoStandard',
}
