// eslint-disable-next-line import/no-extraneous-dependencies
import { faker } from '@faker-js/faker';

import { GridLayout, GridSchema } from '../types';
import { CreateFactoryOptions } from './types/CreateFactoryOptions';
import { Factory } from './types/Factory';

faker.setLocale('ru');

export function createGridSchemaFactory(
  options?: CreateFactoryOptions,
): Factory<GridSchema> {
  let currentId = options?.initId || 0;

  return (data) => {
    const gridSchema: GridSchema = {
      canChangeOrder: faker.datatype.boolean(),
      canChangePosts: faker.datatype.boolean(),
      canHide: faker.datatype.boolean(),
      canPaginate: faker.datatype.boolean(),
      cover: undefined,
      coverId: data?.cover?.id,
      createdAt: undefined,
      createdBy: undefined,
      createdById: data?.createdBy?.id,
      deletedAt: undefined,
      filterByPostTypes: [],
      filterBySection: undefined,
      filterBySectionId: data?.filterBySection?.id,
      grid: undefined,
      gridId: data?.grid?.id,
      id: ++currentId,
      isHidden: faker.datatype.boolean(),
      layout: faker.helpers.objectValue(GridLayout),
      maxPostCount: faker.datatype.number(),
      posts: undefined,
      sortOrder: faker.datatype.number(),
      title: faker.lorem.word(),
      updatedAt: undefined,
      updatedBy: undefined,
      updatedById: data?.updatedBy?.id,
      ...data,
    } as GridSchema;

    return gridSchema;
  };
}
