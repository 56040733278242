import Link from 'next/link';
import { CoverFormat, PostContentCompiled, PostType } from 'pn-backend';
import { not } from 'ramda';
import React, { FC, useEffect, useLayoutEffect, useState } from 'react';

import { Grid, Tag, Text } from '~atoms';
import { usePost } from '~hooks';
import {
  CoverBlock,
  DescriptionBlock,
  H1Block,
  MetaBlock,
  SeoTitleBlock,
  SubTitleBlock,
} from '~postBlocks';
import {
  formatFullDate,
  getPostSummary,
  isCoverBlock,
  isDefined,
  isDescriptionBlock,
  isEmptyString,
  isH1Block,
  isMetaBlock,
  isSeoTitleBlock,
  isSubtitleBlock,
} from '~utils';

export const LongCoverBlock: FC<PostContentCompiled<PostType.Longread>> = ({ blocks }) => {
  const { post } = usePost();
  const { isModificationDateVisible, modifiedAt, publishedAt, tagLink, tagTitle } =
    getPostSummary(post);

  const [currentPublishedAt, setCurrentPublishedAt] = useState<Nullable<string>>(null);
  const [currentModifiedAt, setCurrentModifiedAt] = useState<Nullable<string>>(null);

  const h1Block = blocks.find(isH1Block);
  const metaBlock = blocks.find(isMetaBlock);
  const seoTitleBlock = blocks.find(isSeoTitleBlock);
  const descriptionBlock = blocks.find(isDescriptionBlock);
  const subtitleBlock = blocks.find(isSubtitleBlock);
  const coverBlock = blocks.find(isCoverBlock);

  let renderType = CoverFormat.FullscreenCaptionOver;
  let isCoverVisible = true;

  if (coverBlock) {
    const { format, hideInPost, imageURL } = coverBlock.compiled;

    if (format) renderType = format;
    if (hideInPost || !imageURL) {
      renderType = CoverFormat.CaptionAbove;
      isCoverVisible = false;
    }
  } else {
    renderType = CoverFormat.CaptionAbove;
    isCoverVisible = false;
  }

  useLayoutEffect(() => {
    if (renderType === CoverFormat.CaptionAbove || renderType === CoverFormat.CaptionUnder) {
      const { body } = document;
      body.id = 'longread_simple';
    }

    return () => {
      const { body } = document;
      body.id = '';
    };
  }, [renderType]);

  const isFullscreenWithText =
    CoverFormat.FullscreenCaptionRight || CoverFormat.FullscreenCaptionOver;

  useEffect(() => {
    setCurrentPublishedAt(publishedAt);
    setCurrentModifiedAt(modifiedAt);
  }, [publishedAt, modifiedAt]);

  switch (renderType) {
    case CoverFormat.CaptionAbove:
      return (
        <>
          {metaBlock && <MetaBlock {...metaBlock} />}
          {seoTitleBlock && <SeoTitleBlock {...seoTitleBlock} />}
          {descriptionBlock && <DescriptionBlock {...descriptionBlock} />}

          <Grid
            $maxWidth="750px"
            $width="100%"
            $margin={isCoverVisible ? '30px auto -20px' : '30px auto -10px'}
            $display="flex"
            $flexDirection="column"
            $justifyContent="space-between"
            $alignItems="center"
            $sm={{ $margin: '10px auto 0' }}
          >
            <Grid
              $display="flex"
              $width="100%"
              $alignItems="center"
              $padding="0"
              $sm={{
                $alignItems: 'flex-start',
                $display: 'flex',
                $flexDirection: 'column',
                $padding: '0 15px',
              }}
            >
              {tagLink && (
                <Link href={tagLink} passHref legacyBehavior>
                  <Tag
                    $transparent
                    $position="relative"
                    $top="unset"
                    $right="unset"
                    $sm={{ $fontSize: '14px', $padding: '3px 11px' }}
                  >
                    {tagTitle}
                  </Tag>
                </Link>
              )}

              <Grid
                $display="flex"
                $gap="5px"
                $sm={{ $gap: '3px', $marginBottom: '0', $width: '100%' }}
              >
                {isDefined(currentPublishedAt) && not(isEmptyString(currentPublishedAt)) && (
                  <Grid $marginLeft={tagLink ? '15px' : '0'} $sm={{ $margin: '20px 0 0' }}>
                    <Text $size={14} $weight={500} $lineHeight={17} $textTransform="uppercase">
                      {formatFullDate(currentPublishedAt)}
                    </Text>
                  </Grid>
                )}
                {isModificationDateVisible &&
                  isDefined(currentModifiedAt) &&
                  not(isEmptyString(currentModifiedAt)) && (
                    <>
                      <Grid $sm={{ $margin: '20px 0 0' }}>
                        <Text $display="inline-flex" $alignItems="center">
                          &#8226;
                        </Text>
                      </Grid>
                      <Grid $sm={{ $margin: '20px 0 0' }}>
                        <Text
                          $size={14}
                          $lineHeight={17}
                          $weight={500}
                          $textTransform="uppercase"
                          $maxWidth="min-content"
                        >
                          UPD:
                        </Text>
                      </Grid>
                      <Grid $sm={{ $margin: '20px 0 0' }}>
                        <Text $size={14} $weight={500} $lineHeight={17} $textTransform="uppercase">
                          {formatFullDate(currentModifiedAt)}
                        </Text>
                      </Grid>
                    </>
                  )}
              </Grid>
            </Grid>

            <Grid $width="100%" $sm={{ $margin: '0 15px', $width: 'calc(100% - 30px)' }}>
              {h1Block && <H1Block {...h1Block} renderType={renderType} />}
            </Grid>
            <Grid
              $margin={isCoverVisible ? '0 0 20px' : '0'}
              $width="inherit"
              $sm={{ $margin: '0', $padding: '0 15px' }}
            >
              {subtitleBlock && <SubTitleBlock {...subtitleBlock} />}
            </Grid>
            {coverBlock && isCoverVisible && <CoverBlock {...coverBlock} />}
          </Grid>
        </>
      );

    case CoverFormat.CaptionUnder:
      return (
        <>
          {metaBlock && <MetaBlock {...metaBlock} />}
          {seoTitleBlock && <SeoTitleBlock {...seoTitleBlock} />}
          {descriptionBlock && <DescriptionBlock {...descriptionBlock} />}

          <Grid
            $maxWidth="750px"
            $width="100%"
            $margin="30px auto 0"
            $display="flex"
            $flexDirection="column"
            $justifyContent="space-between"
            $alignItems="center"
            $sm={{ $margin: '10px auto 0' }}
          >
            <Grid
              $display="flex"
              $width="100%"
              $alignItems="center"
              $padding="0 0 20px"
              $sm={{
                $alignItems: 'flex-start',
                $display: 'flex',
                $flexDirection: 'column',
                $padding: '0 15px',
              }}
            >
              {tagLink && (
                <Link href={tagLink} passHref legacyBehavior>
                  <Tag
                    $transparent
                    $position="relative"
                    $top="unset"
                    $right="unset"
                    $sm={{ $fontSize: '14px', $padding: '3px 11px' }}
                  >
                    {tagTitle}
                  </Tag>
                </Link>
              )}

              <Grid
                $display="flex"
                $gap="5px"
                $sm={{ $gap: '3px', $marginBottom: '0', $width: '100%' }}
              >
                {isDefined(currentPublishedAt) && not(isEmptyString(currentPublishedAt)) && (
                  <Grid $marginLeft={tagLink ? '15px' : '0'} $sm={{ $margin: '20px 0 0' }}>
                    <Text $size={14} $weight={500} $lineHeight={17} $textTransform="uppercase">
                      {formatFullDate(currentPublishedAt)}
                    </Text>
                  </Grid>
                )}
                {isModificationDateVisible &&
                  isDefined(currentModifiedAt) &&
                  not(isEmptyString(currentModifiedAt)) && (
                    <>
                      <Grid $sm={{ $margin: '20px 0 0' }}>
                        <Text $display="inline-flex" $alignItems="center">
                          &#8226;
                        </Text>
                      </Grid>
                      <Grid $sm={{ $margin: '20px 0 0' }}>
                        <Text
                          $size={14}
                          $lineHeight={17}
                          $weight={500}
                          $textTransform="uppercase"
                          $maxWidth="min-content"
                        >
                          UPD:
                        </Text>
                      </Grid>
                      <Grid $sm={{ $margin: '20px 0 0' }}>
                        <Text $size={14} $weight={500} $lineHeight={17} $textTransform="uppercase">
                          {formatFullDate(currentModifiedAt)}
                        </Text>
                      </Grid>
                    </>
                  )}
              </Grid>
            </Grid>

            {coverBlock && <CoverBlock {...coverBlock} />}

            <Grid $width="100%" $sm={{ $margin: '0 15px', $width: 'calc(100% - 30px)' }}>
              {h1Block && <H1Block {...h1Block} renderType={renderType} />}
            </Grid>
            <Grid $width="inherit" $sm={{ $padding: '0 15px' }}>
              {subtitleBlock && <SubTitleBlock {...subtitleBlock} />}
            </Grid>
          </Grid>
        </>
      );

    case CoverFormat.FullscreenCaptionRight:
      return (
        <>
          {metaBlock && <MetaBlock {...metaBlock} />}
          {seoTitleBlock && <SeoTitleBlock {...seoTitleBlock} />}
          {descriptionBlock && <DescriptionBlock {...descriptionBlock} />}

          <Grid $position="relative">
            {coverBlock && <CoverBlock {...coverBlock} />}
            <Grid
              $position="absolute"
              $width="100%"
              $color="white"
              $zIndex="2"
              $gridTemplateColumns="1fr 1fr"
              $xl={{
                $background: 'var(--color-black)',
                $gridTemplateColumns: '1fr',
                $position: 'relative',
              }}
            >
              <Grid />
              <Grid
                $justifyContent="flex-end"
                $display="flex"
                $flexDirection="column"
                $height="100vh"
                $xl={{ $height: 'auto' }}
              >
                <Grid
                  $padding="60px"
                  $xl={{ $padding: '45px 15px 50px' }}
                  $sm={{ $padding: '45px 15px 30px' }}
                >
                  <Grid
                    $display="flex"
                    $alignItems="center"
                    $sm={{
                      $alignItems: 'flex-start',
                      $flexDirection: 'column',
                      $padding: '0 0 20px',
                    }}
                  >
                    {tagLink && (
                      <Link href={tagLink} passHref legacyBehavior>
                        <Tag
                          $transparentWhite
                          $position="relative"
                          $top="unset"
                          $right="unset"
                          $sm={{ $fontSize: '14px', $padding: '3px 11px' }}
                        >
                          {tagTitle}
                        </Tag>
                      </Link>
                    )}

                    <Grid
                      $display="flex"
                      $gap="5px"
                      $sm={{ $gap: '3px', $marginBottom: '0', $width: '100%' }}
                    >
                      {isDefined(currentPublishedAt) && not(isEmptyString(currentPublishedAt)) && (
                        <Grid $marginLeft={tagLink ? '40px' : 0} $sm={{ $margin: '20px 0 0' }}>
                          <Text
                            $size={14}
                            $weight={500}
                            $lineHeight={17}
                            $textTransform="uppercase"
                          >
                            {formatFullDate(currentPublishedAt)}
                          </Text>
                        </Grid>
                      )}
                      {isModificationDateVisible &&
                        isDefined(currentModifiedAt) &&
                        not(isEmptyString(currentModifiedAt)) && (
                          <>
                            <Grid $sm={{ $margin: '20px 0 0' }}>
                              <Text $display="inline-flex" $alignItems="center">
                                &#8226;
                              </Text>
                            </Grid>
                            <Grid $sm={{ $margin: '20px 0 0' }}>
                              <Text
                                $size={14}
                                $lineHeight={17}
                                $weight={500}
                                $textTransform="uppercase"
                                $maxWidth="min-content"
                              >
                                UPD:
                              </Text>
                            </Grid>
                            <Grid $sm={{ $margin: '20px 0 0' }}>
                              <Text
                                $size={14}
                                $weight={500}
                                $lineHeight={17}
                                $textTransform="uppercase"
                              >
                                {formatFullDate(currentModifiedAt)}
                              </Text>
                            </Grid>
                          </>
                        )}
                    </Grid>
                  </Grid>
                  {h1Block && <H1Block {...h1Block} renderType={renderType} />}
                  <Grid
                    $marginTop="30px"
                    $width="70%"
                    $xl={{ $width: '60%' }}
                    $sm={{ $marginTop: '7px', $width: '100%' }}
                  >
                    {subtitleBlock && <SubTitleBlock {...subtitleBlock} isOnCover />}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      );

    case CoverFormat.FullscreenCaptionUnder:
      return (
        <>
          {metaBlock && <MetaBlock {...metaBlock} />}
          {seoTitleBlock && <SeoTitleBlock {...seoTitleBlock} />}
          {descriptionBlock && <DescriptionBlock {...descriptionBlock} />}

          {coverBlock && <CoverBlock {...coverBlock} />}
          <Grid
            $margin="auto"
            $maxWidth="750px"
            $width="100%"
            $marginTop="13px"
            $xl={{ $marginTop: '20px' }}
          >
            {h1Block && <H1Block {...h1Block} renderType={renderType} />}

            <Grid $sm={{ $margin: '0 15px' }}>
              {subtitleBlock && <SubTitleBlock {...subtitleBlock} />}
            </Grid>
            <Grid
              $display="flex"
              $alignItems="center"
              $margin="30px 0 0"
              $sm={{
                $alignItems: 'flex-start',
                $flexDirection: 'column-reverse',
                $margin: '20px 0 0',
                $padding: '0 15px',
              }}
            >
              {tagLink && (
                <Link href={tagLink} passHref legacyBehavior>
                  <Tag
                    $transparent
                    $position="relative"
                    $top="unset"
                    $right="unset"
                    $xl={{ $fontSize: '14px', $lineHeight: '22px' }}
                    $sm={{ $fontSize: '14px', $padding: '3px 11px' }}
                  >
                    {tagTitle}
                  </Tag>
                </Link>
              )}
              <Grid
                $display="flex"
                $gap="5px"
                $sm={{ $gap: '3px', $marginBottom: '20px', $width: '100%' }}
              >
                {isDefined(currentPublishedAt) && not(isEmptyString(currentPublishedAt)) && (
                  <Grid $margin={tagLink ? '0 0 0 15px' : '0'} $sm={{ $margin: '0' }}>
                    <Text $size={14} $weight={500} $lineHeight={17} $textTransform="uppercase">
                      {formatFullDate(currentPublishedAt)}
                    </Text>
                  </Grid>
                )}
                {isModificationDateVisible &&
                  isDefined(currentModifiedAt) &&
                  not(isEmptyString(currentModifiedAt)) && (
                    <>
                      <Grid>
                        <Text $display="inline-flex" $alignItems="center">
                          &#8226;
                        </Text>
                      </Grid>
                      <Grid>
                        <Text
                          $size={14}
                          $lineHeight={17}
                          $weight={500}
                          $textTransform="uppercase"
                          $maxWidth="min-content"
                        >
                          UPD:
                        </Text>
                      </Grid>
                      <Grid>
                        <Text $size={14} $weight={500} $lineHeight={17} $textTransform="uppercase">
                          {formatFullDate(currentModifiedAt)}
                        </Text>
                      </Grid>
                    </>
                  )}
              </Grid>
            </Grid>
          </Grid>
        </>
      );

    case CoverFormat.FullscreenCaptionOver:
      return (
        <>
          {metaBlock && <MetaBlock {...metaBlock} />}
          {seoTitleBlock && <SeoTitleBlock {...seoTitleBlock} />}
          {descriptionBlock && <DescriptionBlock {...descriptionBlock} />}

          <Grid $position="relative" $xl={{ $background: 'black' }}>
            {coverBlock && <CoverBlock {...coverBlock} />}
            <Grid
              $position="absolute"
              $maxWidth="60vw"
              $color="white"
              $zIndex="2"
              $bottom="80px"
              $left="120px"
              $xl={{
                $bottom: '0',
                $height: 'auto',
                $left: '0',
                $maxWidth: '100%',
                $padding: '40px 15px 30px',
                $position: 'relative',
              }}
              $sm={{ $height: '100%', $padding: '20px 15px 30px' }}
            >
              <Grid $display="flex" $alignItems="center" $sm={{ $padding: '0 0 20px' }}>
                {tagLink && (
                  <Link href={tagLink} passHref legacyBehavior>
                    <Tag
                      $transparentWhite
                      $position="relative"
                      $top="unset"
                      $right="unset"
                      $sm={{ $fontSize: '14px', $padding: '3px 11px' }}
                    >
                      {tagTitle}
                    </Tag>
                  </Link>
                )}
              </Grid>

              <Grid $marginTop="-10px" $sm={{ $margin: '0' }}>
                {h1Block && <H1Block {...h1Block} renderType={renderType} />}
              </Grid>
              {subtitleBlock && (
                <SubTitleBlock {...subtitleBlock} isOnCover={!!isFullscreenWithText} />
              )}

              <Grid
                $display="flex"
                $gap="5px"
                $sm={{ $gap: '3px', $marginBottom: '0', $width: '100%' }}
              >
                {isDefined(currentPublishedAt) && not(isEmptyString(currentPublishedAt)) && (
                  <Grid $marginTop="25px">
                    <Text $size={14} $weight={500} $lineHeight={17} $textTransform="uppercase">
                      {formatFullDate(currentPublishedAt)}
                    </Text>
                  </Grid>
                )}
                {isModificationDateVisible &&
                  isDefined(currentModifiedAt) &&
                  not(isEmptyString(currentModifiedAt)) && (
                    <>
                      <Grid $marginTop="25px" $display="inline-flex" $alignItems="center">
                        <Text $display="inline-flex" $alignItems="center">
                          &#8226;
                        </Text>
                      </Grid>
                      <Grid $marginTop="25px">
                        <Text
                          $size={14}
                          $lineHeight={17}
                          $weight={500}
                          $textTransform="uppercase"
                          $maxWidth="min-content"
                        >
                          UPD:
                        </Text>
                      </Grid>
                      <Grid $marginTop="25px">
                        <Text $size={14} $weight={500} $lineHeight={17} $textTransform="uppercase">
                          {formatFullDate(currentModifiedAt)}
                        </Text>
                      </Grid>
                    </>
                  )}
              </Grid>
            </Grid>
          </Grid>
        </>
      );
    default:
      return null;
  }
};
