import React, { FC } from 'react';

import { Grid, Image, Text } from '~atoms';

interface ImageAnswerProps {
  imageURL: string;
  text: string;
}

export const ImageAnswer: FC<ImageAnswerProps> = ({ imageURL, text }) => {
  return (
    <Grid>
      <Grid
        $imageObjectFit="cover"
        $justifyContent="center"
        $margin="1px"
        $width="332px"
        $height="223px"
        $position="relative"
        $transform="translateZ(1px)"
        $lg={{ $margin: '1px 0', $width: '100%' }}
      >
        <Image src={imageURL} alt="" />
      </Grid>
      <Grid $padding="4px 0 0 2px" $opacity="0">
        <Text $weight={600} $size={14} $lineHeight={20}>
          {text}
        </Text>
      </Grid>
    </Grid>
  );
};
