import { CoverFormat } from 'pn-backend';
import { __, always, cond, includes, T } from 'ramda';

import { HeaderType } from '~types';
import { isCoverBlock } from '~utils';

import { useLayout } from './useLayout';
import { usePost } from './usePost';

const getLongreadHeaderType = cond<[CoverFormat | undefined], HeaderType>([
  [
    includes<CoverFormat | undefined>(__, [CoverFormat.CaptionAbove, CoverFormat.CaptionUnder]),
    always(HeaderType.HEADER_INLINE_WITH_LOGO),
  ],
  [T, always(HeaderType.HEADER_LONGREAD)],
]);

export const useHeader = (): HeaderType => {
  const {
    isAbout,
    isAdvertisers,
    isArticle,
    isBlackScience,
    isCard,
    isLong,
    isNewsFull,
    isSport,
    isTest,
  } = useLayout();
  const { post } = usePost();
  const coverBlock = post.compiled.blocks.find(isCoverBlock);

  switch (true) {
    case isBlackScience:
      return HeaderType.HEADER_BLACK_SCIENCE;
    case isSport:
      return HeaderType.HEADER_ON_SPORT;
    case isCard:
    case isArticle:
    case isNewsFull:
    case isTest:
    case isAbout:
    case isAdvertisers:
      return HeaderType.HEADER_INLINE_WITH_LOGO;
    case isLong: {
      return getLongreadHeaderType(coverBlock?.compiled.format);
    }
    default:
      return HeaderType.HEADER_WITH_BIG_LOGO;
  }
};
