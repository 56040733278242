import { useEffect, useState } from 'react';

export const useMobile = () => {
  const [isMobile, setIsMobile] = useState<boolean | undefined>();

  useEffect(() => {
    if (window.innerWidth > 500) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  }, []);

  return isMobile;
};
