import { BlockType } from 'pn-backend';
import React, { FC } from 'react';

import { Grid } from '~atoms';
import { testsBlockMap } from '~blockMaps';
import { TestProps } from '~types';
import { blockTypeEquals, renderPostBlock } from '~utils';

export const Results: FC<TestProps> = ({ blocks }) => (
  <Grid $marginTop="40px" $lg={{ $margin: '0' }}>
    {blocks
      .filter(blockTypeEquals(BlockType.TestResults))
      .slice(0, 1)
      .map(renderPostBlock(testsBlockMap))}
  </Grid>
);
