import Link from 'next/link';
import { not } from 'ramda';
import React, { FC, useState } from 'react';

import { BlackScienceLogo, Close, Logo, SearchIcon } from '~assets';
import { Grid, Header } from '~atoms';
import { DEFAULT_GRID_PROPS, MOBILE_HEADER_HEIGHT } from '~constants';

import { SearchLine } from '../../SearchLine';

export const HeaderBlackScience: FC = () => {
  const [isOpenSearch, setIsOpenSearch] = useState(false);
  const toggleSearch = () => setIsOpenSearch(not);
  const closeSearch = () => setIsOpenSearch(false);

  return (
    <>
      <Header $backgroundColor="black" $sm={{ $height: MOBILE_HEADER_HEIGHT }}>
        <Grid
          $display="flex"
          $width="1200px"
          $justifyContent="space-between"
          $alignItems="center"
          $margin="0 auto"
          $xl={{ $padding: '0 15px', $width: '100%' }}
        >
          <Grid
            $width="193px"
            $height="25px"
            $color="white"
            $sm={{ $height: '23px', $width: '177px' }}
          >
            <Link href="/" passHref>
              <Logo />
            </Link>
          </Grid>
          <Grid $cursor="pointer">
            <Grid
              $transform={isOpenSearch ? 'scale(0)' : 'scale(1)'}
              $opacity={isOpenSearch ? 0 : 1}
              $position={isOpenSearch ? 'absolute' : 'relative'}
              $transition="0.2s ease-in-out"
            >
              <SearchIcon id="searchIcon" onClick={toggleSearch} />
            </Grid>
            <Grid
              $width="32px"
              $transform={isOpenSearch ? 'scale(1)' : 'scale(0)'}
              $opacity={isOpenSearch ? 1 : 0}
              $position={isOpenSearch ? 'relative' : 'absolute'}
              $zIndex={isOpenSearch ? '-10' : '10'}
              $transition="0.2s ease-in-out"
            >
              <Close onClick={toggleSearch} />
            </Grid>
          </Grid>
        </Grid>
        <SearchLine isOpen={isOpenSearch} closeSearch={closeSearch} />
      </Header>
      <Grid
        {...DEFAULT_GRID_PROPS}
        $padding="0 0 40px"
        $marginTop="10px"
        $xl={{ $marginTop: '0', $padding: '10px 15px 20px 15px' }}
      >
        <BlackScienceLogo />
      </Grid>
    </>
  );
};
