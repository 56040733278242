import React, { FC } from 'react';

import { Grid, Text } from '~atoms';
import { WatchAll } from '~components';
import { DEFAULT_GRID_PROPS, SectionAlias } from '~constants';
import { NewsItem } from '~postCards';
import { ButtonType, GridLayoutProps } from '~types';
import { getSectionLink } from '~utils';

export const MainNews: FC<GridLayoutProps> = ({ posts = [], title }) => {
  return (
    <Grid
      $position="relative"
      $margin="10px -50vw 20px"
      $zIndex="10"
      $width="100vw"
      $left="50%"
      $right="50%"
      $background="black"
    >
      <Grid
        id="main_news_block"
        $background="black"
        $color="white"
        $margin="10px -50vw 20px"
        $zIndex="10"
        $width="100vw"
        $left="50%"
        $right="50%"
        $position="relative"
        $xl={{ $maxWidth: '100%', $padding: '0 15px' }}
        $lg={{ $left: 'unset', $margin: '10px 0', $maxWidth: '100%', $right: 'unset' }}
        $sm={{ $margin: '0' }}
      >
        <Grid
          {...DEFAULT_GRID_PROPS}
          $padding="40px 0 60px"
          $gridTemplateAreas={'"title watchAll" "news news"'}
          $sm={{
            $gridTemplateAreas: '"title" "news" "watchAll"',
            $padding: '30px 0',
          }}
        >
          <Grid $gridArea="title" $padding="0 0 45px" $sm={{ $padding: '0 0 30px' }}>
            <Text
              $size={32}
              $lineHeight={35}
              $weight={600}
              $textTransform="uppercase"
              $sm={{ $lineHeight: 29, $size: 26 }}
            >
              {title}
            </Text>
          </Grid>
          <Grid
            $gridArea="watchAll"
            $width="fit-content"
            $padding="0 0 45px"
            $marginLeft="auto"
            $sm={{ $margin: '30px auto 0', $padding: '0' }}
          >
            <WatchAll type={ButtonType.BLACK} href={getSectionLink(SectionAlias.News)} />
          </Grid>
          <Grid $gap="40px" $gridArea="news" $sm={{ $gap: '20px' }}>
            {posts.slice(0, 5).map((post) => (
              <NewsItem key={post.id} post={post} isOnMain />
            ))}
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid */}
      {/*  $background="white" */}
      {/*  $height="10000vh" */}
      {/*  $width="100vw" */}
      {/*  $position="absolute" */}
      {/*  $marginTop="100px" */}
      {/* /> */}
    </Grid>
  );
};
