import { config } from '~services/config';

export const SECOND = 1;

export const MINUTE = 60 * SECOND;

export const HOUR = 60 * MINUTE;

export const DAY = 24 * HOUR;

export const YEAR = 365 * DAY;

export const DEFAULT_CACHE_REVALIDATE_TIME = 30 * SECOND;

export const DEFAULT_CACHE_API = 2 * MINUTE;

export const DEFAULT_GRID_PROPS = {
  $margin: 'auto',
  $maxWidth: '1200px',
  $padding: '30px 0',
  $width: '100%',
  $zIndex: 1,
};

export const NEXT_NOT_FOUND = {
  notFound: true as const,
};

export const NEXT_NOT_FOUND_WITH_REVALIDATE = {
  ...NEXT_NOT_FOUND,
  revalidate: DEFAULT_CACHE_REVALIDATE_TIME,
};

export const DEFAULT_CACHE_CONTROL = config.CACHE_CONTROL || 's-maxage=15, must-revalidate';
export const DEFAULT_NO_CACHE = 's-max-age=0, no-cache';
