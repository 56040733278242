import {
  Block,
  Post,
  PostType,
  PostTypeBlockMap,
  postTypeBlocksMap,
} from '../types';
import { createEmptyBlocks } from './createEmptyBlocks';
import { createEmptyDefaultChildBlocks } from './createEmptyDefaultChildBlocks';

export function createEmptyDefaultBlocks<P extends PostType>(
  post: Post<P>,
): Partial<Block<PostTypeBlockMap[P]>>[] {
  const [defaultFixedBlockTypes, defaultBlockTypes] =
    postTypeBlocksMap[post.type];

  const blocks = createEmptyBlocks<PostTypeBlockMap[P]>(
    defaultFixedBlockTypes,
    defaultBlockTypes,
    [],
  ).map((block) => ({
    ...block,
    blocks: createEmptyDefaultChildBlocks(block.type),
    createdById: post.createdById,
    files: [],
    postId: post.id,
  }));

  return blocks;
}
