import React, { ComponentType } from 'react';

import { BlockProps, GridLayoutProps, PostProps, TestProps } from '~types';

import { log } from './log';

export const renderGridLayoutByComponent =
  (Component: ComponentType<GridLayoutProps>) =>
  (gridLayoutProps: GridLayoutProps, index: number) => {
    log('info', 'renderGridLayout')({ ...gridLayoutProps });
    return <Component key={index} {...gridLayoutProps} />;
  };

export const renderPostLayoutByComponent =
  (Component: ComponentType<PostProps>) => (postProps: PostProps, index: number) => {
    log('info', 'renderPostLayout')({ ...postProps });
    return <Component key={index} {...postProps} />;
  };

export const renderPostBlockByComponent =
  (Component: ComponentType<BlockProps>) => (blockProps: BlockProps, index: number) => {
    log('info', 'renderPostBlock')({ ...blockProps });
    return <Component key={index} {...blockProps} />;
  };

export const renderTestStepByComponent =
  (Component: ComponentType<TestProps>) => (testProps: TestProps, index: number) => {
    log('info', 'renderTestStep')({ ...testProps });
    return <Component key={index} {...testProps} />;
  };
