import { pathOr, pipe, propOr } from 'ramda';
import { CSSProperties } from 'react';
import styled, { css } from 'styled-components';

import { devices, Screen } from '~constants';
import { getColor } from '~utils';

type HeaderProp = {
  $backgroundColor?: CSSProperties['backgroundColor'];
  $borderBottom?: CSSProperties['borderBottom'];
  $display?: CSSProperties['display'];
  $flexDirection?: CSSProperties['flexDirection'];
  $height?: CSSProperties['height'];
  $lineHeight?: CSSProperties['lineHeight'];
  $margin?: CSSProperties['margin'];
  $marginBottom?: CSSProperties['marginBottom'];
  $maxHeight?: CSSProperties['maxHeight'];
  $size?: number;
  $textTransform?: CSSProperties['textTransform'];
  $top?: CSSProperties['top'];
  $transform?: CSSProperties['transform'];
  $weight?: CSSProperties['fontWeight'];
  $width?: CSSProperties['width'];
};

type Props = HeaderProp & { [key in Screen]?: HeaderProp };

export const Header = styled.header<Props>`
  z-index: 1000000;
  position: sticky;
  top: ${propOr('0', '$top')};
  height: ${propOr('70px', '$height')};
  display: flex;
  aligh-items: center;
  background-color: ${pipe(propOr('white', '$backgroundColor'), getColor)};
  margin-bottom: ${propOr(null, '$marginBottom')};
  margin: ${propOr(null, '$margin')};
  width: ${propOr(null, '$width')};
  flex-direction: ${propOr(null, '$flexDirection')};
  border-bottom: ${propOr(null, '$borderBottom')};
  display: ${propOr(null, '$display')};
  transform: ${propOr(null, '$transform')};

  ${(props) =>
    Object.entries(devices).map(
      ([field, width]) =>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        props[field] &&
        css`
          @media only screen and (max-width: ${width}) {
            height: ${pathOr(null, [field, '$height'], props)};
            display: ${pathOr(null, [field, '$display'], props)};
            margin-bottom: ${pathOr(null, [field, '$marginBottom'], props)};
            transform: ${pathOr(null, [field, '$transform'], props)};
          }
        `,
    )}
`;
