// eslint-disable-next-line import/no-extraneous-dependencies
import { uniq } from 'lodash';

import { PostType, PostTypeBlockMap, postTypeBlocksMap } from '../types';

export function getAvailableBlockTypes<T extends PostType>(
  postType: T,
): PostTypeBlockMap[T][] {
  const [, defaultBlockTypes, additionalBlockTypes] =
    postTypeBlocksMap[postType];

  return uniq([...defaultBlockTypes, ...additionalBlockTypes]);
}
