import { BlockType, QuoteType } from 'pn-backend';
import React, { FC } from 'react';

import { Quote } from '~assets';
import { Grid, Image, Text } from '~atoms';
import { Tooltips } from '~components';
import { useLayout } from '~hooks';
import { BlockProps } from '~types';
import { isEmptyString } from '~utils';

const defaultProps = {
  $margin: 'auto',
  $marginTop: '40px',
  $maxWidth: '750px',
  $width: '100%',
};

export const QuoteBlock: FC<BlockProps<BlockType.Quote>> = ({
  compiled: { caption, expertName, html, imageURL, type },
}) => {
  const { isCard } = useLayout();

  switch (type) {
    case QuoteType.Line:
      return (
        <Grid
          {...defaultProps}
          $marginTop={isCard ? '0px' : defaultProps.$marginTop}
          $md={{ $maxWidth: 'unset', $padding: '0 15px' }}
          $sm={{ $marginTop: isCard ? '0px' : '30px', $padding: isCard ? '0' : '0 15px' }}
        >
          <Grid
            $display="flex"
            $flexFlow="column"
            $gap="20px"
            $padding=" 0 0 0 20px"
            $borderLeft="1px solid var(--color-black)"
            $sm={{ $padding: '0 0 0 10px' }}
          >
            <Tooltips html={html} />
            <Text
              $weight={500}
              $size={20}
              $lineHeight={28}
              $color="black"
              $display="grid"
              $gap="30px"
              $innerPMarginTop={0}
              $innerPMarginBottom={0}
              $textWithLink
              $sm={{ $lineHeight: 25, $size: 18 }}
              dangerouslySetInnerHTML={{ __html: html }}
            />

            {!isEmptyString(caption) && (
              <Text
                $weight={400}
                $size={16}
                $lineHeight={22}
                $color="black"
                $opacity="0.5"
                $textWithLink
                $sm={{ $lineHeight: 20, $size: 14 }}
              >
                一 {caption}
              </Text>
            )}
          </Grid>
        </Grid>
      );

    case QuoteType.Frame:
      return (
        <Grid
          {...defaultProps}
          $marginTop={isCard ? '0px' : defaultProps.$marginTop}
          $md={{ $maxWidth: 'unset', $padding: '0 15px' }}
          $sm={{ $marginTop: isCard ? '0px' : '30px', $padding: isCard ? '0' : '0 15px' }}
        >
          <Grid
            $padding="30px"
            $border="1px solid var(--color-black)"
            $display="flex"
            $position="relative"
            $sm={{ $flexDirection: 'column', $padding: '20px 20px 36px' }}
          >
            {!isEmptyString(imageURL) && (
              <Grid
                $position="relative"
                $minWidth="90px"
                $width="90px"
                $height="90px"
                $border="1px solid var(--color-black)"
                $margin="0 30px 0 0"
                $sm={{ $margin: '0 0 10px 0' }}
              >
                <Grid
                  $zIndex={1}
                  $position="absolute"
                  $width="100%"
                  $height="100%"
                  className="imageOverlay"
                />
                <Image src={imageURL} alt={caption} />
              </Grid>
            )}
            <Grid $display="flex" $flexFlow="column" $gap="20px" $sm={{ $gap: '10px' }}>
              <Tooltips html={html} />
              <Text
                $weight={500}
                $size={20}
                $lineHeight={28}
                $color="black"
                $innerPMarginTop={0}
                $innerPMarginBottom={0}
                $textWithLink
                $display="grid"
                $gap="30px"
                $sm={{ $lineHeight: 25, $size: 18 }}
                dangerouslySetInnerHTML={{ __html: html }}
              />
              <Text
                $weight={400}
                $size={16}
                $lineHeight={22}
                $color="black"
                $opacity="0.5"
                $textWithLink
                $sm={{ $lineHeight: 20, $size: 14 }}
              >
                {caption ? `一 ${caption}` : ' '}
              </Text>
            </Grid>
            <Grid
              $position="absolute"
              $width="23px"
              $height="16px"
              $transform="rotate(180deg)"
              $bottom="30px"
              $right="30px"
              $sm={{ $bottom: '10px', $right: '10px' }}
            >
              <Quote />
            </Grid>
          </Grid>
        </Grid>
      );

    case QuoteType.Expert:
      return (
        <Grid
          {...defaultProps}
          $display="flex"
          $flexFlow="row"
          $gap="20px"
          $marginTop={isCard ? '0px' : defaultProps.$marginTop}
          $md={{ $maxWidth: 'unset', $padding: '0 15px' }}
          $sm={{
            $flexFlow: 'column',
            $marginTop: isCard ? '0px' : '30px',
            $padding: isCard ? '0' : '0 15px',
          }}
        >
          {!isEmptyString(imageURL) && (
            <Grid
              $position="relative"
              $minWidth="125px"
              $width="125px"
              $height="125px"
              $imageObjectFit="fill"
              $border="1px solid var(--color-black)"
            >
              <Image src={imageURL} alt={caption} />
            </Grid>
          )}
          <Grid $display="flex" $flexFlow="column" $justifyContent="flex-end" $gap="5px">
            <Text
              $weight={600}
              $size={20}
              $lineHeight={23}
              $color="black"
              $textTransform="uppercase"
              $textWithLink
              $sm={{ $lineHeight: 23, $size: 18 }}
            >
              {expertName}
            </Text>
            {!isEmptyString(caption) && (
              <Text $weight={400} $size={18} $lineHeight={23} $sm={{ $lineHeight: 20, $size: 16 }}>
                {caption}
              </Text>
            )}
          </Grid>
        </Grid>
      );

    case QuoteType.Selection:
      return (
        <Grid
          {...defaultProps}
          $marginTop={isCard ? '0px' : defaultProps.$marginTop}
          $md={{ $maxWidth: 'unset', $padding: '0 15px' }}
          $sm={{ $marginTop: isCard ? '0px' : '30px', $padding: isCard ? '0' : '0 15px' }}
        >
          <Grid $display="flex" $flexFlow="row" $gap="30px" $sm={{ $gap: '10px' }}>
            <Grid $minWidth="38px" $height="31px" $sm={{ $minWidth: '27px' }}>
              <Quote />
            </Grid>
            <Tooltips html={html} />
            <Text
              $size={27}
              $lineHeight={30}
              $weight={600}
              $innerPMarginBottom={0}
              $innerPMarginTop={0}
              $textTransform="uppercase"
              $textWithLink
              $display="grid"
              $gap="30px"
              $sm={{ $lineHeight: 24, $size: 22 }}
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </Grid>
        </Grid>
      );

    case QuoteType.SelectionLine:
      return (
        <Grid
          {...defaultProps}
          $marginTop={isCard ? '0px' : defaultProps.$marginTop}
          $md={{ $maxWidth: 'unset', $padding: '0 15px' }}
          $sm={{ $marginTop: isCard ? '0px' : '30px', $padding: isCard ? '0' : '0 15px' }}
        >
          <Grid $display="flex" $flexFlow="row" $gap="20px" $sm={{ $gap: '10px' }}>
            <Grid $borderLeft="1px solid var(--color-black)" />
            <Tooltips html={html} />
            <Text
              $size={27}
              $lineHeight={30}
              $weight={600}
              $innerPMarginBottom={0}
              $innerPMarginTop={0}
              $textTransform="uppercase"
              $textWithLink
              $display="grid"
              $gap="30px"
              $sm={{ $lineHeight: 24, $size: 22 }}
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </Grid>
        </Grid>
      );

    default:
      return null;
  }
};
