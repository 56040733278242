import classNames from 'classnames';
import Link from 'next/link';
import React, { FC } from 'react';

import { Grid, Image, Tag, Text } from '~atoms';
import { useLayout } from '~hooks';
import { GridPostCardProps } from '~types';
import { getPostPreviewSummary } from '~utils';

import styles from './styles.module.scss';

interface Props extends GridPostCardProps {
  // Требуется для постов на главной
  mainBS?: boolean;
  // Требуется для постов на главной
  mainSport?: boolean;
}

export const SubsectionCard: FC<Props> = ({ mainBS, mainSport, post }) => {
  const { coverUrl, postLink, tagLink, tagTitle, title } = getPostPreviewSummary(post);
  const { isBlackScience, isSport } = useLayout();

  const blackScience = isBlackScience || mainBS;
  const sport = isSport || mainSport;

  return (
    <Grid
      $minHeight="394px"
      $lg={{ $minHeight: '245px' }}
      $sm={{ $minHeight: '232px' }}
      $position="relative"
    >
      {tagLink && (
        <Link href={tagLink} passHref legacyBehavior>
          <Tag
            $blackScience={blackScience}
            $sport={sport}
            $lg={{
              $fontSize: '14px',
              $lineHeight: '22px',
              $padding: '3px 10px',
              $right: '10px',
              $top: '10px',
            }}
          >
            {tagTitle}
          </Tag>
        </Link>
      )}
      <Link
        href={postLink}
        passHref
        className={classNames(
          styles.subsectionCard,
          blackScience && styles.blackscience,
          sport && styles.sport,
        )}
      >
        <Grid $position="relative">
          {coverUrl && title ? (
            <>
              <Grid
                $zIndex={1}
                $position="absolute"
                $width="100%"
                $height="394px"
                className={styles.imageOverlay}
                $lg={{ $height: '300px' }}
                $md={{ $height: '243px' }}
                $sm={{ $height: '232px' }}
              />
              <Grid
                $height="394px"
                $width="100%"
                $position="relative"
                $lg={{ $height: '300px' }}
                $md={{ $height: '243px' }}
                $sm={{ $height: '232px' }}
              >
                <Image src={coverUrl.medium} alt={title} />
              </Grid>
            </>
          ) : null}
        </Grid>
        {title && (
          <Grid
            $padding="20px 0 0 0"
            $position="absolute"
            $bottom="30px"
            $left="15px"
            $right="15px"
            $zIndex="2"
            $lg={{
              $bottom: '30px',
              $left: '15px',
              $padding: '10px 0 0 0',
              $right: '15px',
            }}
          >
            <Text
              $weight={600}
              $size={26}
              $lineHeight={29}
              $color="white"
              className={styles.title}
              $lg={{ $lineHeight: 22, $size: 20 }}
              $sm={{ $lineHeight: 20, $size: 18 }}
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </Grid>
        )}
      </Link>
    </Grid>
  );
};
