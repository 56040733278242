import { RawAxiosResponseHeaders } from 'axios';
import { setCookie } from 'cookies-next';
import { NextApiRequest, NextApiResponse } from 'next';
import cookieParser from 'set-cookie-parser';

export const setCookiesFromHeaders = (
  headers: RawAxiosResponseHeaders,
  req: NextApiRequest,
  res: NextApiResponse,
) => {
  const splitCookieHeaders = cookieParser.splitCookiesString(headers?.['set-cookie']);
  const cookies = cookieParser.parse(splitCookieHeaders);
  cookies.forEach((cookie) => {
    // ToDo Сохранять options
    const { name, value, ...options } = cookie;
    setCookie(name, value, {
      req,
      res,
    });
  });
};

export const serializeCookies = (cookies: NextApiRequest['cookies']) =>
  Object.entries(cookies)
    .map(([key, value]) => `${key}=${value}`)
    .join(';');
