import { pipe, propOr } from 'ramda';
import { CSSProperties } from 'react';
import styled, { css } from 'styled-components';

import { getColor } from '~utils';

interface Props {
  $color?: string;
  $height?: CSSProperties['height'];
  $inverse?: boolean;
  $small?: boolean;
  $width?: CSSProperties['width'];
}

export const RoundIcon = styled.div<Props>`
  width: ${propOr('32px', '$width')};
  height: ${propOr('32px', '$height')};
  background: var(--color-white);
  border-radius: 30px;
  text-align: center;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;
  display: grid;
  justify-content: center;
  align-items: center;

  ${(props) =>
    props.$inverse &&
    css`
      background: var(--color-black);

      & svg path {
        fill: var(--color-white);
      }
    `}

  ${(props) =>
    props.$small &&
    css`
      width: 24px;
      height: 24px;
    `}

  &:hover {
    background: ${pipe(propOr(null, '$color'), getColor)};
  }
`;
