import { useRouter } from 'next/router';
import { SearchSortDirection, SearchSortField } from 'pn-backend';
import React, { FC, useRef } from 'react';

import { Grid } from '~atoms';
import { HEADER_HEIGHT, MOBILE_HEADER_HEIGHT } from '~constants';
import { useLayout, useOnClickOutside } from '~hooks';

import { Search } from '../../Search';

type SearchLineProps = {
  closeSearch: () => void;
  dir?: SearchSortDirection;
  isOpen: boolean;
  sort?: SearchSortField;
};

export const SearchLine: FC<SearchLineProps> = ({ closeSearch, dir, isOpen, sort }) => {
  const searchRef = useRef<HTMLDivElement>(null);

  const { pathname } = useRouter();

  const { isBlackScience, isSport, isTest } = useLayout();
  const isBlack = isBlackScience || isSport || isTest;

  useOnClickOutside(searchRef, (event: React.MouseEvent) => {
    if (event.target instanceof Element) {
      const isSearchElem = event.target.closest('#searchIcon');
      if (!isSearchElem) closeSearch();
    }
  });

  if (pathname === '/search') return null;

  return (
    <Grid
      ref={searchRef}
      $gridAutoFlow="column"
      $margin="auto"
      // $maxWidth="1400px"
      $justifyContent="space-between"
      $gridAutoColumns="1fr"
      $alignItems="center"
      $gap="40px"
      $background={isBlack ? 'black' : 'white'}
      $maxHeight={isOpen ? '200px' : '0'}
      $overflow="hidden"
      $transition="max-height 0.2s ease-in-out"
      $position="absolute"
      $top={HEADER_HEIGHT}
      $left="0"
      $right="0"
      $xl={{ $maxWidth: '100%', $padding: '0 15px' }}
      $lg={{ $padding: '0 15px' }}
      $sm={{ $height: '50px', $marginTop: '0', $padding: '0 15px', $top: MOBILE_HEADER_HEIGHT }}
    >
      <Search isOpen={isOpen} closeSearch={closeSearch} dir={dir} sort={sort} />
    </Grid>
  );
};
