import React, { FC } from 'react';

import { Grid, Text } from '~atoms';
import { DEFAULT_GRID_PROPS } from '~constants';
import { PostOfTheWeek } from '~postCards';
import { GridLayoutProps } from '~types';

export const MainPostOfTheWeek: FC<GridLayoutProps> = ({ posts, title = [] }) => (
  <Grid
    {...DEFAULT_GRID_PROPS}
    $padding="0 0 10px"
    $gap="30px"
    $zIndex="10"
    $xl={{ $maxWidth: '100%', $padding: '0 15px' }}
    $sm={{ $gap: '20px' }}
  >
    <Text
      $size={32}
      $lineHeight={34}
      $weight={600}
      $color="black"
      $textTransform="lowercase"
      $sm={{ $lineHeight: 29, $size: 26 }}
    >
      {title}
    </Text>
    {posts.slice(0, 1).map((post) => (
      <PostOfTheWeek key={post.id} post={post} />
    ))}
  </Grid>
);
