import React, { FC } from 'react';

import { Grid } from '~atoms';
import { DEFAULT_GRID_PROPS } from '~constants';
import { Top } from '~postCards';
import { GridLayoutProps } from '~types';

export const MainTop: FC<GridLayoutProps> = ({ posts = [] }) => (
  <Grid
    {...DEFAULT_GRID_PROPS}
    $padding="0"
    $xl={{ $padding: '0 15px 20px' }}
    $lg={{ $padding: '0 15px' }}
  >
    {posts.slice(0, 1).map((post) => (
      <Top key={post.id} post={post} />
    ))}
  </Grid>
);
