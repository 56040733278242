import React, { FC } from 'react';

import { TestEmpty, TestGreen, TestRed } from '~assets';
import { Grid, Image, Text } from '~atoms';
import { AnswerOptionProps } from '~types';

interface AnswerOptionPropsExtended extends AnswerOptionProps {
  hideRightAnswer: boolean;
  isSelected: boolean;
  selectedAnswer: number | null;
  setAnswer: (isRightAnswer: boolean) => void;
  setSelectedAnswer: (index: number) => void;
}

export const ImageAnswerOverflow: FC<AnswerOptionPropsExtended> = ({
  hideRightAnswer,
  imageURL,
  index,
  isRightAnswer,
  isSelected,
  selectedAnswer,
  setAnswer,
  setSelectedAnswer,
  text,
}) => {
  const handleAnswer = () => {
    if (!isSelected) {
      setSelectedAnswer(index);
      setAnswer(isRightAnswer);
    }
  };

  const isThisAnswerSelected = selectedAnswer === index;

  const getBorderColor = () => {
    if (hideRightAnswer && isThisAnswerSelected) return '3px solid var(--color-blue)';
    if (isSelected && isThisAnswerSelected && isRightAnswer) {
      return '3px solid var(--color-alert)';
    }
    if (isSelected && isThisAnswerSelected && !isRightAnswer) {
      return '3px solid var(--color-fail)';
    }
    return '3px solid var(--color-white)';
  };

  const getDescriptionColor = () => {
    if (hideRightAnswer) return 'black';
    if (isSelected && isThisAnswerSelected && isRightAnswer) {
      return 'alert';
    }
    if (isSelected && isThisAnswerSelected && !isRightAnswer) {
      return 'fail';
    }
    return 'black';
  };

  return (
    <Grid $position="relative" $width="336px" key={index} $lg={{ $width: '100%' }}>
      <Grid $overflow="hidden">
        <Grid
          onClick={handleAnswer}
          $cursor="pointer"
          $border={getBorderColor()}
          $overflow="hidden"
          $imageObjectFit="cover"
          $hoverBorder={isSelected ? 'null' : '3px solid var(--color-blue)'}
          $hoverPadding={isSelected ? 'null' : '-1px'}
          $position="relative"
          $width="336px"
          $height="225px"
          $lg={{ $width: '100%' }}
        />
        <Grid
          $opacity={
            isThisAnswerSelected ||
            (isSelected && isRightAnswer && !hideRightAnswer) ||
            (hideRightAnswer && isThisAnswerSelected)
              ? '1'
              : '0'
          }
          $position="absolute"
          $right="10px"
          $top="10px"
          $width="20px"
          $height="20px"
          $background="white"
          $borderRadius="50px"
          $transform="translateZ(1px)"
          $alignItems="center"
          $justifyContent="center"
        >
          {!hideRightAnswer && isRightAnswer && <TestGreen />}
          {!hideRightAnswer && !isRightAnswer && <TestRed />}
          {hideRightAnswer && <TestEmpty />}
        </Grid>
      </Grid>
      <Grid $padding="4px 0 0 2px">
        <Text $weight={600} $size={14} $lineHeight={20} $color={getDescriptionColor()}>
          {text}
        </Text>
      </Grid>
    </Grid>
  );
};
