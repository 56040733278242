import { BlockType, PostType } from 'pn-backend';
import { always, cond, CondPair, pickAll, pipe, propEq, T, tap } from 'ramda';
import { ComponentType, ReactElement } from 'react';

import { BlockProps, BlockTypeToBlockComponentMap, PostTypeToBlockComponentMap } from '~types';

import { log } from './log';
import { renderPostBlockByComponent } from './render';

export const blockTypeEquals = (blockType: BlockType) =>
  propEq<keyof BlockProps>('type', blockType);

export const isSubtitleBlock = (block: BlockProps): block is BlockProps<BlockType.Subtitle> =>
  blockTypeEquals(BlockType.Subtitle)(block);

export const isSeoTitleBlock = (block: BlockProps): block is BlockProps<BlockType.SeoTitle> =>
  blockTypeEquals(BlockType.SeoTitle)(block);

export const isDescriptionBlock = (block: BlockProps): block is BlockProps<BlockType.Description> =>
  blockTypeEquals(BlockType.Description)(block);

export const isH1Block = (block: BlockProps): block is BlockProps<BlockType.H1> =>
  blockTypeEquals(BlockType.H1)(block);

export const isH2Block = (block: BlockProps): block is BlockProps<BlockType.H2> =>
  blockTypeEquals(BlockType.H2)(block);

export const isH3Block = (block: BlockProps): block is BlockProps<BlockType.H2> =>
  blockTypeEquals(BlockType.H3)(block);

export const isIntroBlock = (block: BlockProps): block is BlockProps<BlockType.Intro> =>
  blockTypeEquals(BlockType.Intro)(block);

export const isTextBlock = (block: BlockProps): block is BlockProps<BlockType.Text> =>
  blockTypeEquals(BlockType.Text)(block);

export const isCardBlock = (block: BlockProps): block is BlockProps<BlockType.Card> =>
  blockTypeEquals(BlockType.Card)(block);

export const isCoverBlock = (block: BlockProps): block is BlockProps<BlockType.Cover> =>
  blockTypeEquals(BlockType.Cover)(block);

export const isTestBlock = (block: BlockProps): block is BlockProps<BlockType.Test> =>
  blockTypeEquals(BlockType.Test)(block);

export const isMetaBlock = (block: BlockProps): block is BlockProps<BlockType.Meta> =>
  blockTypeEquals(BlockType.Meta)(block);

export const isTestQuestionBlock = (
  block: BlockProps,
): block is BlockProps<BlockType.TestQuestion> => blockTypeEquals(BlockType.TestQuestion)(block);

export const renderPostBlock = <T extends PostType>(blockMap: PostTypeToBlockComponentMap<T>) =>
  cond<[BlockProps, number], ReactElement | null>([
    ...Object.entries(blockMap).map(
      ([blockType, Component]) =>
        [
          blockTypeEquals(blockType as BlockType),
          renderPostBlockByComponent(Component as ComponentType<BlockProps>),
        ] as CondPair<[BlockProps, number], ReactElement>,
    ),
    [T, pipe(pickAll(['type']), tap(log('error', 'Assertion: unknown post block')), always(null))],
  ]);

export const renderChildBlock = (blockMap: BlockTypeToBlockComponentMap<BlockType.Card>) =>
  cond<[BlockProps, number], ReactElement | null>([
    ...Object.entries(blockMap).map(
      ([blockType, Component]) =>
        [
          blockTypeEquals(blockType as BlockType),
          renderPostBlockByComponent(Component as unknown as ComponentType<BlockProps>),
        ] as CondPair<[BlockProps, number], ReactElement>,
    ),
    [T, pipe(pickAll(['type']), tap(log('error', 'Assertion: unknown child block')), always(null))],
  ]);

export const removeParagraph = (html: string): string => {
  return html
    .replace(/<p\s?.*?>/, '')
    .replace(/<\/p>/, '')
    .replace(/&#8232;/g, ' ')
    .trim();
};
