// eslint-disable-next-line import/no-extraneous-dependencies
import { faker } from '@faker-js/faker';

import { Author, AuthorStatus } from '../types';
import { CreateFactoryOptions } from './types/CreateFactoryOptions';
import { Factory } from './types/Factory';

faker.setLocale('ru');

export function createAuthorFactory(
  options?: CreateFactoryOptions,
): Factory<Author> {
  let currentId = options?.initId || 0;

  return (data) => {
    const author: Author = {
      avatar: undefined,
      avatarId: data?.avatar?.id,
      createdAt: undefined,
      createdBy: undefined,
      createdById: data?.createdBy?.id,
      deletedAt: undefined,
      employee: undefined,
      employeeId: data?.employee?.id,
      id: ++currentId,
      name: faker.name.fullName(),
      status: faker.helpers.objectValue(AuthorStatus),
      updatedAt: undefined,
      updatedBy: undefined,
      updatedById: data?.updatedBy?.id,
      ...data,
    } as Author;

    return author;
  };
}
