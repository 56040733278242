import { BlockType } from 'pn-backend';
import React, { FC, useEffect, useRef, useState } from 'react';

import { Grid, Image, Text } from '~atoms';
import { usePost } from '~hooks';
import { clientApi } from '~services';
import { BlockProps, HttpStatusCode } from '~types';
import { getPostSummary } from '~utils';

const defaultProps = {
  $margin: 'auto',
  $marginTop: '40px',
  $maxWidth: '640px',
  $width: '100%',
};

export const QuizBlock: FC<BlockProps<BlockType.Quiz>> = ({ compiled, id: blockId }) => {
  const { answerOptions, caption, imageURL, question, voted } = compiled;

  const isMountRef = useRef(true);
  const [currentAnswerOptions, setCurrentAnswerOptions] = useState(answerOptions);
  const [currentVoted, setCurrentVoted] = useState(voted);

  const { post } = usePost();
  const { postId } = getPostSummary(post);

  // ToDo Обработать ошибки
  const handleVote = async (optionId: number) => {
    const voteResponse = await clientApi.voteQuiz({ blockId, optionId, postId });
    if (voteResponse.status === HttpStatusCode.Ok) {
      setCurrentVoted(true);
    }
  };

  useEffect(() => {
    // Запускаем на клиенте, чтобы обработать куки через api handler
    // ToDo Обработать ошибки
    if (isMountRef.current || currentVoted) {
      clientApi.getQuiz({ blockId, postId }).then((result) => {
        if (result.data) {
          setCurrentAnswerOptions(result.data.compiled.answerOptions);
          setCurrentVoted(result.data.compiled.voted);
        }
      });
    }
    isMountRef.current = false;
  }, [blockId, currentVoted, postId]);

  return (
    <Grid {...defaultProps} $sm={{ $marginTop: '30px', $padding: '0 15px' }}>
      <Grid $background="black" $border="1px solid var(--color-black)">
        <Text
          $weight={700}
          $size={16}
          $lineHeight={19}
          $textTransform="uppercase"
          $color="white"
          $padding="15px 20px"
          $sm={{ $lineHeight: 17, $size: 14, $weight: 700 }}
        >
          ОПРОС: ОТВЕТЬТЕ НА ОДИН ВОПРОС
        </Text>
      </Grid>

      <Grid $border="1px solid var(--color-black)" $padding="20px">
        <Grid $position="relative" $width="100%">
          {imageURL && (
            <Grid $position="relative" $height="300px" $width="100%" $sm={{ $height: '172px' }}>
              <Image src={imageURL} alt={caption} />
            </Grid>
          )}

          {caption && (
            <Text $size={12} $lineHeight={17} $weight={400} $marginTop="5px" $color="gray">
              {caption}
            </Text>
          )}
        </Grid>

        {question && (
          <Grid $marginTop="12px">
            <Text
              $weight={500}
              $size={20}
              $lineHeight={28}
              $sm={{ $lineHeight: 28, $size: 20, $weight: 500 }}
            >
              {question}
            </Text>
          </Grid>
        )}

        <Grid $alignItems="center" $marginTop="20px" $sm={{ $marginTop: '10px' }}>
          {currentAnswerOptions.map((option, index) => {
            const { percent, selected, title } = option;
            const onOptionClick = async () => {
              await handleVote(index);
            };
            const isActive = selected;
            const isSelectable = !currentVoted;

            return (
              <Grid
                key={index}
                $cursor={isSelectable ? 'pointer' : 'default'}
                $border="1px solid var(--color-black)"
                $marginTop="-1px"
                $alignItems="center"
                $padding="0 20px"
                $transition="all 0.2s ease-in-out"
                $overflow="hidden"
                $quizItem={isSelectable}
                onClick={isSelectable ? onOptionClick : undefined}
                $sm={{ $padding: '0 10px' }}
              >
                <Grid $display="flex" $padding="16px 0" $alignItems="center">
                  <Grid
                    $border={
                      isActive ? '2px solid var(--color-blue)' : '2px solid var(--color-black)'
                    }
                    $width="24px"
                    $minWidth="24px"
                    $height="24px"
                    $borderRadius="50px"
                    $margin="0 10px 0 0"
                    $position="relative"
                  >
                    {isActive && (
                      <Grid
                        $width="16px"
                        $height="16px"
                        $background="blue"
                        $position="absolute"
                        $borderRadius="50px"
                        $top="2px"
                        $left="2px"
                      />
                    )}
                  </Grid>
                  <Text
                    $weight={600}
                    $size={16}
                    $lineHeight={22}
                    $color={isActive ? 'blue' : 'black'}
                    $sm={{ $lineHeight: 22, $size: 16 }}
                  >
                    {title}
                  </Text>
                </Grid>
                <Grid
                  $padding={isSelectable ? '0' : '0 0 16px'}
                  $display="flex"
                  $alignItems="center"
                  $transition="all 0.2s ease-in-out"
                  $justifyContent="space-between"
                  $height={isSelectable ? '0' : 'auto'}
                  $opacity={isSelectable ? '0' : '1'}
                >
                  <Text
                    $weight={600}
                    $size={14}
                    $lineHeight={2}
                    $color="blue"
                  >{`${percent}%`}</Text>
                  <Grid
                    $width="93%"
                    $height="3px"
                    $background="gray-lighter"
                    $sm={{ $width: '86%' }}
                  >
                    <Grid
                      $maxWidth={isSelectable ? '0' : `${percent}%`}
                      $width="100%"
                      $height="100%"
                      $background="postnews-blue"
                      $transition="all 0.5s ease-in-out 0.5s"
                    />
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};
