// eslint-disable-next-line import/no-extraneous-dependencies
import { faker } from '@faker-js/faker';
// eslint-disable-next-line import/no-extraneous-dependencies
import { omit } from 'lodash';

import {
  Post,
  PostStatus,
  PostType,
  PostTypeBlockMap,
  postTypeBlocksMap,
} from '../types';
import { createEmptyBlocks } from './createEmptyBlocks';
import { CreateFactoryOptions } from './types/CreateFactoryOptions';
import { PostFactories } from './types/PostFactories';
import { PostFactory } from './types/PostFactory';

export function createPostFactory(
  options: CreateFactoryOptions & { factories: PostFactories },
): PostFactory {
  let currentId = options?.initId || 1;

  return <T extends PostType>(type: T, data?: Partial<Post<T>>) => {
    const [defaultFixedBlockTypes, defaultBlockTypes, additinalBlockTypes] =
      postTypeBlocksMap[type];

    const blocks = createEmptyBlocks<PostTypeBlockMap[T]>(
      defaultFixedBlockTypes,
      defaultBlockTypes,
      additinalBlockTypes,
    ).map((block) =>
      options.factories.createBlock(block.type, omit(block, 'content')),
    );

    const post: Post<T> = {
      authors: [],
      compiled: {
        blocks: [],
      },
      createdAt: undefined,
      createdBy: undefined,
      createdById: data?.createdBy?.id,
      deletedAt: undefined,
      error: undefined,
      id: currentId++,
      publishedAt: undefined,
      sections: [],
      status: faker.helpers.objectValue(PostStatus),
      tags: [],
      type,
      updatedAt: undefined,
      updatedBy: undefined,
      updatedById: data?.updatedBy?.id,
      ...data,
      blocks,
    } as Post<T>;

    return post;
  };
}
