import { PostType } from 'pn-backend';
import React, { FC } from 'react';

import { Grid } from '~atoms';
import { DEFAULT_GRID_PROPS } from '~constants';
import { usePost } from '~hooks';
import { Meta } from '~meta';
import { TestProvider } from '~providers';
import { PostProps } from '~types';
import { getPostSummary, isTestBlock, isTestQuestionBlock } from '~utils';

import { TestFlow } from './TestFlow';

export const Test: FC<PostProps<PostType.Test>> = ({
  post: {
    compiled: { blocks },
  },
}) => {
  const testBlock = blocks.find(isTestBlock);
  // Для обратной совместимости: раньше вопросы хранились в отдельных блоках
  const testQuestionBlocks = blocks.filter(isTestQuestionBlock);
  const totalQuestions = testBlock?.compiled.blocks.length ?? testQuestionBlocks.length;

  const { post } = usePost();
  const {
    modifiedAt,
    postAuthors,
    postId,
    postShareCover,
    postSubtitle,
    postTags,
    postTitle,
    publishedAt,
  } = getPostSummary(post);

  // Мета устанавливается в тестах напрямую, т.к. в постах используется в SeoTitleBlock. А вот у тестов его нет.

  return (
    <>
      <Meta
        isPublication
        id={postId}
        title={postTitle}
        tags={postTags}
        authors={postAuthors}
        description={postSubtitle}
        image={postShareCover}
        publishedAt={publishedAt}
        modifiedAt={modifiedAt}
      />

      <TestProvider values={{ totalQuestions }}>
        <Grid
          {...DEFAULT_GRID_PROPS}
          $maxWidth="750px"
          $padding="0 0 40px"
          $sm={{ $padding: '20px 0 60px' }}
        >
          <TestFlow blocks={blocks} />
        </Grid>
      </TestProvider>
    </>
  );
};
