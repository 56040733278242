import React, { FC, useEffect } from 'react';

import { useHeader, useLayout } from '~hooks';
import { HeaderType } from '~types';

import {
  HeaderBlackScience,
  HeaderInlineWithLogo,
  HeaderLongread,
  HeaderOnSport,
  HeaderWithBigLogo,
} from './HeaderTypes';

export const headerMap = {
  [HeaderType.HEADER_WITH_BIG_LOGO]: <HeaderWithBigLogo />,
  [HeaderType.HEADER_BLACK_SCIENCE]: <HeaderBlackScience />,
  [HeaderType.HEADER_ON_SPORT]: <HeaderOnSport />,
  [HeaderType.HEADER_INLINE_WITH_LOGO]: <HeaderInlineWithLogo />,
  [HeaderType.HEADER_LONGREAD]: <HeaderLongread />,
};

export const Header: FC = () => {
  const { isBlackScience, isSport, isTest } = useLayout();
  const headerType = useHeader();

  useEffect(() => {
    if (isBlackScience || isSport || isTest) document.body.style.backgroundColor = '#151515';
    if (!isBlackScience && !isSport && !isTest) document.body.style.backgroundColor = 'white';
  }, [isBlackScience, isSport, isTest]);

  return headerMap[headerType];
};
