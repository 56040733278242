import React, { FC } from 'react';

import { Grid } from '~atoms';
import { DEFAULT_GRID_PROPS } from '~constants';
import { useLayout } from '~hooks';
import { GridLayoutProps } from '~types';

import { Small } from '../../PostCards/Small';

export const ThreeSmall: FC<GridLayoutProps> = ({ posts = [] }) => {
  const { isMain } = useLayout();

  return (
    <Grid
      {...DEFAULT_GRID_PROPS}
      className={isMain ? 'three-small-cards' : undefined}
      $gridTemplateColumns="1fr 1fr 1fr"
      $columnGap="18px"
      $padding="0"
      $zIndex="9"
      $xl={{ $maxWidth: '100%', $padding: '0 15px' }}
      $sm={{ $gridTemplateColumns: '1fr', $rowGap: '30px' }}
    >
      {posts.slice(0, 3).map((post) => (
        <Small key={post.id} post={post} />
      ))}
    </Grid>
  );
};
