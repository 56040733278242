import { PostType } from 'pn-backend';
import React, { FC } from 'react';

import { Grid } from '~atoms';
import { customBlockMap } from '~blockMaps';
import { PostProps } from '~types';
import { renderPostBlock } from '~utils';

export const Custom: FC<PostProps<PostType.Custom>> = ({
  post: {
    compiled: { blocks },
  },
}) => <Grid>{blocks.map(renderPostBlock(customBlockMap))}</Grid>;
