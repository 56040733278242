import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { Chevron, Close } from '~assets';
import { Grid } from '~atoms';

export interface ModalProps {
  children: React.ReactNode;
  isNextAvailable: boolean;
  isPrevAvailable: boolean;
  isVisible: boolean;
  onClose: () => void;
  onNext: () => void;
  onPrev: () => void;
}

export const Modal: React.FC<ModalProps> = ({
  children,
  isNextAvailable,
  isPrevAvailable,
  isVisible,
  onClose,
  onNext,
  onPrev,
}) => {
  const [isBrowser, setIsBrowser] = useState(false);

  useEffect(() => {
    setIsBrowser(true);
  }, []);

  const handleCloseClick = (e: React.MouseEvent) => {
    e.preventDefault();
    onClose();
  };

  const handleNextClick = useCallback(
    (e: React.MouseEvent | React.KeyboardEvent) => {
      e.stopPropagation();
      e.preventDefault();
      onNext();
    },
    [onNext],
  );
  const handlePrevClick = useCallback(
    (e: React.MouseEvent | React.KeyboardEvent) => {
      e.stopPropagation();
      e.preventDefault();
      onPrev();
    },
    [onPrev],
  );

  const noClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleClick = useCallback(
    (event: KeyboardEvent) => {
      if (event.code === 'ArrowRight' && isNextAvailable) {
        handleNextClick(event as unknown as React.KeyboardEvent);
      }

      if (event.code === 'ArrowLeft' && isPrevAvailable) {
        handlePrevClick(event as unknown as React.KeyboardEvent);
      }
    },
    [handleNextClick, handlePrevClick, isNextAvailable, isPrevAvailable],
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window?.addEventListener('keyup', handleClick);
    }

    return () => {
      window?.removeEventListener('keyup', handleClick);
    };
  }, [handleClick, handleNextClick, handlePrevClick, isNextAvailable, isPrevAvailable]);

  if (isBrowser) {
    const modalRoot = document && document?.getElementById('modal-root');

    if (!modalRoot) return null;

    return ReactDOM.createPortal(
      isVisible ? (
        <>
          <Grid
            $zIndex="21000000000000"
            $position="fixed"
            $top="0"
            $left="0"
            $width="100%"
            $height="100%"
            $background="black"
            $opacity="0.45"
          />

          <Grid
            $zIndex="30000000000000"
            $display="flex"
            $width="100%"
            $height="100%"
            $justifyContent="center"
            $alignItems="center"
            $position="fixed"
            $top="0"
            $left="0"
            onClick={handleCloseClick}
          >
            <Grid
              $width="100px"
              $height="100px"
              $display="flex"
              $justifyContent="center"
              $alignItems="center"
              onClick={isPrevAvailable ? handlePrevClick : undefined}
            >
              <Grid
                $width="32px"
                $cursor="pointer"
                $transform="rotate(90deg)"
                $color="white"
                $zIndex="40"
                $opacity={isPrevAvailable ? '1' : '0'}
              >
                <Chevron />
              </Grid>
            </Grid>

            <Grid
              $maxWidth="1200px"
              $maxHeight="80vh"
              $alignItems="center"
              $width="100%"
              $height="100%"
              $position="relative"
              $lg={{ $maxWidth: 'unset', $width: '80%' }}
            >
              <Grid
                $zIndex="30000000000000"
                $maxWidth="1200px"
                $maxHeight="660px"
                $width="100%"
                $height="100%"
                $borderRadius="15px"
                $padding="15px"
                $position="relative"
                onClick={noClose}
                $lg={{ $maxWidth: 'unset' }}
              >
                <Grid
                  $position="absolute"
                  $top="-40px"
                  $right="-30px"
                  $color="white"
                  $zIndex="40000000000000"
                  $width="22px"
                  $height="22px"
                  onClick={handleCloseClick}
                  $cursor="pointer"
                >
                  <Close />
                </Grid>
                {children}
              </Grid>
            </Grid>

            <Grid
              $width="100px"
              $height="100px"
              $display="flex"
              $justifyContent="center"
              $alignItems="center"
              onClick={isNextAvailable ? handleNextClick : undefined}
            >
              <Grid
                $width="32px"
                $cursor="pointer"
                $transform="rotate(-90deg)"
                $color="white"
                $zIndex="40000000000000"
                $opacity={isNextAvailable ? '1' : '0'}
              >
                <Chevron />
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : null,
      modalRoot,
    );
  }
  return null;
};
