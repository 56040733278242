import React, { FC, useContext } from 'react';

import { Grid } from '~atoms';
import { testsBlockMap } from '~blockMaps';
import { TestContext } from '~providers';
import { TestProps } from '~types';
import { isTestBlock, isTestQuestionBlock, renderPostBlock } from '~utils';

export const Questions: FC<TestProps> = ({ blocks }) => {
  const { state } = useContext(TestContext);
  const { currentQuestion } = state;
  const testBlock = blocks.find(isTestBlock);
  // Для обратной совместимости: раньше вопросы хранились в отдельных блоках
  const testQuestionBlocks = blocks.filter(isTestQuestionBlock);
  const questionBlocks = testBlock?.compiled.blocks ?? testQuestionBlocks;
  return (
    <Grid $marginTop="40px" $lg={{ $margin: '0' }}>
      {questionBlocks
        // Рендерим только текущий вопрос
        .filter((_, index) => index === currentQuestion)
        .map(renderPostBlock(testsBlockMap))}
    </Grid>
  );
};
