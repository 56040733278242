import { Block, BlockChildMap, BlockType, blockTypeChildMap } from '../types';
import { createEmptyBlocks } from './createEmptyBlocks';

export function createEmptyDefaultChildBlocks<T extends BlockType>(
  type: T,
): Block<BlockChildMap<T>>[] {
  const [defaultFixedBlockTypes, defaultBlockTypes] = blockTypeChildMap[type];

  const blocks = createEmptyBlocks<BlockChildMap<T>>(
    defaultFixedBlockTypes,
    defaultBlockTypes,
    [],
  );

  return blocks;
}
