import Link from 'next/link';
import { useRouter } from 'next/router';
import { PostType } from 'pn-backend';
import { not } from 'ramda';
import React, { FC, useEffect, useState } from 'react';

import { Burger, Close, Logo, SearchIcon } from '~assets';
import { Grid, Header, Text } from '~atoms';
import { MOBILE_HEADER_HEIGHT, SectionAlias } from '~constants';
import { useLayout, useScrollPosition, useSection } from '~hooks';
import { getPageTitle, getSection, getSectionLink } from '~utils';

import { BurgerMenu } from '../../../BurgerMenu';
import { SearchLine } from '../../SearchLine';

export const HeaderMobile: FC = () => {
  const { isCard, isMain, isPost, layout } = useLayout();
  const scroll = useScrollPosition();

  let pageTitle = getPageTitle(layout as SectionAlias | PostType);
  const { currentSection } = useSection();
  if (!pageTitle) pageTitle = currentSection?.title;
  const section = getSection(layout as PostType);
  const pageLink = section && getSectionLink(section);

  const [isOpenBurger, setIsOpenBurger] = useState(false);
  const [isOpenSearch, setIsOpenSearch] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleBurger = () => setIsOpenBurger(!isOpenBurger);

  const { pathname } = useRouter();
  const isSearchPage = pathname.search('search') >= 1;

  const toggleSearch = () => {
    if (!isSearchPage) setIsOpenSearch(not);
  };
  const closeSearch = () => {
    if (!isSearchPage) setIsOpenSearch(false);
  };

  const scrollToTop = () => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
  };

  let borderBottom = '3px solid transparent';
  if (isScrolled) borderBottom = '3px solid var(--color-black)';
  if (isCard) borderBottom = '1px solid var(--color-black)';

  useEffect(() => {
    if (scroll > window.screen.height / 4) {
      setIsScrolled(true);
    } else if (scroll < window.screen.height / 4) {
      setIsScrolled(false);
    }
  }, [scroll]);

  return (
    <>
      {!isMain && !isPost && (
        <Grid
          $display="none"
          $padding="10px 15px"
          $height="auto"
          $margin="0"
          $xl={{ $display: 'grid', $padding: '20px 15px 10px' }}
          $sm={{ $padding: '10px 15px' }}
        >
          <Link href="/" passHref>
            <Logo />
          </Link>
        </Grid>
      )}

      {isMain && (
        <Grid
          $display="none"
          $padding="10px 15px"
          $height="auto"
          $margin="0"
          $xl={{ $display: 'grid', $padding: '20px 15px 10px' }}
          $sm={{ $display: 'none' }}
        >
          <Link href="/" passHref>
            <Logo />
          </Link>
        </Grid>
      )}
      <Header
        $display="none"
        $xl={{ $display: 'grid' }}
        $sm={{ $height: MOBILE_HEADER_HEIGHT, $transform: 'translateZ(1px)' }}
      >
        <Grid
          $width="100%"
          $display="flex"
          $alignItems="center"
          $justifyContent="space-between"
          $padding="0 15px"
          $transition="border-bottom 0.2s ease-in-out"
          $borderBottom={borderBottom}
        >
          <Grid
            onClick={toggleBurger}
            $gridArea="burger"
            $display="none"
            $xl={{ $display: 'grid' }}
          >
            <Burger />
          </Grid>

          {!pageTitle && !isMain && (
            <Grid $width="150px" $height="20px">
              <Link href="/" passHref>
                <Logo />
              </Link>
            </Grid>
          )}

          {isMain && (
            <Grid $display="none" $width="150px" $height="20px" $sm={{ $display: 'grid' }}>
              <Link href="/" passHref>
                <Logo />
              </Link>
            </Grid>
          )}

          {pageTitle && !pageLink && (
            <Grid onClick={scrollToTop}>
              <Text
                $weight={600}
                $color="black"
                $display="flex"
                $alignItems="center"
                $justifyContent="center"
                $textTransform="lowercase"
                $xl={{ $lineHeight: 44, $size: 40 }}
                $sm={{ $lineHeight: 33, $size: 30 }}
              >
                {pageTitle}
              </Text>
            </Grid>
          )}

          {pageTitle && pageLink && (
            <Link href={pageLink} passHref>
              <Grid>
                <Text
                  $weight={600}
                  $color="black"
                  $display="flex"
                  $alignItems="center"
                  $justifyContent="center"
                  $textTransform="lowercase"
                  $xl={{ $lineHeight: 44, $size: 40 }}
                  $sm={{ $lineHeight: 33, $size: 30 }}
                >
                  {pageTitle}
                </Text>
              </Grid>
            </Link>
          )}

          <Grid
            $justifyContent="flex-end"
            $position="relative"
            $xl={{ $display: 'grid' }}
            $userSelect="none"
          >
            <Grid
              $transform={isOpenSearch ? 'scale(0)' : 'scale(1)'}
              $opacity={isOpenSearch ? 0 : 1}
              $position={isOpenSearch ? 'absolute' : 'relative'}
              $transition="0.2s ease-in-out"
            >
              <SearchIcon id="searchIcon" onClick={toggleSearch} />
            </Grid>
            <Grid
              $width="32px"
              $transform={isOpenSearch ? 'scale(1)' : 'scale(0)'}
              $opacity={isOpenSearch ? 1 : 0}
              $position={isOpenSearch ? 'relative' : 'absolute'}
              $zIndex={isOpenSearch ? '-10' : '10'}
              $transition="0.2s ease-in-out"
            >
              <Close onClick={toggleSearch} />
            </Grid>
          </Grid>
          <SearchLine isOpen={isOpenSearch} closeSearch={closeSearch} />
        </Grid>
      </Header>
      <BurgerMenu isOpenBurger={isOpenBurger} onClose={toggleBurger} />
    </>
  );
};
