import { ImageProxyOptions } from '../types/common/ImageProxyOptions';
import { VideoOptions } from '../types/common/VideoOptions';
import { File } from '../types/db/file/File';
import { StorageProviderType } from '../types/db/file/StorageProviderType';
import { getImageProxyURL } from './getImageProxyURL';
import { isFileSupportsImageProxy } from './isFileSupportsImageProxy';
import { isFileVkVideo } from './isFileVkVideo';
import { toBase64 } from './toBase64';

export function getFileURL<D = Date>(
  file: File<D>,
  options: ImageProxyOptions & VideoOptions,
): string {
  if (isFileSupportsImageProxy(file)) {
    const url = new URL(file.url);

    const imageProxyUrl = getImageProxyURL(options);

    return imageProxyUrl.concat(
      '/',
      toBase64(
        [file.provider === StorageProviderType.S3 ? 's3:' : '', url.pathname]
          .filter(Boolean)
          .join('/'),
      ),
    );
  }

  if (isFileVkVideo(file)) {
    const url = new URL(file.url);

    url.searchParams.set('autoplay', options.autoplay ? '1' : '0');
    url.searchParams.set('loop', options.loop ? '1' : '0');

    return url.toString();
  }

  return file.url;
}
