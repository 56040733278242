import Link from 'next/link';
import React, { useContext } from 'react';

import { Eighteen, Logo } from '~assets';
import { Grid, Text } from '~atoms';
import { SectionAlias } from '~constants';
import { useSection } from '~hooks';
import { StateContext } from '~providers';
import { getSectionLink } from '~utils';

import { Socials } from '../Socials';
import styles from './styles.module.scss';

export const Footer = () => {
  const { aboutSection, advertisersSection } = useSection();

  const {
    state: { settings },
  } = useContext(StateContext);

  const {
    additional,
    address,
    certificate,
    certificateTitle,
    chiefEditor,
    copyright,
    email,
    founder,
  } = settings;

  return (
    <footer className={styles.footer}>
      <Grid
        $gridAutoFlow="column"
        $margin="auto"
        $maxWidth="1200px"
        $justifyContent="space-between"
        $gridTemplateAreas={'"logo links socials" "copyright links socials" "info info info"'}
        $gridAutoColumns="min-content"
        $gap="40px"
        $lg={{
          $gridTemplateAreas: '"logo links socials" "copyright links socials" "info info info"',
          $maxWidth: '768px',
        }}
        $sm={{
          $gap: '0',
          $gridTemplateAreas: '"links socials" "links logo" "copyright copyright" "info info"',
          $maxWidth: '375px',
        }}
      >
        <Grid $gridArea="copyright" $width="280px" $lg={{ $width: 'unset' }}>
          <Text
            $maxWidth="280px"
            $sm={{
              $lineHeight: 13,
              $marginTop: '20px',
              $maxWidth: 'unset',
              $size: 12,
            }}
          >
            {`© ${copyright} | `}
            {certificate?.url && (
              <a target="_blank" href={certificate.url} rel="noreferrer">{`${certificateTitle}`}</a>
            )}
            {!certificate?.url && `${certificateTitle}`}
          </Text>
        </Grid>
        <Grid
          $gridArea="logo"
          $sm={{
            $alignItems: 'flex-end',
          }}
        >
          <Link href="/" passHref>
            <Logo className={styles.logo} />
          </Link>
        </Grid>
        <Grid
          $gridArea="links"
          $justifyContent="center"
          $height="100px"
          $sm={{
            $height: '70px',
          }}
        >
          {aboutSection?.isShownInFooter && (
            <Link href={getSectionLink(SectionAlias.About)} passHref>
              <Text
                $hoverColor="gray"
                $size={20}
                $lineHeight={24}
                $sm={{
                  $lineHeight: 17,
                  $size: 14,
                  $weight: 500,
                }}
              >
                {aboutSection?.title || 'о нас'}
              </Text>
            </Link>
          )}
          <Link href={getSectionLink(SectionAlias.Specs)} passHref>
            <Text
              $hoverColor="gray"
              $size={20}
              $lineHeight={24}
              $sm={{
                $lineHeight: 17,
                $size: 14,
                $weight: 500,
              }}
            >
              спецпроекты
            </Text>
          </Link>
          {advertisersSection?.isShownInFooter && (
            <Link href={getSectionLink(SectionAlias.Advertisers)} passHref>
              <Text
                $hoverColor="gray"
                $size={20}
                $lineHeight={24}
                $sm={{
                  $lineHeight: 17,
                  $size: 14,
                  $weight: 500,
                }}
              >
                {advertisersSection?.title || 'Рекламодателям'}
              </Text>
            </Link>
          )}
        </Grid>
        <Grid $gridArea="socials" $display="flex" $alignItems="flex-start">
          <Socials small inFooter />
          <Grid $marginLeft="10px">
            <Eighteen />
          </Grid>
        </Grid>
        <Grid $gridArea="info">
          <Text
            $opacity={0.5}
            $size={14}
            $lineHeight={15.26}
            $weight={400}
            $sm={{
              $lineHeight: 13,
              $marginTop: '30px',
              $size: 12,
              $weight: 400,
            }}
          >
            <span>{`Адрес редакции: ${address}`} </span>
            <span>{`Учредитель: ${founder}`} </span>
            <span>{`Главный редактор: ${chiefEditor}`} </span>
            <span>
              Электронная почта редакции: <a href={`mailto:${email}`}>{email}</a>{' '}
            </span>
            <span>{`${additional}`} </span>
          </Text>
        </Grid>
      </Grid>
    </footer>
  );
};
