import React, { FC } from 'react';

import { Grid } from '~atoms';
import { Full } from '~postCards';
import { GridLayoutProps } from '~types';

export const FullPost: FC<GridLayoutProps> = ({ posts }) => (
  <Grid
    $margin="auto"
    $maxWidth="1200px"
    $width="100%"
    $display="flex"
    $flexFlow="column"
    $gap="30px"
    $zIndex="10"
    $padding="0 0 30px 0"
    $xl={{ $maxWidth: '768px', $padding: '0 15px 20px 15px' }}
    $sm={{ $maxWidth: '768px', $padding: '0 0 20px' }}
  >
    {posts
      .slice(0, 1)
      .map((post) => ('compiled' in post ? <Full key={post.id} post={post} /> : null))}
  </Grid>
);
