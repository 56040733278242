import { pathOr, propOr } from 'ramda';
import { CSSProperties } from 'react';
import styled, { css } from 'styled-components';

import { devices, Screen } from '~constants';
import { ButtonType } from '~types';

interface LinkButtonProps {
  $chevron?: boolean;
  $fontSize?: CSSProperties['fontSize'];
  $padding?: CSSProperties['padding'];
  $type?: ButtonType;
}

type Props = LinkButtonProps & { [key in Screen]?: LinkButtonProps };

export const LinkButton = styled.a<Props>`
  border: 2px solid var(--color-black);
  font-size: ${propOr('20px', '$padding')};
  font-weight: 500;
  line-height: 22px;
  border-radius: 20px;
  transition: all 0.2s ease-in-out;
  padding: ${propOr('6px 14px', '$padding')};
  cursor: pointer;
  color: var(--color-black);
  background: transparent;
  display: flex;
  align-items: center;

  ${(props) => {
    switch (props.$type) {
      case ButtonType.BLACK_SCIENCE:
        return css`
          border-color: var(--color-white);
          color: var(--color-white);

          &:hover {
            background: var(--color-black-science);
            border-color: var(--color-black-science);
          }
        `;

      case ButtonType.SPORT:
        return css`
          border-color: var(--color-black);

          &:hover {
            background: var(--color-na-sporte);
            border-color: var(--color-na-sporte);
            color: var(--color-white);
          }
        `;

      case ButtonType.BLACK:
        return css`
          border-color: var(--color-white);
          color: var(--color-white);

          &:hover {
            background: var(--color-white);
            color: var(--color-black);
          }
        `;

      default:
        return css`
          &:hover {
            background: var(--color-white);
          }
        `;
    }
  }}

  ${(props) =>
    Object.entries(devices).map(
      ([field, width]) =>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        props[field] &&
        css`
          @media only screen and (max-width: ${width}) {
            padding: ${pathOr(null, [field, '$padding'], props)};
            font-size: ${pathOr(null, [field, '$fontSize'], props)};
          }
        `,
    )}
`;
