export enum PostType {
  Article = 'article',
  Card = 'card',
  Custom = 'custom',
  Longread = 'longread',
  News = 'news',
  NewsFull = 'news_full',
  Spec = 'spec',
  Test = 'test',
}
