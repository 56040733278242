import { BlockType, ListType } from 'pn-backend';
import React, { FC } from 'react';

import { Grid, LI, OL, Text, UL } from '~atoms';
import { Tooltips } from '~components';
import { useLayout } from '~hooks';
import { BlockProps } from '~types';

const defaultProps = {
  $margin: 'auto',
  $marginBottom: '-10px',
  $marginTop: '30px',
  $maxWidth: '750px',
  $width: '100%',
};

const cardProps = {
  $margin: 'auto',
  $marginBottom: '-10px',
  $marginTop: '0px',
  $maxWidth: '750px',
  $width: '100%',
};

export const ListBlock: FC<BlockProps<BlockType.List>> = ({ compiled: { options, type } }) => {
  const { isCard } = useLayout();

  if (!options || options.length === 0) return null;
  let gridProps = defaultProps;
  if (isCard) {
    gridProps = cardProps;
  }

  // ToDo Отрефакторить: UL / OL обернуть в вычисляемый компонент
  return (
    <Grid
      {...gridProps}
      $md={{ $maxWidth: 'unset', $padding: '0 15px' }}
      $sm={{ $marginTop: isCard ? '0' : '30px', $padding: isCard ? '0' : '0 15px' }}
    >
      <Grid>
        {type === ListType.Dotted && (
          <UL>
            {options.map(({ html }, i) => (
              <LI key={i}>
                <Tooltips html={html} />
                <Text
                  $weight={400}
                  $size={20}
                  $display="inline-flex"
                  $lineHeight={30}
                  $color="black"
                  $textWithLink
                  $sm={{ $lineHeight: 27, $size: 18 }}
                  dangerouslySetInnerHTML={{ __html: html }}
                />
              </LI>
            ))}
          </UL>
        )}

        {type === ListType.Numbered && (
          <OL>
            {options.map(({ html }, i) => (
              <LI key={i}>
                <Tooltips html={html} />
                <Text
                  $weight={400}
                  $size={20}
                  $display="inline-flex"
                  $lineHeight={30}
                  $color="black"
                  $textWithLink
                  $sm={{ $lineHeight: 27, $size: 18 }}
                  dangerouslySetInnerHTML={{ __html: html }}
                />
              </LI>
            ))}
          </OL>
        )}
      </Grid>
    </Grid>
  );
};
