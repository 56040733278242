export enum WidgetType {
  VK = 'VK',
  VK_PLAYLIST = 'VK_PLAYLIST',
  VK_POST = 'VK_POST',
  appleMusic = 'appleMusic',
  facebook = 'facebook',
  googleMaps = 'googleMaps',
  infogram = 'infogram',
  inst = 'inst',
  rutube = 'rutube',
  sporcle = 'sporcle',
  telegram = 'telegram',
  twitter = 'twitter',
  yandexMusic = 'yandexMusic',
  youtube = 'youtube',
}
