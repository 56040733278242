export enum SectionAlias {
  About = 'about',
  Advertisers = 'advertisers',
  Articles = 'articles',
  BlackScience = 'blackscience',
  Cards = 'cards',
  Corona = 'corona',
  Main = 'main',
  News = 'news',
  OnSport = 'onsport',
  SpecOperaciya = 'specoperaciya',
  Specs = 'specs',
  Tests = 'tests',
}
