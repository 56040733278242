import { BlockType } from 'pn-backend';
import React, { FC } from 'react';

import { Grid, Text } from '~atoms';
import { Tooltips } from '~components';
import { useLayout } from '~hooks';
import { BlockProps } from '~types';

const defaultProps = {
  $margin: 'auto',
  $maxWidth: '750px',
  $width: '100%',
};

export const IntroBlock: FC<BlockProps<BlockType.Intro>> = ({ compiled: { html } }) => {
  const { isAbout, isAdvertisers, isLong, layout } = useLayout();

  if (!html) return null;

  let marginTop = '30px';
  if (isLong) marginTop = '40px';
  if (isAbout || isAdvertisers) marginTop = '20px';

  switch (layout) {
    case 'card':
      return (
        <Grid $marginTop="30px" $sm={{ $marginTop: '20px' }}>
          <Tooltips html={html} />
          <Text
            $weight={600}
            $size={20}
            $lineHeight={30}
            $color="black"
            $textWithLink
            $spaceBetweenParagraph="30px 0 0"
            $sm={{ $lineHeight: 27, $size: 18 }}
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </Grid>
      );
    default:
      return (
        <Grid
          {...defaultProps}
          $marginTop={marginTop}
          $md={{ $marginTop: '30px', $maxWidth: 'unset', $padding: '0 15px' }}
        >
          <Tooltips html={html} />
          <Text
            $weight={600}
            $size={20}
            $lineHeight={30}
            $color="black"
            $textWithLink
            $spaceBetweenParagraph="30px 0 0"
            $sm={{ $lineHeight: 27, $size: 18 }}
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </Grid>
      );
  }
};
