import { always } from 'ramda';

/**
 * Generic type guard function for values runtime-checked as defined.
 *
 * @param argument - The argument to check.
 *
 * @returns The boolean describing the assertion.
 * @remarks Uses the {@link Defined} type as returned value.
 */
export function isDefined<T>(argument: T): argument is Defined<T> & NotNull<T> {
  return argument !== undefined && argument !== null;
}

export const noop = always(undefined);

export const isEmptyString = (argument: string) => argument === '';

export const isString = (argument: unknown): argument is string => typeof argument === 'string';

export const getElemPageY = (elem: HTMLElement | null) => {
  if (elem) {
    const box = elem?.getBoundingClientRect();
    return box.top + window.pageYOffset;
  }
  return 0;
};
