import Link from 'next/link';
import React, { FC, useEffect, useState } from 'react';

import { Logo } from '~assets';
import { Grid } from '~atoms';
import { HEADER_HEIGHT } from '~constants';
import { usePost, useScrollPosition } from '~hooks';
import { getPostSummary } from '~utils';

import { HeaderInlineWithLogo } from '../HeaderInlineWithLogo';

const defaultHeaderStyles = {
  $borderBottom: 'none',
  $height: 0,
  $opacity: 0,
  $padding: 0,
  $position: 'sticky',
  $top: 0,
  $transition: 'opacity 0.1s ease-in-out, background-color 0.2s ease-in-out',
};

const scrolledHeaderStyles = {
  $borderBottom: '1px solid var(--color-black)',
  $height: '71px',
  $opacity: 1,
  $position: 'sticky',
  $top: 0,
  $transition: 'opacity 0.1s ease-in-out, background-color 0.2s ease-in-out',
  $zIndex: '10',
};

export const HeaderLongread: FC = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  const { post } = usePost();
  const { isLongHeaderRight } = getPostSummary(post);

  const scroll = useScrollPosition();

  useEffect(() => {
    if (scroll > window.screen.height) {
      setIsScrolled(true);
    } else if (scroll < window.screen.height) {
      setIsScrolled(false);
    }
  }, [scroll]);

  return (
    <>
      <Grid
        $position="absolute"
        $zIndex="20"
        $width={isLongHeaderRight ? 'auto' : '100%'}
        $left={isLongHeaderRight ? 'calc(50vw + 60px)' : '0'}
        $height={HEADER_HEIGHT}
        $display="flex"
        $justifyContent="center"
        $xl={{ $left: 0, $right: 0 }}
        $sm={{ $height: '35px', $left: 0, $width: '100%' }}
      >
        <Grid
          $width="150px"
          $height="20px"
          $marginTop="30px"
          $color="white"
          $sm={{ $height: '15px', $marginTop: '10px', $width: '117px' }}
        >
          <Link href="/" passHref>
            <Logo />
          </Link>
        </Grid>
      </Grid>
      <Grid
        {...(isScrolled ? scrolledHeaderStyles : defaultHeaderStyles)}
        $sm={{ $height: isScrolled ? '50px' : '0' }}
      >
        <HeaderInlineWithLogo />
      </Grid>
    </>
  );
};
