import axios, { AxiosRequestConfig } from 'axios';
import { NextApiRequest } from 'next';
import {
  BlockType,
  GetManySectionsResponsePublic,
  GetNextPostsQueryPublic,
  GetPostByIdQueryPublic,
  GetPostByIdResponsePublic,
  GetPostsByTagQueryPublic,
  GetPostsByTagResponsePublic,
  GetSectionByIdResponsePublic,
  GetSectionGridQueryPublic,
  GetSectionGridResponsePublic,
  PostType,
  SearchPostsQueryPublic,
  SearchPostsResponsePublic,
  SettingPublic,
} from 'pn-backend';
import queryString, { StringifyOptions } from 'query-string';

import {
  ApiResult,
  BlockProps,
  HttpStatusCode,
  IdOrAliasRequestParameters,
  PostRequestParameters,
  QuizOptionRequestParameters,
  QuizRequestParameters,
} from '~types';
import { log, serializeCookies } from '~utils';

import { config } from './config';

const API_ROOT = config.API_URL || '/api';
const QUERY_PARAM_OPTIONS: StringifyOptions = {
  skipEmptyString: true,
  skipNull: true,
  strict: true,
};

const fetch = async <T = unknown>(
  path: string,
  init?: AxiosRequestConfig,
): Promise<ApiResult<T>> => {
  const headers = {
    'Content-Type': 'application/json',
    ...(init?.headers || {}),
  };

  log('info', 'fetchRequest')({ headers, path });

  const response = await axios
    .request<T>({
      url: `${API_ROOT}${path}`,
      ...init,
      headers,
    })
    .catch((e) => {
      log('error', 'fetchRequest')({ error: e, path });
    });

  if (
    response &&
    [HttpStatusCode.Ok, HttpStatusCode.Created, HttpStatusCode.Accepted].includes(response.status)
  ) {
    return {
      data: response.data,
      headers: response.headers,
      status: response.status,
    };
  }

  return {
    status: (response && response.status) || HttpStatusCode.NotFound,
  };
};

export const api = {
  getNextPosts: async ({ postId, ...params }: PostRequestParameters & GetNextPostsQueryPublic) =>
    fetch<SearchPostsResponsePublic>(
      `/posts/${postId}/next?${queryString.stringify(params, QUERY_PARAM_OPTIONS)}`,
    ),

  getPost: async ({ postId, ...params }: PostRequestParameters & GetPostByIdQueryPublic) =>
    fetch<GetPostByIdResponsePublic<PostType>>(
      `/posts/${postId}?${queryString.stringify(params, QUERY_PARAM_OPTIONS)}`,
    ),

  getQuiz: async ({ blockId, postId }: QuizRequestParameters, req: NextApiRequest) =>
    fetch<BlockProps<BlockType.Quiz>>(`/posts/${postId}/quizes/${blockId}`, {
      headers: {
        Cookie: serializeCookies(req.cookies),
      },
    }),

  getSection: async ({ idOrAlias }: IdOrAliasRequestParameters) =>
    fetch<GetSectionByIdResponsePublic>(`/sections/${idOrAlias}`),

  getSectionGrid: async ({
    idOrAlias,
    ...params
  }: IdOrAliasRequestParameters & Partial<GetSectionGridQueryPublic>) =>
    fetch<GetSectionGridResponsePublic>(
      `/sections/${idOrAlias}/grid?${queryString.stringify(params, QUERY_PARAM_OPTIONS)}`,
    ),

  getSections: async () => fetch<GetManySectionsResponsePublic>('/sections'),

  getSettings: async () => fetch<SettingPublic>(`/settings`),

  getTagPosts: async ({
    idOrAlias,
    ...params
  }: IdOrAliasRequestParameters & Partial<GetPostsByTagQueryPublic>) =>
    fetch<GetPostsByTagResponsePublic>(
      `/tags/${idOrAlias}/posts?${queryString.stringify(params, QUERY_PARAM_OPTIONS)}`,
    ),

  search: async (params: SearchPostsQueryPublic) =>
    fetch<SearchPostsResponsePublic>(
      `/posts?${queryString.stringify(params, QUERY_PARAM_OPTIONS)}`,
    ),

  voteQuiz: async (
    { blockId, optionId, postId }: QuizOptionRequestParameters,
    req: NextApiRequest,
  ) =>
    fetch<HttpStatusCode.Ok>(`/posts/${postId}/quizes/${blockId}/options/${optionId}`, {
      headers: {
        Cookie: serializeCookies(req.cookies),
      },
      method: 'POST',
    }),
};

// clientApi - все вызовы проксируются через api routes на api
export const clientApi = {
  getQuiz: async ({ blockId, postId }: QuizRequestParameters) =>
    fetch<BlockProps<BlockType.Quiz>>(`/posts/${postId}/quizes/${blockId}`),
  voteQuiz: async ({ blockId, optionId, postId }: QuizOptionRequestParameters) =>
    fetch<void>(`/posts/${postId}/quizes/${blockId}/options/${optionId}`, {
      method: 'POST',
    }),
};
