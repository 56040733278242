import React, { FC, useEffect, useRef, useState } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { useOnClickOutside, useOnScreen } from '~hooks';

import styles from './styles.module.scss';

type Props = {
  tooltipId: string;
};

export const Tooltip: FC<Props> = ({ tooltipId }) => {
  const tooltipInstanceId = `${tooltipId}-instance`;

  const [isOpen, setIsOpen] = useState(false);
  const [tooltipInstance, setTooltipInstance] = useState(
    document.getElementById(tooltipInstanceId),
  );
  const [isTooltipOnScreen, setTooltipOnScreen] = useState(false);

  const searchRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(searchRef, (event: React.MouseEvent) => {
    if (event.target instanceof Element) {
      const tooltipButtonId = event.target.id;
      const isTooltip = tooltipButtonId.includes('tooltip');
      const isCurrentTooltip = tooltipButtonId === tooltipId;

      if (isTooltip && isCurrentTooltip) {
        setIsOpen(!isOpen);
      } else {
        setIsOpen(false);
      }
    }
  });

  useEffect(() => {
    setTimeout(() => {
      const newTooltipInstance = document.getElementById(tooltipInstanceId);

      setTooltipInstance(newTooltipInstance);
    }, 0);
  }, [tooltipInstanceId, isOpen]);

  const isOnScreen = useOnScreen(tooltipInstance);

  useEffect(() => {
    if (tooltipInstance) {
      setTooltipOnScreen(isOnScreen);
    }
  }, [isOnScreen, tooltipInstance]);

  useEffect(() => {
    if (!isTooltipOnScreen) {
      setIsOpen(false);
    }
  }, [isTooltipOnScreen]);

  return (
    <span ref={searchRef} key={tooltipId}>
      <ReactTooltip
        id={tooltipInstanceId}
        anchorId={tooltipId}
        key={tooltipId}
        isOpen={isOpen}
        events={[]}
        variant="light"
        noArrow
        className={styles.customTooltipStyles}
      />
    </span>
  );
};
