import { BlockContentCoverCompiled, BlockType } from 'pn-backend';
import { anyPass, propEq } from 'ramda';
import React, { FC, useContext } from 'react';

import { Grid } from '~atoms';
import { testsBlockMap } from '~blockMaps';
import { NextButton } from '~components';
import { TestContext } from '~providers';
import { TestProps } from '~types';
import { blockTypeEquals, renderPostBlock } from '~utils';

const isCoverBlock = propEq('type', BlockType.Cover);
const isStartBlock = anyPass([
  blockTypeEquals(BlockType.H1),
  blockTypeEquals(BlockType.Intro),
  blockTypeEquals(BlockType.Meta),
  blockTypeEquals(BlockType.SeoTitle),
  blockTypeEquals(BlockType.Subtitle),
]);

export const Start: FC<TestProps> = ({ blocks }) => {
  const { moveNextStep } = useContext(TestContext);

  const coverBlocks = blocks.filter(isCoverBlock);
  const startBlocks = blocks.filter(isStartBlock);

  const cover = coverBlocks[0]?.compiled as BlockContentCoverCompiled;
  const isCoverVisible = !cover?.hideInPost;

  return (
    <Grid $gap="30px" $maxWidth="750px" $marginTop="40px" $sm={{ $gap: '20px', $margin: '0 15px' }}>
      {isCoverVisible && <Grid>{coverBlocks.map(renderPostBlock(testsBlockMap))}</Grid>}
      <Grid $background="white">
        <Grid $padding="0 30px 60px" $sm={{ $padding: '0 0 40px' }}>
          {startBlocks.map(renderPostBlock(testsBlockMap))}
        </Grid>
        <NextButton onClick={moveNextStep}>Пройти тест</NextButton>
      </Grid>
    </Grid>
  );
};
