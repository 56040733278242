import { isThisYear, isToday, isYesterday } from 'date-fns';
import { formatWithOptions, parse as formatParse, parseISO } from 'date-fns/fp';
import { ru } from 'date-fns/locale';
import parse from 'html-react-parser';
import { BlockCompiled, BlockType, CoverFormat, PostType } from 'pn-backend';
import {
  always,
  cond,
  CondPair,
  descend,
  equals,
  groupBy,
  lensIndex,
  map,
  over,
  pickAll,
  pipe,
  prop,
  propEq,
  sort,
  sortWith,
  T,
  tap,
  toPairs,
} from 'ramda';
import { ComponentType, ReactElement } from 'react';

import { GridPostProps, PostLayoutComponentMap, PostProps } from '~types';

import { isCoverBlock, isH1Block, isIntroBlock, isSubtitleBlock, isTextBlock } from './block';
import { isDefined, isString } from './common';
import { getPostLink, getTagLink } from './links';
import { log } from './log';
import { renderPostLayoutByComponent } from './render';

interface ChildrenProps {
  props?: {
    children: {
      props: {
        children: string;
      };
    };
  };
}

export const formatGroupDate = pipe(
  formatParse(new Date(), 'yyyy-MM-dd'),
  cond([
    [(date) => isToday(date), always('Сегодня')],
    [
      (date) => isYesterday(date),
      (value) => `Вчера, ${formatWithOptions({ locale: ru }, 'dd MMMM', value)}`,
    ],
    [(date) => isThisYear(date), formatWithOptions({ locale: ru }, 'dd MMMM')],
    [T, formatWithOptions({ locale: ru }, 'dd MMMM yyyy')],
  ]),
);

export const groupPublicationsByDay = pipe(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  groupBy(pipe(prop('publishedAt'), parseISO, formatWithOptions({ locale: ru }, 'yyyy-MM-dd'))),
  toPairs,
  sort(([a], [b]) => b.localeCompare(a)),
  map(
    pipe(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      over(lensIndex(0), formatGroupDate),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      over(lensIndex(1), sortWith([descend(prop('publishedAt'))])),
    ),
  ),
);

export const getPostPreviewSummary = (post: GridPostProps) => {
  const { id: postId, publishedAt } = post;
  const title = 'title' in post && post?.title?.text;
  const subtitle = 'subtitle' in post && post.subtitle.text;
  const newsText = 'text' in post && post.text.html;
  const coverUrl = 'preview' in post && post.preview.imageURLs;
  const sourceUrl = 'newsSource' in post && post.newsSource.url;
  const source = 'newsSource' in post && post.newsSource.name;
  const specLink = 'specLink' in post && post.specLink.url;
  const fullPost = 'compiled' in post && post;

  const [tag] = post.tags;
  const tagTitle = isDefined(tag) && tag.name;
  const tagLink = isDefined(tag) && getTagLink(tag.alias);
  const postLink = getPostLink(postId, post.type);

  return {
    coverUrl,
    fullPost,
    newsText,
    postId,
    postLink,
    publishedAt,
    source,
    sourceUrl,
    specLink,
    subtitle,
    tagLink,
    tagTitle,
    title,
  };
};

const getPostText = (block: BlockCompiled<BlockType.H1 | BlockType.Subtitle>) => {
  if (isH1Block(block) || isSubtitleBlock(block)) {
    return block.compiled.text;
  }
  return '';
};

const getPostHtml = (block?: BlockCompiled<BlockType.Intro | BlockType.Text>) => {
  if (!block || !block.compiled) return '';

  if ('html' in block.compiled) {
    const parsedHtml = parse(block?.compiled?.html) as {
      props: { children: string | (string | ChildrenProps)[] | ChildrenProps };
    };

    if (
      Array.isArray(parsedHtml) &&
      parsedHtml.length > 0 &&
      isString(parsedHtml[0].props?.children)
    ) {
      return parsedHtml[0].props?.children;
    }

    if (typeof parsedHtml?.props?.children === 'string') {
      return parsedHtml?.props?.children;
    }

    const children = parsedHtml?.props?.children;

    if (!Array.isArray(children)) {
      if (children?.props?.children && typeof children.props.children === 'string') {
        return children.props.children;
      }
      return '';
    }

    if (typeof children === 'object' && children !== null) {
      return children.reduce((accumulator: string, currentValue: string | ChildrenProps) => {
        if (isString(currentValue)) {
          return accumulator + currentValue;
        }
        if (currentValue?.props?.children?.props?.children) {
          return accumulator + currentValue.props.children.props.children;
        }
        return accumulator;
      }, '');
    }
  }
  return '';
};

const getPostCover = (block?: BlockCompiled<BlockType.Cover>) => {
  if (!block || !block.compiled) return '';

  if ('imageURL' in block.compiled) {
    return block.compiled.imageURL || '';
  }
  return '';
};

const getLongHeader = (block?: BlockCompiled<BlockType.Cover>) => {
  if (!block || !block.compiled) return '';

  if ('format' in block.compiled) {
    return block.compiled.format === CoverFormat.FullscreenCaptionRight;
  }
  return '';
};

export const getPostSummary = (post: PostProps['post']) => {
  const {
    authors,
    compiled,
    id: postId,
    isAffiliateArticle,
    isAuthorVisible,
    isModificationDateVisible,
    modifiedAt,
    publishedAt,
    shareCover,
    tags,
  } = post;

  const [tag] = tags;
  const tagTitle = isDefined(tag) && tag.name;
  const tagLink = isDefined(tag) && getTagLink(tag.alias);
  const postLink = getPostLink(postId);

  const h1Block = compiled.blocks.find(isH1Block);
  const coverBlock = compiled.blocks.find(isCoverBlock);
  const subtitleBlock = compiled.blocks.find(isSubtitleBlock);
  const introBlock = compiled.blocks.find(isIntroBlock);
  const textBlock = compiled.blocks.find(isTextBlock);

  const postTitle = h1Block ? getPostText(h1Block) : '';
  const postCover = getPostCover(coverBlock);
  const postSubtitle = subtitleBlock ? getPostText(subtitleBlock) : '';
  const postIntro = getPostHtml(introBlock);
  const postText = getPostHtml(textBlock);
  const postShareCover = shareCover?.url;
  const postAuthors = authors;
  const postTags = tags;

  const isLongHeaderRight = getLongHeader(coverBlock);

  return {
    isAffiliateArticle,
    isAuthorVisible,
    isLongHeaderRight,
    isModificationDateVisible,
    modifiedAt,
    postAuthors,
    postCover,
    postId,
    postIntro,
    postLink,
    postShareCover,
    postSubtitle,
    postTags,
    postText,
    postTitle,
    publishedAt,
    tagLink,
    tagTitle,
  };
};

export const postTypeEquals = (postType: PostType) =>
  pipe<[PostProps], PostProps['post'], boolean>(
    prop('post'),
    propEq<keyof Pick<PostProps['post'], 'type'>>('type', postType),
  );

export const renderPostLayout = (layoutMap: PostLayoutComponentMap) =>
  cond<[PostProps], ReactElement | null>([
    ...Object.entries(layoutMap).map(
      ([postType, Component]) =>
        [
          postTypeEquals(postType as PostType),
          renderPostLayoutByComponent(Component as ComponentType<PostProps>),
        ] as CondPair<[PostProps], ReactElement>,
    ),
    [
      T,
      pipe(
        prop('post'),
        pickAll(['type']),
        tap(log('error', 'Assertion: unknown post type')),
        always(null),
      ),
    ],
  ]);

export const getFirstString = (text: string) => {
  return text?.split('.')[0] || '';
};

export const isNewsFull = equals(PostType.NewsFull);
