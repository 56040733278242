import { convertToHTML, IConvertToHTMLConfig } from 'draft-convert';
import { convertFromRaw, RawDraftContentState } from 'draft-js';
import { decode } from 'html-entities';

import { blockToHTML } from './blockToHTML';
import { entityToHTML } from './entityToHTML';
import { styleToHTML } from './styleToHTML';

const options: IConvertToHTMLConfig = {
  blockToHTML,
  entityToHTML,
  styleToHTML,
};

const converterFunction = convertToHTML(options);

function replaceQuotes(value: string): string {
  return value.replace(/&amp;quot;|&quot;/gi, '"');
}

export function draftToHTML(state: RawDraftContentState): string {
  const result = decode(
    replaceQuotes(converterFunction(convertFromRaw(state))),
  );

  if (result === '<p></p>') {
    return '';
  }

  return result;
}
