import { BlockContentTestQuestionOptionCompiled, PostType } from 'pn-backend';
import { FunctionComponent } from 'react';

import { PostProps } from './post';

export enum TestStep {
  Questions = 'questions',
  Result = 'result',
  Start = 'start',
}

export type TestStepComponentMap = {
  [key in TestStep]: FunctionComponent<TestProps>;
};

export type TestProps = { step: TestStep } & Pick<
  PostProps<PostType.Test>['post']['compiled'],
  'blocks'
>;
export type AnswerOptionProps = BlockContentTestQuestionOptionCompiled & { index: number };
