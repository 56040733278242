import React, { FC } from 'react';

import { Grid, Text } from '~atoms';
import { SpecialProject } from '~postCards';
import { GridLayoutProps } from '~types';
import { getPostPreviewSummary } from '~utils';

export const OneSpecProject: FC<GridLayoutProps> = ({ posts = [] }) => (
  <Grid $position="relative" $marginTop="20px" $zIndex="10" $lg={{ $marginTop: '10px' }}>
    {posts.slice(0, 1).map((post) => {
      const { specLink } = getPostPreviewSummary(post);
      if (!specLink) return null;
      return (
        <Grid
          key={post.id}
          $margin="auto"
          $maxWidth="1200px"
          $width="100%"
          $display="flex"
          $flexFlow="column"
          $gap="30px"
          $xl={{ $maxWidth: '100%', $padding: '0 15px' }}
          $sm={{ $gap: '20px', $padding: '0' }}
        >
          <Grid $sm={{ $padding: '0 15px' }}>
            <Text $size={32} $lineHeight={34} $weight={600} $sm={{ $lineHeight: 29, $size: 26 }}>
              спецпроект
            </Text>
          </Grid>
          <SpecialProject post={post} />
        </Grid>
      );
    })}
  </Grid>
);
