import { PostType } from 'pn-backend';
import { useContext } from 'react';

import { SectionAlias } from '~constants';
import { StateContext } from '~providers';

export const useLayout = () => {
  const {
    state: { layout },
  } = useContext(StateContext);

  const isBlackScience = layout === SectionAlias.BlackScience;
  const isSport = layout === SectionAlias.OnSport;
  const isCards = layout === SectionAlias.Cards;
  const isTests = layout === SectionAlias.Tests;
  const isArticles = layout === SectionAlias.Articles;
  const isNews = layout === SectionAlias.News;
  const isMain = layout === SectionAlias.Main;
  const isAbout = layout === SectionAlias.About;
  const isAdvertisers = layout === SectionAlias.Advertisers;
  const isSpecs = layout === SectionAlias.Specs;

  const isCard = layout === PostType.Card;
  const isTest = layout === PostType.Test;
  const isArticle = layout === PostType.Article;
  const isNewsFull = layout === PostType.NewsFull;
  const isLong = layout === PostType.Longread;
  const isCustom = layout === PostType.Custom;

  const isPost = isCard || isTest || isArticle || isLong || isCustom;

  return {
    isAbout,
    isAdvertisers,
    isArticle,
    isArticles,
    isBlackScience,
    isCard,
    isCards,
    isCustom,
    isLong,
    isMain,
    isNews,
    isNewsFull,
    isPost,
    isSpecs,
    isSport,
    isTest,
    isTests,
    layout,
  };
};
