import React, { createContext, FC, PropsWithChildren, useMemo } from 'react';

type Context = { cardIndex?: number; totalCards?: number };
type Props = { cardIndex: number; totalCards: number };

export const CardContext = createContext<Context>({ cardIndex: undefined, totalCards: 0 });

export const CardProvider: FC<PropsWithChildren<Props>> = ({ cardIndex, children, totalCards }) => {
  const value = useMemo(() => ({ cardIndex, totalCards }), [cardIndex, totalCards]);
  return <CardContext.Provider value={value}>{children}</CardContext.Provider>;
};
