import Link from 'next/link';
import React, { FC } from 'react';

import { Grid, Text } from '~atoms';
import { Meta } from '~meta';
import { ErrorProps } from '~types';
import { isDefined } from '~utils';

const defaultTestProps = {
  $lineHeight: 22,
  $padding: '0 5px 0 0',
  $size: 20,
  $weight: 600,
};

const defaultTestSm = {
  $lineHeight: 30,
  $size: 20,
};

export const ErrorFallback: FC<ErrorProps> = ({ code, description }) => {
  let title = '';

  if (code === 404) title = `${code} - страница не найдена`;
  return (
    <Grid $maxWidth="1200px" $margin="40px auto" $justifyContent="center">
      {title && <Meta title={title} description=" " />}

      {isDefined(code) && (
        <Grid $justifyContent="center">
          <Text
            $weight={600}
            $size={213}
            $lineHeight={213}
            $textTransform="uppercase"
            $sm={{
              $lineHeight: 180,
              $size: 180,
            }}
          >
            {code}
          </Text>
        </Grid>
      )}
      <Grid
        $margin="20px auto"
        $justifyContent="center"
        $sm={{ $flexWrap: 'wrap', $padding: '0 15px' }}
      >
        <Text
          $lineHeight={40}
          $size={36}
          $weight={600}
          $textAlign="center"
          $sm={{
            $lineHeight: 35,
            $size: 32,
          }}
        >
          {description}
        </Text>
      </Grid>
      <Grid
        $margin="20px auto"
        $justifyContent="center"
        $display="inline-flex"
        $alignItems="center"
        $md={{ $flexWrap: 'wrap', $padding: '0 30px' }}
        $sm={{ $flexWrap: 'wrap', $padding: '0 15px' }}
      >
        <Text {...defaultTestProps} $sm={{ ...defaultTestSm }}>
          не расстраивайтесь —
        </Text>
        <Text {...defaultTestProps} $sm={{ ...defaultTestSm }}>
          на
        </Text>

        <Text
          {...defaultTestProps}
          $errorLink
          $padding="0"
          $marginRight="5px"
          $sm={{ ...defaultTestSm, $lineHeight: 22 }}
        >
          <Link href="/" passHref>
            главной
          </Link>
        </Text>

        <Text {...defaultTestProps} $sm={{ ...defaultTestSm }}>
          {' вас ждут интересные'}
        </Text>
        <Text {...defaultTestProps} $sm={{ ...defaultTestSm }}>
          новости
        </Text>
      </Grid>
    </Grid>
  );
};
