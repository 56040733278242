import { GridLayout } from 'pn-backend';
import { always, cond, CondPair, equals, pickAll, pipe, propEq, T, tap } from 'ramda';
import { ReactElement } from 'react';

import { SectionAlias } from '~constants';
import { GridLayoutProps, SectionLayoutComponentMap, SectionTextMap } from '~types';

import { log } from './log';
import { renderGridLayoutByComponent } from './render';

export const layoutEquals = (layout: GridLayout) => propEq<keyof GridLayoutProps>('layout', layout);

export const renderGridLayoutSchema = (layoutMap: SectionLayoutComponentMap) =>
  cond<[GridLayoutProps, number], ReactElement | null>([
    ...Object.entries(layoutMap).map(
      ([layout, Component]) =>
        [layoutEquals(layout as GridLayout), renderGridLayoutByComponent(Component)] as CondPair<
          [GridLayoutProps, number],
          ReactElement
        >,
    ),
    [
      T,
      pipe(pickAll(['layout']), tap(log('error', 'Assertion: unknown grid layout')), always(null)),
    ],
  ]);

export const getSectionText = (sectionMap: SectionTextMap, defaultResult: string) =>
  cond<[SectionAlias], string>([
    ...Object.entries(sectionMap).map(
      ([sectionAlias, text]) =>
        [equals(sectionAlias), always(text)] as CondPair<[SectionAlias], string>,
    ),
    [T, always(defaultResult)],
  ]);
