import React, { FC, useContext } from 'react';

import { Grid } from '~atoms';
import { TestContext } from '~providers';
import { Questions, Results, Start } from '~testSteps';
import { TestProps, TestStep, TestStepComponentMap } from '~types';
import { renderTestStep } from '~utils';

export const testStepMap: TestStepComponentMap = {
  [TestStep.Start]: Start,
  [TestStep.Questions]: Questions,
  [TestStep.Result]: Results,
};

export const TestFlow: FC<Pick<TestProps, 'blocks'>> = ({ blocks }) => {
  const { state } = useContext(TestContext);
  const { currentStep } = state;

  return <Grid>{renderTestStep(testStepMap)({ blocks, step: currentStep })}</Grid>;
};
