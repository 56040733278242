export const getType = (str: string): string | null => {
  if (str.indexOf('youtube') !== -1) return 'youtube';
  if (str.indexOf('twitter') !== -1) return 'twitter';
  if (str.indexOf('facebook') !== -1) return 'facebook';
  if (str.indexOf('instagram') !== -1) return 'instagram';
  if (str.indexOf('infogram') !== -1) return 'infogram';
  if (str.indexOf('google') !== -1) return 'googleMaps';
  if (str.indexOf('music.yandex') !== -1) return 'yandexMusic';
  if (str.indexOf('embed.music.apple') !== -1) return 'appleMusic';
  if (str.indexOf('vk.com/video') !== -1) return 'VK';
  if (str.indexOf('vk_post') !== -1) return 'VK_POST';
  if (str.indexOf('rutube') !== -1) return 'rutube';
  if (str.indexOf('telegram.org') !== -1) return 'telegram';
  if (str.indexOf('giphy') !== -1) return 'giphy';
  if (str.indexOf('kp.ru') !== -1) return 'kp';
  if (str.indexOf('vk_playlist') !== -1) return 'VK_PLAYLIST';
  if (str.indexOf('sporcle') !== -1) return 'sporcle';
  return null;
};

const parseHTML = (html: string) => {
  const regEx = /(width|height)=["']([^"']*)["']/gi;

  let width;
  let height;

  html.replace(regEx, (all: string, type: string, value: string): string => {
    if (type === 'width') width = value;
    if (type === 'height') height = value;
    return '';
  });

  if (width && height) {
    return width / height;
  }
  return '16/9';
};

export const getAspectRatio = (
  type: string,
  format?: string,
  html?: string,
): string | undefined => {
  let aspectRatio;
  if (
    format !== 'center' &&
    type !== 'inst' &&
    type !== 'twitter' &&
    type !== 'facebook' &&
    type !== 'appleMusic' &&
    type !== 'yandexMusic' &&
    type !== 'VK_POST' &&
    type !== 'telegram' &&
    !(type === 'VK' && format === 'wide')
  ) {
    aspectRatio = '16/9';
  }

  if (type === 'VK' && html) {
    aspectRatio = parseHTML(html).toString();
  }

  return aspectRatio;
};
