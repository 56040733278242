import React, { FC, useState } from 'react';

import { Share } from '~assets';
import { Grid, ShareBtn } from '~atoms';
import { Shares } from '~shares';

import styles from './styles.module.scss';

export const ShareButton: FC = () => {
  const [isSharesOpen, setSharesOpen] = useState(false);

  const toggleShares = () => setSharesOpen(!isSharesOpen);

  return (
    <>
      {!isSharesOpen && (
        <Grid className={styles.animate}>
          <ShareBtn
            onClick={toggleShares}
            $sm={{
              $fontSize: '18px',
              $height: '40px',
              $lineHeight: '22px',
              $width: '152px',
            }}
          >
            поделиться
            <Grid
              $height="24px"
              $width="24px"
              $sm={{
                $height: '20px',
                $width: '20px',
              }}
            >
              <Share />
            </Grid>
          </ShareBtn>
        </Grid>
      )}
      {isSharesOpen && (
        <Grid className={styles.animate} $height="40px">
          <Shares toggleShares={toggleShares} />
        </Grid>
      )}
    </>
  );
};
