import Link from 'next/link';
import React, { FC } from 'react';

import { Grid, Image, Text } from '~atoms';
import { GridPostCardProps } from '~types';
import { getPostPreviewSummary } from '~utils';

import styles from './styles.module.scss';

export const SpecialProject: FC<GridPostCardProps> = ({ post }) => {
  const { coverUrl, specLink, title } = getPostPreviewSummary(post);

  return (
    <Grid $position="relative" $display="flex">
      {specLink ? (
        <Link href={specLink} passHref className={styles.specialProject}>
          {coverUrl && title ? (
            <>
              <Grid
                $zIndex="5"
                $position="absolute"
                $width="100%"
                $height="394px"
                className={styles.imageOverlay}
                $lg={{ $height: '294px' }}
                $sm={{ $height: '380px' }}
              />
              <Grid
                $width="100%"
                $height="394px"
                $lg={{ $height: '294px' }}
                $sm={{ $height: '380px' }}
              >
                <Image src={coverUrl.wide} alt={title} />
              </Grid>
            </>
          ) : null}
          {title && (
            <Grid
              $position="absolute"
              $padding="0 30px"
              $bottom="40px"
              $gap="20px"
              $zIndex="6"
              $lg={{ $bottom: '20px' }}
              $sm={{ $bottom: '13px', $padding: '0 15px' }}
            >
              <Grid>
                <Text
                  $weight={600}
                  $size={44}
                  $lineHeight={48}
                  $color="white"
                  className={styles.title}
                  $innerPMarginBottom={0}
                  $innerPMarginTop={0}
                  $lg={{ $lineHeight: 26, $size: 24 }}
                  $sm={{ $lineHeight: 24, $size: 22 }}
                  dangerouslySetInnerHTML={{ __html: title }}
                />
              </Grid>
            </Grid>
          )}
        </Link>
      ) : null}
    </Grid>
  );
};
