import Link from 'next/link';
import React, { FC } from 'react';

import { Grid, Image, Tag, Text } from '~atoms';
import { GridPostCardProps } from '~types';
import { getPostPreviewSummary } from '~utils';

import styles from './styles.module.scss';

export const Top: FC<GridPostCardProps> = ({ post }) => {
  const { coverUrl, postLink, specLink, subtitle, tagLink, tagTitle, title } =
    getPostPreviewSummary(post);

  return (
    <Grid $position="relative">
      {tagLink && (
        <Link href={tagLink} passHref legacyBehavior>
          <Tag
            $lg={{
              $fontSize: '14px',
              $lineHeight: '22px',
              $padding: '3px 10px',
              $right: '10px',
              $top: '10px',
            }}
          >
            {tagTitle}
          </Tag>
        </Link>
      )}

      <Link href={specLink || postLink} passHref className={styles.top}>
        <Grid
          $height="430px"
          $gridAutoColumns="63.33%"
          $justifyContent="flex-end"
          $position="relative"
          $lg={{ $gridAutoColumns: '62%', $height: '320px' }}
          $md={{ $gridAutoColumns: '62%', $height: '260px' }}
          $sm={{ $gridAutoColumns: '100%', $height: 'auto' }}
        >
          {coverUrl && title ? (
            <>
              <Grid
                $zIndex={1}
                $position="absolute"
                $width="100%"
                $height="100%"
                className={styles.imageOverlay}
                $sm={{ $height: '195px' }}
              />
              <Grid $position="relative" $sm={{ $height: '195px' }}>
                <Image src={coverUrl.big} alt={title} priority />
              </Grid>
            </>
          ) : null}
          <Grid
            $position="absolute"
            $maxWidth="70%"
            $padding="20px 0"
            $gap="20px"
            $lg={{ $gap: '26px', $padding: '5px 0' }}
            $sm={{
              $gap: '10px',
              $maxWidth: '100%',
              $padding: '10px 0 0',
              $position: 'relative',
            }}
          >
            {title && (
              <Grid $display="inline-block">
                <Text
                  $weight={600}
                  $size={48}
                  $lineHeight={53}
                  $color="black"
                  $background="white"
                  className={styles.title}
                  $lg={{ $lineHeight: 33, $size: 30 }}
                  $md={{ $lineHeight: 30, $size: 27 }}
                  $sm={{ $lineHeight: 24, $size: 22 }}
                  dangerouslySetInnerHTML={{ __html: title }}
                />
              </Grid>
            )}
            {subtitle && (
              <Grid
                $width="420px"
                $xl={{ $width: '375px' }}
                $lg={{ $width: '278px' }}
                $sm={{ $width: '100%' }}
              >
                <Text
                  $weight={400}
                  $size={20}
                  $lineHeight={22}
                  $color="black"
                  $lg={{ $lineHeight: 15, $maxWidth: '100%', $size: 14 }}
                  dangerouslySetInnerHTML={{ __html: subtitle }}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Link>
    </Grid>
  );
};
