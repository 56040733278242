import { pathOr } from 'ramda';
import { CSSProperties } from 'react';
import styled, { css } from 'styled-components';

import { devices, Screen } from '~constants';

interface ButtonProps {
  $blackScience?: boolean;
  $borderWidth?: CSSProperties['borderWidth'];
  $fontSize?: CSSProperties['fontSize'];
  $height?: CSSProperties['height'];
  $lineHeight?: CSSProperties['lineHeight'];
  $sport?: boolean;
  $width?: CSSProperties['width'];
}

type Props = ButtonProps & { [key in Screen]?: ButtonProps };

export const ShareBtn = styled.a<Props>`
  width: 167px;
  height: 35px;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  border: 1px solid #151515;
  border-radius: 50px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 5px 15px;
  cursor: pointer;
  user-select: none;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: var(--color-postnews-blue);
    border: 1px solid var(--color-postnews-blue);
  }

  ${(props) =>
    Object.entries(devices).map(
      ([field, width]) =>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        props[field] &&
        css`
          @media only screen and (max-width: ${width}) {
            font-size: ${pathOr(null, [field, '$fontSize'], props)};
            line-height: ${pathOr(null, [field, '$lineHeight'], props)};
            top: ${pathOr(null, [field, '$top'], props)};
            right: ${pathOr(null, [field, '$right'], props)};
            left: ${pathOr(null, [field, '$left'], props)};
            color: ${pathOr(null, [field, '$color'], props)};
            padding: ${pathOr(null, [field, '$padding'], props)};
            width: ${pathOr(null, [field, '$width'], props)};
            height: ${pathOr(null, [field, '$height'], props)};
          }
        `,
    )}
`;
