import { ImageProps } from './types';

export const findImageToShow = (imagesArr: ImageProps[], imageId: number) => {
  return imagesArr.find(({ id }) => id === imageId);
};

export const getImageIndex = (images: ImageProps[], imageId: number) => {
  const currentImageObj = images.find(({ id }) => id === imageId);
  if (typeof currentImageObj === 'undefined') return undefined;
  return images.indexOf(currentImageObj);
};

export const getAvailableArrows = (images: ImageProps[], imageIndex: number) => {
  const isPrevAvailable = images[imageIndex - 1];
  const isNextAvailable = images[imageIndex + 1];

  return {
    isNextAvailable: !!isNextAvailable,
    isPrevAvailable: !!isPrevAvailable,
  };
};
