import Link from 'next/link';
import React, { FC } from 'react';

import { Grid, Image, Text } from '~atoms';
import { GridPostCardProps } from '~types';
import { getPostPreviewSummary } from '~utils';

import styles from './styles.module.scss';

export const PostOfTheWeek: FC<GridPostCardProps> = ({ post }) => {
  const { coverUrl, postLink, subtitle, title } = getPostPreviewSummary(post);

  return (
    <Link href={postLink} passHref className={styles.link}>
      <Grid
        $gridTemplateColumns="1fr 1fr"
        $gap="20px"
        $lg={{ $gap: '10px' }}
        $sm={{ $display: 'flex', $flexDirection: 'column-reverse', $gap: 'unset' }}
      >
        <Grid
          style={{ alignContent: 'space-between' }}
          $gap="20px"
          $lg={{ $gap: '26px' }}
          $sm={{ $gap: '10px', $marginTop: '10px', $maxWidth: '100%' }}
        >
          {title && (
            <Grid>
              <Text
                $weight={600}
                $size={40}
                $lineHeight={44}
                $color="black"
                $background="white"
                className={styles.title}
                $lg={{ $lineHeight: 26, $size: 24 }}
                $sm={{ $lineHeight: 24, $size: 22 }}
                dangerouslySetInnerHTML={{ __html: title }}
              />
            </Grid>
          )}

          {subtitle && (
            <Grid>
              <Text
                $weight={400}
                $size={20}
                $lineHeight={22}
                $color="black"
                $maxWidth="420px"
                $lg={{ $lineHeight: 15, $maxWidth: '100%', $size: 14 }}
                $sm={{ $maxWidth: '100%' }}
                dangerouslySetInnerHTML={{ __html: subtitle }}
              />
            </Grid>
          )}
        </Grid>
        <Grid $position="relative">
          {coverUrl && title ? (
            <>
              <Grid
                $zIndex={1}
                $position="absolute"
                $height="394px"
                $width="590px"
                className={styles.imageOverlay}
                $xl={{ $height: '300px', $width: '100%' }}
                $md={{ $height: '243px', $width: '364px' }}
                $sm={{ $maxWidth: '100%', $width: 'unset' }}
              />
              <Grid
                $position="relative"
                $height="394px"
                $width="590px"
                $xl={{ $height: '300px', $width: '100%' }}
                $md={{ $height: '243px', $width: '364px' }}
                $sm={{ $maxWidth: '100%', $width: 'unset' }}
              >
                <Image src={coverUrl.medium} alt={title} />
              </Grid>
            </>
          ) : null}
        </Grid>
      </Grid>
    </Link>
  );
};
