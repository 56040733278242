// NOTE: https://docs.imgproxy.net/generating_the_url?id=gravity

export enum ImageProxyGravity {
  CE = 'ce',
  EA = 'ea',
  NO = 'no',
  NOEA = 'noea',
  NOWE = 'nowe',
  SM = 'sm',
  SO = 'so',
  SOEA = 'soea',
  SOWE = 'sowe',
  WE = 'we',
}
