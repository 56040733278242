// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import 'react-tooltip/dist/react-tooltip.css';
import '../styles/globals.scss';

import localFont from '@next/font/local';
import NextApp, { AppContext, AppInitialProps, AppProps, NextWebVitalsMetric } from 'next/app';
import React, { useState } from 'react';

import { Grid } from '~atoms';
import { ErrorBoundary } from '~components';
import { SectionAlias } from '~constants';
import { Layout, Meta } from '~containers';
import { useYandexMetrika } from '~hooks';
import { Context, PostProvider, StateProvider } from '~providers';
import { api } from '~services';
import { PostProps, SearchProps, SectionProps, TagPostsProps } from '~types';
import { getCanonical, log } from '~utils';

type InitialProps = { initialProps: Context['state'] };

type PagesProps = PostProps | SectionProps | TagPostsProps | SearchProps;

const mainFont = localFont({
  display: 'swap',
  src: [
    {
      path: '../assets/fonts/gilroy_light.ttf',
      style: 'normal',
      weight: '300',
    },
    {
      path: '../assets/fonts/gilroy_regular.ttf',
      style: 'normal',
      weight: '400',
    },
    {
      path: '../assets/fonts/gilroy_medium.ttf',
      style: 'normal',
      weight: '500',
    },
    {
      path: '../assets/fonts/gilroy_semibold.ttf',
      style: 'normal',
      weight: '600',
    },
    {
      path: '../assets/fonts/gilroy_bold.ttf',
      style: 'normal',
      weight: '700',
    },
  ],
});

const App = (props: AppProps<PagesProps> & InitialProps) => {
  const { Component, initialProps, pageProps, router } = props;

  useYandexMetrika();

  const [isPShapedBannerVisible, setIsPShapedBannerVisible] = useState<boolean>(false);

  return (
    <main className={mainFont.className}>
      <Grid $overflow="hidden">
        <StateProvider {...initialProps} {...pageProps}>
          <PostProvider {...pageProps}>
            <Layout
              isPShapedBannerVisible={isPShapedBannerVisible}
              setIsPShapedBannerVisible={setIsPShapedBannerVisible}
            >
              <ErrorBoundary>
                <Meta canonical={getCanonical(router.asPath)} />
                {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  <Component {...pageProps} isPShapedBannerVisible={isPShapedBannerVisible} />
                }
              </ErrorBoundary>
            </Layout>
          </PostProvider>
        </StateProvider>
      </Grid>
    </main>
  );
};

// App does not support Next.js Data Fetching methods like getStaticProps or getServerSideProps
// https://nextjs.org/docs/advanced-features/custom-app#caveats
App.getInitialProps = async (context: AppContext): Promise<AppInitialProps & InitialProps> => {
  const appContext = await NextApp.getInitialProps(context);
  const [sectionsResponse, settingsResponse] = await Promise.all([
    api.getSections(),
    api.getSettings(),
  ]);

  if (sectionsResponse.data && settingsResponse.data) {
    return {
      ...appContext,
      initialProps: {
        layout: SectionAlias.Main,
        sections: sectionsResponse.data.results,
        settings: settingsResponse.data,
      },
    };
  }
  throw new Error('Could not fetch initial props');
};

export function reportWebVitals(metric: NextWebVitalsMetric) {
  log('info', 'reportWebVitals')(metric);
}

export default App;
