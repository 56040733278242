import { PostPublic, PostType } from 'pn-backend';

import { OK, Telegram, Twitter, VK, WhatsApp } from '~assets';
import { config } from '~services';
import { getPostSummary } from '~utils';

export const getSharesUrl = (shareType: string, post: PostPublic<PostType, string>) => {
  const { postCover, postLink, postTitle } = getPostSummary(post);
  const fullLink = `${config.ORIGIN_URL}${postLink}`;

  switch (shareType) {
    case 'vk':
      return `https://vk.com/share.php?url=${fullLink}&title=${postTitle}&image=${postCover}`;

    case 'odnoklassniki':
      return `https://connect.ok.ru/offer?url=${fullLink}&title=${postTitle}&imageUrl=${postCover}`;

    case 'whatsapp':
      return `https://api.whatsapp.com/send?text=${encodeURIComponent(
        `${postTitle} — ${fullLink}`,
      )}`;

    case 'telegram':
      return `https://t.me/share/url?url=${fullLink}&text=${postTitle}`;

    case 'twitter':
      return `http://twitter.com/share?url=${fullLink}&text=${postTitle}`;

    default:
      return '';
  }
};

export const sharesIcons = [
  {
    icon: VK,
    type: 'vk',
  },
  {
    icon: OK,
    type: 'odnoklassniki',
  },
  {
    icon: WhatsApp,
    type: 'whatsapp',
  },
  {
    icon: Telegram,
    type: 'telegram',
  },
  {
    icon: Twitter,
    type: 'twitter',
  },
];
