import { BlockType, ImageFormat } from 'pn-backend';
import React, { FC } from 'react';

import { Grid, Image, Text } from '~atoms';
import { useLayout } from '~hooks';
import { BlockProps } from '~types';
import { log } from '~utils';

const getCaption = (caption: string) => {
  return (
    <Text
      $size={16}
      $lineHeight={22}
      $weight={400}
      $opacity="0.5"
      $sm={{ $lineHeight: 17, $size: 12 }}
    >
      {caption}
    </Text>
  );
};

export const ImageBlock: FC<BlockProps<BlockType.Image>> = ({ compiled }) => {
  const { caption, format, height, imageURL, width } = compiled;
  const { isArticle, isCard, isLong } = useLayout();

  // ToDo Refactor: перед рендером блока прогонять через схемы валидации
  if (!imageURL || !height || !width) return null;

  const aspectRatio = width / height;

  switch (format) {
    case ImageFormat.Fullscreen:
      return (
        <Grid
          $margin="40px auto 0"
          $maxWidth="100vw"
          $width="100vw"
          $sm={{ $margin: '30px auto 0' }}
        >
          <Grid
            $position="relative"
            $width="100%"
            $height="100%"
            $aspectRatio="16/9"
            $imageObjectFit="cover"
          >
            <Image src={imageURL} alt={caption} />
          </Grid>
          <Grid
            $width="750px"
            $margin={isCard ? '5px auto 0' : '10px auto 0'}
            $md={{ $padding: '0 15px', $width: '100%' }}
          >
            {caption && getCaption(caption)}
          </Grid>
        </Grid>
      );

    case ImageFormat.HeaderWidth:
      return (
        <Grid
          $margin={isCard ? '0 auto 0' : '40px auto 0'}
          $maxWidth="1200px"
          $width="100%"
          $md={{ $maxWidth: 'unset', $padding: '0 15px' }}
          $sm={{ $margin: isCard ? '0 auto' : '30px auto 0', $padding: isCard ? '0' : '0 15px' }}
        >
          <Grid $position="relative" $width="100%" $aspectRatio={aspectRatio}>
            <Image src={imageURL} alt={caption} />
          </Grid>
          <Grid
            $width={isCard ? '100%' : '750px'}
            $margin={isCard ? '5px auto 0' : '10px auto 0'}
            $lg={{ $padding: isCard || isArticle || isLong ? '0' : '0 15px', $width: '100%' }}
          >
            {caption && getCaption(caption)}
          </Grid>
        </Grid>
      );

    case ImageFormat.ContentWidth:
      return (
        <Grid
          $margin={isCard ? '0 auto 0' : '40px auto 0'}
          $maxWidth="750px"
          $width="100%"
          $md={{ $maxWidth: 'unset', $padding: '0 15px' }}
          $sm={{
            $margin: isCard ? '0 auto' : '30px auto 0',
            $padding: isCard ? '0' : '0 15px',
          }}
        >
          <Grid $position="relative" $width="100%" $aspectRatio={aspectRatio}>
            <Image src={imageURL} alt={caption} />
          </Grid>
          <Grid
            $width={isCard ? '100%' : '750px'}
            $maxWidth="calc(100vw - 30px)"
            $margin={isCard ? '5px auto 0' : '10px auto 0'}
            $lg={{
              $maxWidth: '100%',
              $padding: isCard || isArticle || isLong ? '0' : '0 15px',
              $width: '100%',
            }}
          >
            {caption && getCaption(caption)}
          </Grid>
        </Grid>
      );

    case ImageFormat.ContentHeight:
      return (
        <Grid
          $margin={isCard ? '0 auto' : '40px auto 0'}
          $sm={{ $margin: isCard ? '0 auto' : '30px auto 0' }}
        >
          <Grid
            $position="relative"
            $aspectRatio={aspectRatio}
            $margin="0 auto"
            $width={aspectRatio <= 1 ? 'auto' : '750px'}
            $height={aspectRatio <= 1 ? '750px' : 'auto'}
            $sm={{
              $height: aspectRatio <= 1 ? '497px' : 'auto',
              $width: aspectRatio <= 1 ? 'auto' : '100vw',
            }}
          >
            <Image src={imageURL} alt={caption} />
          </Grid>
          <Grid
            $width="750px"
            $maxWidth="calc(100vw - 30px)"
            $margin={isCard ? '5px auto 0' : '10px auto 0'}
            $lg={{ $padding: isCard || isArticle || isLong ? '0' : '0 15px', $width: '100%' }}
          >
            {caption && getCaption(caption)}
          </Grid>
        </Grid>
      );

    case ImageFormat.HalfContentHeight:
      return (
        <Grid
          $margin={isCard ? '0 auto' : '40px auto 0'}
          $sm={{ $margin: isCard ? '0 auto' : '30px auto 0' }}
        >
          <Grid
            $position="relative"
            $aspectRatio={aspectRatio}
            $margin="0 auto"
            $width={aspectRatio * 450 <= 750 ? 'auto' : '750px'}
            $height={aspectRatio * 450 <= 750 ? '450px' : 'auto'}
            $sm={{
              $height: aspectRatio <= 1.2 ? '250px' : 'auto',
              $width: aspectRatio <= 1.2 ? 'auto' : '100vw',
            }}
          >
            <Image src={imageURL} alt={caption} />
          </Grid>
          <Grid
            $width="750px"
            $maxWidth="calc(100vw - 30px)"
            $margin={isCard ? '5px auto 0' : '10px auto 0'}
            $lg={{ $padding: isCard || isArticle || isLong ? '0' : '0 15px', $width: '100%' }}
          >
            {caption && getCaption(caption)}
          </Grid>
        </Grid>
      );

    default:
      log('info', 'unknown ImageBlock format')({ format });
      return null;
  }
};
