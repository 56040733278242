import { useContext } from 'react';

import { PostContext } from '~providers';
import { PostProps } from '~types';

export const usePost = (): PostProps => {
  const { nextPosts, post } = useContext(PostContext);

  return {
    nextPosts,
    post,
  };
};
