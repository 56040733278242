import { Block } from '../types/db/block/Block';
import { BlockType } from '../types/db/block/BlockType';
import { File } from '../types/db/file/File';

export function getBlockFile<T extends BlockType, D = Date>(
  block: Block<T, D>,
  fileId: number,
): File<D> | undefined {
  return block.files?.find((item) => item.id === fileId);
}
