// eslint-disable-next-line import/no-extraneous-dependencies
import { faker } from '@faker-js/faker';
import { getType } from 'mime';

import { File, FileStatus, FileType, StorageProviderType } from '../types';
import { CreateFactoryOptions } from './types/CreateFactoryOptions';
import { FileFactory } from './types/FileFactory';

export function createFileFactory(options?: CreateFactoryOptions): FileFactory {
  let currentId = options?.initId || 0;

  return (fileType, data) => {
    let ext: string;
    let url: string;

    // TODO: Сделать рабочие ссылки для всех типов
    switch (fileType) {
      case FileType.Audio:
        ext = 'mp3';
        url = faker.internet.url();
        break;
      case FileType.Image:
        ext = 'jpg';
        url = faker.image.abstract();
        break;
      case FileType.Video:
        ext = 'mpeg';
        url = faker.helpers.arrayElement([
          'https://vk.com/video_ext.php?oid=15460824&id=456239201&hash=6e3f135c9ed0e474',
          'https://vk.com/video_ext.php?oid=-371394&id=171680774&hash=5c665a58e7a8e253',
          'https://vk.com/video_ext.php?oid=15460824&id=160179647&hash=9bff222fd7746877',
          'https://vk.com/video_ext.php?oid=15460824&id=155751099&hash=33f0384729e0f461',
        ]);
        break;
      case FileType.Unknown:
        ext = 'pdf';
        url = faker.internet.url();
        break;
      default:
        ext = 'xyz';
        url = faker.internet.url();
        break;
    }

    const name = `${faker.random.alpha(10)}.${ext}`;

    const file: File = {
      contentLength: 0,
      contentType: getType(name),
      createdAt: undefined,
      createdBy: undefined,
      createdById: data?.createdBy?.id,
      deletedAt: undefined,
      error: '',
      id: ++currentId,
      name,
      provider: StorageProviderType.S3,
      remoteId: '',
      status: FileStatus.Complete,
      type: fileType,
      updatedAt: undefined,
      updatedBy: undefined,
      updatedById: data?.updatedBy?.id,
      url,
      ...data,
    } as File;

    return file;
  };
}
