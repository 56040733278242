import { pathOr, propOr } from 'ramda';
import { CSSProperties } from 'react';
import styled, { css } from 'styled-components';

import { devices, Screen } from '~constants';

type TagProps = {
  $blackScience?: boolean;
  $color?: CSSProperties['color'];
  $cursor?: CSSProperties['cursor'];
  $display?: CSSProperties['display'];
  $fontSize?: CSSProperties['fontSize'];
  $left?: CSSProperties['left'];
  $lineHeight?: CSSProperties['lineHeight'];
  $minWidth?: CSSProperties['minWidth'];
  $padding?: CSSProperties['padding'];
  $position?: CSSProperties['position'];
  $right?: CSSProperties['right'];
  $sport?: boolean;
  $top?: CSSProperties['top'];
  $transparent?: boolean;
  $transparentWhite?: boolean;
  $weight?: number;
  $width?: CSSProperties['width'];
};

type Props = TagProps & { [key in Screen]?: TagProps };

export const Tag = styled.div<Props>`
  display: ${propOr('flex', '$display')};
  position: ${propOr('absolute', '$position')};
  top: ${propOr('20px', '$top')};
  right: ${propOr('20px', '$right')};
  left: ${propOr(null, '$left')};
  width: ${propOr('initial', '$width')};
  min-width: ${propOr(null, '$minWidth')};
  padding: ${propOr('6px 14px', '$padding')};
  z-index: 2;
  height: fit-content;
  background: var(--color-gray-dark);
  border-radius: 80px;
  color: var(--color-white);
  font-weight: ${propOr('500', '$weight')};
  font-size: ${propOr('20px', '$fontSize')};
  line-height: ${propOr('22px', '$lineHeight')};
  align-items: center;
  justify-content: center;
  text-transform: lowercase;
  transition: all 0.2s ease-in-out;
  cursor: ${propOr('pointer', '$cursor')};

  ${(props) =>
    Object.entries(devices).map(
      ([field, width]) =>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        props[field] &&
        css`
          @media only screen and (max-width: ${width}) {
            font-size: ${pathOr(null, [field, '$fontSize'], props)};
            line-height: ${pathOr(null, [field, '$lineHeight'], props)};
            top: ${pathOr(null, [field, '$top'], props)};
            right: ${pathOr(null, [field, '$right'], props)};
            left: ${pathOr(null, [field, '$left'], props)};
            color: ${pathOr(null, [field, '$color'], props)};
            padding: ${pathOr(null, [field, '$padding'], props)};
          }
        `,
    )}

  ${() =>
    css`
      &:hover {
        background: var(--color-postnews-blue);
        color: var(--color-white);
      }
    `}

  ${(props) =>
    props.$blackScience &&
    css`
      background: var(--color-black-science);
      color: var(--color-white);

      &:hover {
        background: var(--color-white);
        color: var(--color-black-science);
      }
    `}

  ${(props) =>
    props.$sport &&
    css`
      background: var(--color-na-sporte);
      color: var(--color-white);

      &:hover {
        background: var(--color-white);
        color: var(--color-na-sporte);
      }
    `}

  ${(props) =>
    props.$transparent &&
    css`
      border: 1px solid var(--color-gray-dark);
      border-radius: 80px;
      background: var(--color-white);
      color: var(--color-gray-dark);

      &:hover {
        background: var(--color-gray-dark);
        color: var(--color-white);
      }
    `}


  ${(props) =>
    props.$transparentWhite &&
    css`
      border: 1px solid var(--color-white);
      border-radius: 80px;
      background: rgba(0, 0, 0, 0);
      color: var(--color-white);

      &:hover {
        background: var(--color-white);
        color: var(--color-gray-dark);
      }
    `}
`;
