// eslint-disable-next-line import/no-extraneous-dependencies
import { faker } from '@faker-js/faker';

import { PostType, Section, SectionStatus } from '../types';
import { CreateFactoryOptions } from './types/CreateFactoryOptions';
import { Factory } from './types/Factory';

faker.setLocale('ru');

export function createSectionFactory(
  options?: CreateFactoryOptions,
): Factory<Section> {
  let currentId = options?.initId || 0;

  return (data) => {
    const section: Section = {
      alias: faker.lorem.word(),
      createdAt: undefined,
      createdBy: undefined,
      createdById: data?.createdBy?.id,
      deletedAt: undefined,
      grid: undefined,
      gridId: data?.grid?.id,
      id: ++currentId,
      isShownInFooter: faker.datatype.boolean(),
      isShownInMenu: faker.datatype.boolean(),
      postTypes: faker.helpers.arrayElements(
        Object.values(PostType).filter((type) => type !== PostType.Custom),
        faker.datatype.number({ max: 3, min: 1 }),
      ),
      sortOrder: faker.datatype.number(),
      status: faker.helpers.objectValue(SectionStatus),
      title: faker.lorem.sentence(),
      updatedAt: undefined,
      updatedBy: undefined,
      updatedById: data?.updatedBy?.id,
      ...data,
    } as Section;

    return section;
  };
}
