// eslint-disable-next-line import/no-extraneous-dependencies
import { faker } from '@faker-js/faker';

import { EmployeeAuth, EmployeeAuthProvider } from '../types';
import { CreateFactoryOptions } from './types/CreateFactoryOptions';
import { Factory } from './types/Factory';

export function createEmployeeAuthFactory(
  options?: CreateFactoryOptions,
): Factory<EmployeeAuth> {
  let currentId = options?.initId || 0;

  return (data) => {
    const employeeAuth: EmployeeAuth = {
      createdAt: undefined,
      deletedAt: undefined,
      employee: undefined,
      employeeId: data?.employee?.id,
      id: ++currentId,
      key: faker.internet.email(),
      provider: EmployeeAuthProvider.local,
      secret: undefined,
      updatedAt: undefined,
      ...data,
    } as EmployeeAuth;

    return employeeAuth;
  };
}
