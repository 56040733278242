import { useRouter } from 'next/router';
import { useContext } from 'react';

import { SectionAlias } from '~constants';
import { StateContext } from '~providers';
import { getSectionLink, isDefined } from '~utils';

export const useSection = () => {
  const {
    state: { sections },
  } = useContext(StateContext);
  const { asPath } = useRouter();
  const currentSection = sections.find(({ alias }) => asPath === getSectionLink(alias));

  const advertisersSection = sections.find(({ alias }) => SectionAlias.Advertisers === alias);
  const aboutSection = sections.find(({ alias }) => SectionAlias.About === alias);

  const isSection = isDefined(currentSection);

  return {
    aboutSection,
    advertisersSection,
    currentSection,
    isSection,
  };
};
