import loadScript from 'load-script';
import { identity } from 'ramda';
import { RefObject } from 'react';

import { WidgetType } from '~types';

import { getType } from '../containers/PostBlocks/WidgetBlock/utils';

const scripts = {
  [WidgetType.VK_PLAYLIST]: {
    id: 'vk_playlist-wjs',
    src: 'https://vk.com/js/api/openapi.js?169',
  },
};

const execScript = (html: string) => {
  const scriptHTML = new DOMParser().parseFromString(html, 'text/html');
  const VkScripts = scriptHTML.body.querySelectorAll('script');
  VkScripts.forEach((scriptNode) => {
    if (scriptNode.innerText.length !== 0) {
      // eslint-disable-next-line @typescript-eslint/no-implied-eval,no-new-func
      new Function(scriptNode.innerText)();
    }
  });
};

export const vkPlaylistWidget = (html: string, parent: RefObject<HTMLDivElement>) => {
  const type = getType(html) as WidgetType.VK_PLAYLIST;

  if (!html || !parent || !type) return null;

  const script = scripts[type];
  loadScript(script.src, { attrs: { id: script.id } }, () => {
    execScript(html);
  });
  if (parent?.current) {
    // eslint-disable-next-line no-param-reassign
    parent.current.innerHTML = html;
  }

  return null;
};
