import classNames from 'classnames';
import Link from 'next/link';
import React, { FC } from 'react';

import { Grid, Image, Tag, Text } from '~atoms';
import { useLayout } from '~hooks';
import { GridPostCardProps } from '~types';
import { getPostPreviewSummary } from '~utils';

import styles from './styles.module.scss';

export const Big: FC<GridPostCardProps> = ({ post }) => {
  const { coverUrl, postLink, tagLink, tagTitle, title } = getPostPreviewSummary(post);
  const { isSport } = useLayout();

  return (
    <Grid $position="relative">
      {tagLink && (
        <Link href={tagLink} passHref legacyBehavior>
          <Tag
            $sport={isSport}
            $top="60px"
            $lg={{
              $fontSize: '14px',
              $lineHeight: '22px',
              $padding: '3px 10px',
              $right: '10px',
              $top: '30px',
            }}
            $sm={{ $top: '10px' }}
          >
            {tagTitle}
          </Tag>
        </Link>
      )}
      <Link href={postLink} passHref className={classNames(styles.big, isSport && styles.sport)}>
        <Grid
          $position="relative"
          $gridAutoColumns="50%"
          $justifyContent="flex-end"
          $width="100%"
          $padding="40px 0"
          $alignItems="center"
          $borderTop="1px solid black"
          $borderBottom="1px solid black"
          $lg={{ $padding: '20px 0' }}
          $sm={{
            $borderBottom: 'none',
            $borderTop: 'none',
            $gridAutoColumns: '100%',
            $padding: '0',
          }}
        >
          {title && (
            <Grid
              $gap="20px"
              $position="absolute"
              $display="flex"
              $width="calc(100% - 590px)"
              $padding="0 20px 0 0"
              $lg={{ $padding: '0 10px 0 0', $width: 'calc(100% - 460px)' }}
              $md={{ $padding: '0 10px 0 0', $width: 'calc(100% - 364px)' }}
              $sm={{
                $order: '1',
                $padding: '10px 0 0',
                $position: 'relative',
                $width: '100%',
              }}
            >
              <Text
                $weight={600}
                $size={40}
                $lineHeight={43}
                $color="black"
                className={styles.title}
                $lg={{ $lineHeight: 26, $size: 24 }}
                $sm={{ $lineHeight: 24, $size: 22 }}
                dangerouslySetInnerHTML={{ __html: title }}
              />
            </Grid>
          )}
          <Grid
            $position="relative"
            $width="590px"
            $justifySelf="flex-end"
            $lg={{ $width: '460px' }}
            $md={{ $width: '364px' }}
            $sm={{ $width: '100%' }}
          >
            {coverUrl && title ? (
              <>
                <Grid
                  $zIndex={1}
                  $position="absolute"
                  $width="100%"
                  $height="394px"
                  className={styles.imageOverlay}
                  $lg={{ $height: '300px' }}
                  $md={{ $height: '243px' }}
                />
                <Grid
                  $height="394px"
                  $width="100%"
                  // $padding="0 0 0 10px"
                  $position="relative"
                  $lg={{ $height: '300px' }}
                  $md={{ $height: '243px' }}
                  $sm={{ $padding: '0' }}
                >
                  <Image src={coverUrl.big} alt={title} />
                </Grid>
              </>
            ) : null}
          </Grid>
        </Grid>
      </Link>
    </Grid>
  );
};
