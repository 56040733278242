import React, { FC } from 'react';

import { Grid } from '~atoms';
import { DEFAULT_GRID_PROPS } from '~constants';
import { Standard } from '~postCards';
import { GridLayoutProps } from '~types';

export const TwoStandard: FC<GridLayoutProps> = ({ posts = [] }) => (
  <Grid
    {...DEFAULT_GRID_PROPS}
    className="two-standard-cards"
    $gridTemplateColumns="1fr 1fr"
    $columnGap="20px"
    $padding="0"
    $zIndex="10"
    $xl={{ $columnGap: '10px', $maxWidth: '100%', $padding: '0 15px' }}
    $sm={{ $gridTemplateColumns: '1fr', $rowGap: '30px' }}
  >
    {posts.slice(0, 2).map((post) => (
      <Standard key={post.id} post={post} />
    ))}
  </Grid>
);
