import React, { Dispatch, FC, PropsWithChildren, SetStateAction, useEffect } from 'react';

import { Grid } from '~atoms';
import { useIsMain, useLayout } from '~hooks';
import { AdbBlockBannerArticle } from '~mocks';

import { useMobile } from '../../hooks/useMobile';

export const BrandingLayout: FC<
  PropsWithChildren & {
    isPShapedBannerVisible: boolean;
    setIsPShapedBannerVisible: Dispatch<SetStateAction<boolean>>;
  }
> = (props) => {
  const { children, isPShapedBannerVisible, setIsPShapedBannerVisible } = props;
  const { isArticle } = useLayout();

  const isMobile = useMobile();
  const isMain = useIsMain();

  const isArticlePShapedBannerVisible = !isMobile && isArticle;

  const onPShapedBannerLoad = () => {
    setIsPShapedBannerVisible(true);
  };

  useEffect(() => {
    const banners = document.getElementsByClassName('adfox-banner-background');

    Array.from(banners).forEach((banner) => {
      banner.remove();
    });
    setIsPShapedBannerVisible(false);
  }, [isArticle, isMain, setIsPShapedBannerVisible]);

  return (
    <>
      {isPShapedBannerVisible && isMain && (
        <Grid
          $height="20px"
          $background="white"
          $width="1286px"
          $margin="0 auto"
          $zIndex={1000}
          $sm={{ $display: 'none' }}
        />
      )}
      <Grid $overflow={isPShapedBannerVisible && isMain ? 'hidden' : 'unset'} $width="100%">
        <Grid
          $margin={isArticle && !isArticlePShapedBannerVisible ? '0' : '-20px auto 0'}
          $position="relative"
        >
          {isArticle && typeof isMobile !== 'undefined' && !isMobile && (
            <AdbBlockBannerArticle onLoad={onPShapedBannerLoad} />
          )}
          <Grid
            $margin={isPShapedBannerVisible && isArticle ? '250px auto 0' : '0'}
            $position="relative"
            $zIndex="100000"
            $maxWidth={isArticle && isArticlePShapedBannerVisible ? '1000px' : '1286px'}
            $width={isArticle && isArticlePShapedBannerVisible ? '1000px' : '1286px'}
            $lg={{ $maxWidth: '100%', $width: '100%' }}
          >
            <Grid
              $position="absolute"
              $width="100%"
              $display="block"
              $height="100%"
              $overflow="hidden"
              $userSelect="none"
            >
              <Grid
                $background="white"
                $height="100000vh"
                $margin="0 auto"
                $maxWidth={isArticle && isArticlePShapedBannerVisible ? '1000px' : '1286px'}
                $padding="25px 0 0"
              />
            </Grid>

            <Grid
              $padding="25px 0 0"
              id="section_wrapper"
              $position="relative"
              $width={isArticle && !isPShapedBannerVisible ? '100vw' : 'unset'}
              $left={isArticle && !isPShapedBannerVisible ? '50%' : 'unset'}
              $right={isArticle && !isPShapedBannerVisible ? '50%' : 'unset'}
              $margin={isArticle && !isPShapedBannerVisible ? '0 -50vw 0' : 'unset'}
            >
              {children}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
