import { BlockType } from 'pn-backend';
import React, { FC } from 'react';

import { Grid, Text } from '~atoms';
import { useLayout } from '~hooks';
import { BlockProps } from '~types';

const defaultProps = {
  $margin: 'auto',
  $marginTop: '20px',
  $maxWidth: '750px',
  $width: '100%',
};

interface Subtitle extends BlockProps<BlockType.Subtitle> {
  isOnCover?: boolean;
}

export const SubTitleBlock: FC<Subtitle> = ({ compiled: { text }, isOnCover }) => {
  const { isAbout, isAdvertisers, isCard, isLong, isTest } = useLayout();

  let lineHeight = 30;
  if (isTest) lineHeight = 26;
  if (isOnCover) lineHeight = 22;

  let lineHeightMob = 17;
  if (isTest) lineHeightMob = 23;
  if (isCard) lineHeightMob = 24;

  if (!text) return null;

  return (
    <Grid
      {...defaultProps}
      $margin={isLong ? '' : 'auto'}
      $sm={{ $padding: isCard || isLong ? '0' : '0 15px' }}
    >
      <Text
        $weight={isAbout || isAdvertisers ? 600 : 400}
        $size={20}
        $lineHeight={lineHeight}
        $spaceBetweenParagraph="15px 0 0"
        $sm={{
          $lineHeight: lineHeightMob,
          $size: isTest ? 18 : 16,
          $weight: isAbout || isAdvertisers ? 600 : 400,
        }}
      >
        {text}
      </Text>
    </Grid>
  );
};
