import classNames from 'classnames';
import Link from 'next/link';
import React, { FC, useContext } from 'react';

import { Dzen, Facebook, OK, Telegram, Tiktok, Twitter, VK, YouTube } from '~assets';
import { Grid, RoundIcon } from '~atoms';
import { StateContext } from '~providers';

import styles from './styles.module.scss';

const MAX_ICONS = 3;

const socials = [
  {
    icon: Dzen,
    type: 'dzen',
  },
  {
    icon: Facebook,
    type: 'facebook',
  },
  {
    icon: OK,
    type: 'odnoklassniki',
  },
  {
    icon: Telegram,
    type: 'telegram',
  },
  {
    icon: Tiktok,
    type: 'tiktock',
  },
  {
    icon: Twitter,
    type: 'twitter',
  },
  {
    icon: VK,
    type: 'vk',
  },
  {
    icon: YouTube,
    type: 'youtube',
  },
];

interface Props {
  inFooter?: boolean;
  inHeader?: boolean;
  inverse?: boolean;
  small?: boolean;
}

export const Socials: FC<Props> = ({
  inFooter = false,
  inHeader = false,
  inverse = false,
  small = false,
}) => {
  const {
    state: { settings },
  } = useContext(StateContext);
  let iconsCounter = 0;

  const { socialNetworkLinks } = settings;

  const getIconByType = (type: string) => {
    const social = socials.find((socialObj) => socialObj.type === type);
    return social?.icon;
  };

  return (
    <Grid
      $gridAutoFlow="column"
      $gap="10px"
      $gridAutoColumns="min-content"
      $alignItems="center"
      $marginBottom="-2px"
      $lg={{ $marginBottom: '0' }}
    >
      {socialNetworkLinks.map(({ showInFooter, showInHeader, type, url }, index) => {
        const isVisible = (inHeader && showInHeader) || (inFooter && showInFooter);
        if (!isVisible || iconsCounter === MAX_ICONS) return null;
        if (isVisible) iconsCounter += 1;

        const Icon = getIconByType(type);

        return (
          <Link href={url} key={index}>
            <RoundIcon $color={`social-${type}`} $inverse={inverse} $small={small}>
              <Icon className={classNames(styles[type], small && styles.small)} />
            </RoundIcon>
          </Link>
        );
      })}
    </Grid>
  );
};
