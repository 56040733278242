import { BlockType, CoverFormat } from 'pn-backend';
import React, { FC } from 'react';

import { Grid, Image, Text } from '~atoms';
import { useLayout } from '~hooks';
import { BlockProps } from '~types';
import { isDefined, log } from '~utils';

export const CoverBlock: FC<BlockProps<BlockType.Cover>> = ({
  compiled: { caption, format, hideInPost, imageURL },
}) => {
  const { isCard, isTest } = useLayout();
  if (!imageURL) return null;

  let renderType = !hideInPost && format ? format : 'noCover';
  if (!format) renderType = CoverFormat.FullscreenCaptionOver;

  if (isTest) renderType = 'test';
  if (!hideInPost && isCard) renderType = 'card';

  switch (renderType) {
    case CoverFormat.FullscreenCaptionOver:
      return (
        <Grid $marginBottom="10px" $sm={{ $marginBottom: '0' }}>
          <Grid $position="relative" $sm={{ $background: 'black', $height: '40vh' }}>
            {isDefined(imageURL) ? (
              <>
                <Grid
                  $zIndex={1}
                  $position="absolute"
                  $width="100%"
                  $height="100vh"
                  $background="black"
                  $opacity={0.2}
                  $xl={{ $height: '60vh' }}
                  $sm={{ $background: 'black', $height: '40vh' }}
                />
                <Grid
                  $height="100vh"
                  $width="100%"
                  $position="relative"
                  $color="white"
                  $xl={{ $height: '60vh' }}
                  $sm={{ $height: '40vh' }}
                >
                  <Image src={imageURL} alt={caption} priority />
                </Grid>
              </>
            ) : (
              <Grid $width="100%" $height="100vh" $background="black" />
            )}
          </Grid>
          {caption && (
            <Grid
              $margin="10px 0 0 120px"
              $position="absolute"
              $top="100vh"
              $xl={{ $margin: '5px 15px', $position: 'absolute', $top: '60vh' }}
              $sm={{ $margin: '5px 15px 0', $position: 'relative', $top: '0' }}
            >
              <Text
                $weight={500}
                $size={14}
                $lineHeight={17}
                $color="gray"
                $xl={{ $color: 'gray-dark' }}
                $sm={{ $lineHeight: 14, $size: 12 }}
              >
                {caption}
              </Text>
            </Grid>
          )}
        </Grid>
      );

    case CoverFormat.FullscreenCaptionRight:
      return (
        <Grid $marginBottom="10px" $xl={{ $marginBottom: '0' }}>
          {isDefined(imageURL) ? (
            <Grid
              $position="relative"
              $gridTemplateColumns="1fr 1fr"
              $background="black"
              $xl={{
                $gridTemplateColumns: '1fr',
              }}
            >
              <Grid
                $height="100vh"
                $width="100%"
                $position="relative"
                $color="white"
                $xl={{ $height: '600px' }}
                $sm={{ $height: '445px' }}
              >
                <Image src={imageURL} alt={caption} priority />
              </Grid>
            </Grid>
          ) : (
            <Grid
              $width="100%"
              $height="100vh"
              $background="black"
              $xl={{ $height: '600px' }}
              $sm={{ $height: '445px' }}
            />
          )}
          {caption && (
            <Grid
              $margin="10px 0 0 120px"
              $position="absolute"
              $top="100vh"
              $zIndex="10"
              $xl={{ $margin: '5px 15px', $position: 'absolute', $top: '600px' }}
              $sm={{ $top: '445px' }}
            >
              <Text
                $weight={500}
                $size={14}
                $lineHeight={17}
                $color="gray"
                $xl={{ $color: 'gray-dark' }}
                $sm={{ $lineHeight: 14, $size: 12 }}
              >
                {caption}
              </Text>
            </Grid>
          )}
        </Grid>
      );

    case CoverFormat.FullscreenCaptionUnder:
      return (
        <Grid $position="relative" $marginBottom="10px" $xl={{ $marginBottom: '0' }}>
          {isDefined(imageURL) ? (
            <Grid
              $height="100vh"
              $width="100%"
              $position="relative"
              $color="white"
              $xl={{ $height: '60vh' }}
              $sm={{ $height: '40vh' }}
            >
              <Image src={imageURL} alt={caption} priority />
            </Grid>
          ) : (
            <Grid $width="100%" $height="100vh" $background="black" />
          )}
          {caption && (
            <Grid $margin="10px 0 0 120px" $xl={{ $margin: '5px 15px 0' }}>
              <Text
                $weight={500}
                $size={14}
                $lineHeight={17}
                $color="gray"
                $xl={{ $color: 'gray-dark' }}
                $sm={{ $lineHeight: 14, $size: 12 }}
              >
                {caption}
              </Text>
            </Grid>
          )}
        </Grid>
      );

    case CoverFormat.CaptionAbove:
    case CoverFormat.CaptionUnder:
      return (
        <Grid
          $width="100%"
          $position="relative"
          $margin="0"
          $sm={{ $margin: '20px 0 0', $width: isTest ? '100%' : '100vw' }}
        >
          <Grid
            $width="100%"
            $position="relative"
            $height="415px"
            $xl={{ $height: '600px' }}
            $sm={{ $height: '210px' }}
          >
            <Image src={imageURL} alt={caption} priority />
          </Grid>
          <Grid
            $margin="10px auto 0"
            $width="100%"
            $sm={{ $margin: '10px 15px 0', $width: 'auto' }}
          >
            <Text $weight={500} $size={12} $lineHeight={14} $color="gray">
              {caption}
            </Text>
          </Grid>
        </Grid>
      );

    case 'cardWide':
      return (
        <Grid
          $maxWidth="750px"
          $position="relative"
          $width="100%"
          $height="415px"
          $sm={{ $height: '197px' }}
        >
          <Image src={imageURL} alt={caption} priority />
          <Grid $margin="10px auto 0" $width="100%">
            <Text $weight={500} $size={12} $lineHeight={14} $color="gray">
              {caption}
            </Text>
          </Grid>
        </Grid>
      );

    case 'test':
      return (
        <Grid
          $width="100%"
          $position="relative"
          $height="415px"
          $xl={{ $height: '397px' }}
          $sm={{ $height: '186px' }}
        >
          <Image src={imageURL} alt={caption} priority />
          <Grid
            $margin="10px auto 0"
            $width="auto"
            $position="absolute"
            $bottom="5px"
            $right="10px"
            $left="10px"
          >
            <Text $weight={400} $size={12} $lineHeight={14} $color="white" $textAlign="end">
              {caption}
            </Text>
          </Grid>
        </Grid>
      );

    case 'card':
      return (
        <Grid
          $width="100%"
          $position="relative"
          $margin="20px 0 0"
          $sm={{ $margin: '20px 0 0', $width: '100%' }}
        >
          <Grid
            $width="100%"
            $position="relative"
            $height="415px"
            $aspectRatio={1.5}
            $xl={{ $height: 'auto' }}
          >
            <Image src={imageURL} alt={caption} priority />
          </Grid>
          <Grid
            $margin={isCard ? '5px auto' : '10px auto'}
            $width="100%"
            $sm={{ $margin: '10px 0 0', $width: 'auto' }}
          >
            <Text $weight={500} $size={12} $lineHeight={14} $color="gray">
              {caption}
            </Text>
          </Grid>
        </Grid>
      );

    default:
      log('info', 'Unknown Cover format')({ renderType });
      return null;
  }
};
