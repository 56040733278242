import { BlockType, CoverFormat } from 'pn-backend';
import React, { FC } from 'react';

import { Grid, H1 } from '~atoms';
import { useLayout } from '~hooks';
import { BlockProps } from '~types';

const defaultProps = {
  $marginTop: '40px',
  $maxWidth: '750px',
  $width: '100%',
};

type H1Type = BlockProps<BlockType.H1> & { renderType?: string };

export const H1Block: FC<H1Type> = ({ compiled, renderType }) => {
  let { layout } = useLayout();

  if (renderType) layout = renderType;

  const { text } = compiled;

  if (!text) return null;

  switch (layout) {
    case CoverFormat.FullscreenCaptionUnder:
      return (
        <Grid {...defaultProps} $marginTop="0" $sm={{ $padding: '0 15px' }}>
          <H1
            $lineHeight={44}
            $size={40}
            $weight={700}
            $lg={{ $lineHeight: 44, $size: 40 }}
            $sm={{ $lineHeight: 31, $size: 28 }}
          >
            {text}
          </H1>
        </Grid>
      );

    case CoverFormat.CaptionAbove:
    case CoverFormat.CaptionUnder:
      return (
        <Grid {...defaultProps} $marginTop="20px">
          <H1 $lineHeight={44} $size={40} $weight={700} $sm={{ $lineHeight: 31, $size: 28 }}>
            {text}
          </H1>
        </Grid>
      );

    case CoverFormat.FullscreenCaptionOver:
      return (
        <Grid {...defaultProps} $maxWidth="856px" $marginTop="30px" $sm={{ $marginTop: '0' }}>
          <H1
            $lineHeight={53}
            $size={48}
            $lg={{ $lineHeight: 44, $size: 40 }}
            $sm={{ $lineHeight: 31, $size: 28, $weight: 700 }}
          >
            {text}
          </H1>
        </Grid>
      );

    case CoverFormat.FullscreenCaptionRight:
      return (
        <Grid {...defaultProps} $marginTop="30px" $sm={{ $marginTop: '0' }}>
          <H1
            $lineHeight={53}
            $size={48}
            $xsl={{ $lineHeight: 44, $size: 40 }}
            $sm={{ $lineHeight: 31, $size: 28, $weight: 700 }}
          >
            {text}
          </H1>
        </Grid>
      );

    case 'card':
      return (
        <Grid {...defaultProps} $sm={{ $marginTop: '20px' }}>
          <H1
            $textTransform="uppercase"
            $lg={{ $lineHeight: 33, $size: 30, $weight: 700 }}
            $sm={{ $lineHeight: 30, $size: 28, $weight: 700 }}
          >
            {text}
          </H1>
        </Grid>
      );

    case 'article':
      return (
        <Grid {...defaultProps} $sm={{ $padding: '0 15px' }}>
          <H1 $size={40} $lineHeight={44} $weight={700} $sm={{ $lineHeight: 31, $size: 28 }}>
            {text}
          </H1>
        </Grid>
      );

    case 'test':
      return (
        <Grid {...defaultProps} $sm={{ $padding: '0 15px' }}>
          <H1 $size={40} $lineHeight={44} $weight={500} $sm={{ $lineHeight: 31, $size: 28 }}>
            {text}
          </H1>
        </Grid>
      );

    default:
      return (
        <Grid {...defaultProps} $margin="0 auto" $sm={{ $marginTop: '0', $padding: '0 15px' }}>
          <H1
            $size={40}
            $lineHeight={44}
            $weight={700}
            $lg={{ $lineHeight: 44, $size: 40 }}
            $sm={{ $lineHeight: 31, $size: 28, $weight: 700 }}
          >
            {text}
          </H1>
        </Grid>
      );
  }
};
