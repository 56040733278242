export { default as Copy } from './copy.svg';
export { default as Dzen } from './dzen.svg';
export { default as Facebook } from './facebook.svg';
export { default as OK } from './odnoklassniki.svg';
export { default as Telegram } from './telegram.svg';
export { default as Tiktok } from './tiktok.svg';
export { default as Twitter } from './twitter.svg';
export { default as VK } from './vk.svg';
export { default as WhatsApp } from './whatsapp.svg';
export { default as YouTube } from './youtube.svg';
