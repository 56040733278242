import React, { FC } from 'react';

import { Grid, Text } from '~atoms';
import { DEFAULT_GRID_PROPS } from '~constants';
import { NewsItem } from '~postCards';
import { GridLayoutProps, GridPostProps } from '~types';
import { groupPublicationsByDay } from '~utils';

export const News: FC<GridLayoutProps> = ({ posts = [] }) => {
  const groupedPublications = groupPublicationsByDay(posts) as [
    day: string,
    dayPosts: GridPostProps[],
  ][];

  return (
    <Grid
      {...DEFAULT_GRID_PROPS}
      $background="white"
      $color="black"
      $padding="40px 15px"
      $maxWidth="970px"
      $xl={{ $gap: '20px', $padding: '0 15px 30px' }}
      $lg={{ $maxWidth: '768px', $padding: '40px 15px' }}
      $sm={{ $overflow: 'hidden', $padding: '0 15px' }}
    >
      <Grid $gap="60px" $sm={{ $gap: '40px' }}>
        {groupedPublications.map(([day, dayPosts]) => (
          <Grid key={day} $rowGap="40px" $lg={{ $rowGap: '30px' }} $sm={{ $rowGap: '30px' }}>
            <Text
              $weight={600}
              $size={26}
              $lineHeight={29}
              $textTransform="uppercase"
              $lg={{ $lineHeight: 22, $size: 20 }}
              $sm={{ $lineHeight: 20, $size: 18 }}
            >
              {day}
            </Text>
            <Grid $rowGap="40px" $lg={{ $rowGap: '20px' }}>
              {dayPosts.map((post) => (
                <NewsItem key={post.id} post={post} />
              ))}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
