/* eslint-disable typescript-sort-keys/string-enum */
export enum Screen {
  XS = '$xs',
  SM = '$sm',
  MD = '$md',
  LG = '$lg',
  XL = '$xl',
  XSL = '$xsl',
  XXL = '$xxl',
}

export const devices = {
  [Screen.XXL]: '1600px',
  [Screen.XSL]: '1300px',
  [Screen.XL]: '1200px',
  [Screen.LG]: '1024px',
  [Screen.MD]: '768px',
  [Screen.SM]: '576px',
  [Screen.XS]: '375px',
};
