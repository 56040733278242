import { Block, BlockType } from '../types';
import { emptyBlockContentFactoryMap } from './emptyBlockContentFactoryMap';

export function createEmptyBlocks<B extends BlockType>(
  defaultFixedBlocksTypes: B[],
  defaultBlockTypes: B[],
  additinalBlockTypes: B[],
): Block<B>[] {
  return [
    ...defaultFixedBlocksTypes.map((type: B, index) => ({
      content: emptyBlockContentFactoryMap[type](),
      sortOrder: index - defaultFixedBlocksTypes.length,
      type,
    })),
    ...defaultBlockTypes.map((type: B, index) => ({
      content: emptyBlockContentFactoryMap[type](),
      sortOrder: index,
      type,
    })),
    ...additinalBlockTypes.map((type: B, index) => ({
      content: emptyBlockContentFactoryMap[type](),
      sortOrder: index + defaultBlockTypes.length,
      type,
    })),
  ] as Block<B>[];
}
