import React, { FC } from 'react';

import { Grid, Image, Text } from '~atoms';
import { DEFAULT_GRID_PROPS } from '~constants';
import { CompilationItem } from '~postCards';
import { GridLayoutProps } from '~types';

export const MainCompilation: FC<GridLayoutProps> = ({ cover, posts, title }) => {
  const totalPosts = posts.length;
  const lastPostToSlice = totalPosts >= 3 ? 3 : totalPosts;

  return (
    <Grid
      {...DEFAULT_GRID_PROPS}
      $padding="0 0 30px"
      $zIndex="10"
      $xl={{ $maxWidth: '100%', $padding: '0 15px 20px' }}
      $sm={{ $padding: '0 15px 10px' }}
    >
      {title && (
        <Text
          $size={32}
          $lineHeight={35}
          $weight={600}
          $color="black"
          $textTransform="lowercase"
          $sm={{ $lineHeight: 29, $size: 26 }}
        >
          {title}
        </Text>
      )}

      <Grid {...DEFAULT_GRID_PROPS} $padding="30px 0 0" $sm={{ $padding: '20px 0 0' }}>
        <Grid
          $columnGap="20px"
          $gridTemplateColumns="1fr 1fr"
          $lg={{ $columnGap: '10px' }}
          $sm={{ $gap: '20px', $gridTemplateColumns: '1fr' }}
        >
          <Grid
            $width="100%"
            $position="relative"
            $imageObjectFit="cover"
            $height="394px"
            $lg={{ $height: '340px' }}
            $sm={{ $height: '250px', $margin: '0 -15px', $width: 'auto' }}
          >
            {cover?.url ? <Image src={cover?.url} alt={title} /> : null}
          </Grid>
          <Grid $display="flex" $flexDirection="column" $justifyContent="space-between">
            {posts.slice(0, lastPostToSlice).map((post, index) => {
              const isLast = index === posts.length - 1;

              return (
                <Grid key={post.id}>
                  <CompilationItem post={post} />
                  {!isLast && (
                    <Grid
                      $width="100%"
                      $height="1px"
                      $background="black"
                      $margin="18px 0"
                      $sm={{ $margin: '20px 0' }}
                    />
                  )}
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
