import { PostType } from 'pn-backend';

export const LIMIT = 30;
export const TYPES: PostType[] = [
  PostType.Card,
  PostType.Article,
  PostType.Longread,
  PostType.Spec,
  PostType.Test,
  PostType.NewsFull,
];
export const OFFSET = 0;
