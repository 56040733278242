import { useEffect, useMemo, useState } from 'react';

import { HEADER_HEIGHT, MOBILE_HEADER_HEIGHT } from '~constants';

export function useOnScreen(elem: HTMLElement | null) {
  const [isIntersecting, setIntersecting] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 500) {
      setIsMobile(true);
    }
  }, []);

  const topMargin = isMobile ? MOBILE_HEADER_HEIGHT : HEADER_HEIGHT;

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting), {
        rootMargin: `-${topMargin} 0px 0px 0px`,
      }),
    [topMargin],
  );

  useEffect(() => {
    if (elem) {
      observer.observe(elem);
    }
    return () => observer.disconnect();
  }, [elem, observer]);

  return isIntersecting;
}
