import { isThisYear, isToday, isYesterday } from 'date-fns';
import { format, formatWithOptions, parseISO } from 'date-fns/fp';
import { ru } from 'date-fns/locale';
import { always, cond, pipe, T } from 'ramda';

export const formatDate = pipe(
  parseISO,
  cond([
    [(date) => isToday(date), always('Сегодня')],
    [
      (date) => isYesterday(date),
      (value) => `Вчера, ${formatWithOptions({ locale: ru }, 'dd MMMM', value)}`,
    ],
    [(date) => isThisYear(date), formatWithOptions({ locale: ru }, 'dd MMMM')],
    [T, formatWithOptions({ locale: ru }, 'dd MMMM yyyy')],
  ]),
);

export const formatTime = pipe(parseISO, format('HH:mm'));
export const formatFullDate = pipe(
  parseISO,
  formatWithOptions({ locale: ru }, 'dd MMMM yyyy HH:mm'),
);
