import React, { FC } from 'react';

import { Chevron } from '~assets';
import { Grid, HugeButton, Text } from '~atoms';

type Props = {
  children: React.ReactNode;
  maxHeight?: string;
  onClick?: () => void;
};

export const NextButton: FC<Props> = ({ children, maxHeight, onClick }) => {
  return (
    <Grid
      $transition="all 0.2s ease"
      $overflow="hidden"
      $maxHeight={maxHeight}
      $lg={{ $maxWidth: '100%' }}
      $sm={{ $height: '59px' }}
    >
      <HugeButton onClick={onClick} $height="81px" $sm={{ $height: '59px' }}>
        <Text
          $size={32}
          $maxHeight="32px"
          $weight={600}
          $lineHeight="unset"
          $sm={{ $lineHeight: 20, $size: 18 }}
        >
          {children}
        </Text>

        <Grid
          $width="32px"
          $height="32px"
          $marginLeft="10px"
          $transform="rotate(-90deg)"
          $sm={{ $marginTop: '-2px', $transform: 'scale(0.65) rotate(-90deg)' }}
        >
          <Chevron />
        </Grid>
      </HugeButton>
    </Grid>
  );
};
