import { CompileOptions } from '../types/common/CompileOptions';
import { Block } from '../types/db/block/Block';
import { BlockType } from '../types/db/block/BlockType';

export function syncBlockPreviewWithCover(
  blocks: Block<BlockType>[],
  compileOptions: CompileOptions,
) {
  const coverBlock = blocks.find(
    (block) => block.type === BlockType.Cover,
  ) as unknown as Block<BlockType.Preview>;

  const previewBlock = blocks.find(
    (block) => block.type === BlockType.Preview,
  ) as unknown as Block<BlockType.Preview>;

  if (coverBlock && previewBlock) {
    previewBlock.content = {
      fileId: coverBlock.content?.fileId,
      images: compileOptions.imageProxy[BlockType.Preview],
    };
    previewBlock.files = [...coverBlock.files];
  }
}
