import loadScript from 'load-script';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { config } from '~services';

const ym = (...args: any[]) => {
  (window.ym.a = window.ym.a || []).push(args);
};

export const useYandexMetrika = () => {
  const [loaded, setLoaded] = useState(false);
  const router = useRouter();

  useEffect(() => {
    if (!window.ym && config.IS_PRODUCTION) {
      window.ym = window.ym || ym;

      window.ym.l = new Date().getTime();

      loadScript('https://mc.yandex.ru/metrika/tag.js', () => {
        setLoaded(true);
        ym(config.NEXT_PUBLIC_YM_ID, 'init', {
          accurateTrackBounce: true,
          clickmap: true,
          trackLinks: true,
          webvisor: true,
        });
      });
    }
  }, []);

  useEffect(() => {
    if (loaded) {
      ym(config.NEXT_PUBLIC_YM_ID, 'hit', window.location.href);
    }
  }, [router.pathname, loaded]);
};
