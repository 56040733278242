import 'swiper/swiper.min.css';

import { BlockType, GalleryType } from 'pn-backend';
import React, { FC, useEffect, useState } from 'react';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Arrow } from '~assets';
import { Grid, Image, Text } from '~atoms';
import { Modal } from '~components';
import { useLayout } from '~hooks';
import { BlockProps } from '~types';

import styles from './styles.module.scss';
import { ArrowsProps, RendererProps } from './types';
import { findImageToShow, getAvailableArrows, getImageIndex } from './utils';

// ToDo Почему именован и вызывается как функция, а не как React компонент?
const galleryLineRenderer: FC<RendererProps> = ({
  imagesArr,
  isArticle = false,
  isCard = false,
  isNewsFull = false,
  setImage,
}) => {
  if (!imagesArr) return null;

  return (
    <>
      {imagesArr.map(({ caption, id, imageURL }, i) => {
        const handleClick = () => setImage(id);
        return (
          <Grid key={i} $width="100%" $display="block">
            <Grid
              onClick={handleClick}
              $cursor="pointer"
              $height={
                isCard || isArticle || isNewsFull
                  ? `calc(272px - (${imagesArr.length} - 1) * 67px)`
                  : `calc(436px - (${imagesArr.length} - 1) * 108px)`
              }
              $width="100%"
              $position="relative"
              $xl={{
                $height: `calc(272px - (${imagesArr.length} - 1) * 67px)`,
              }}
              $sm={{ $height: '194px' }}
            >
              <Image src={imageURL} alt={caption} />
            </Grid>
            <Grid $marginTop="10px">
              <Text
                $weight={400}
                $size={16}
                $lineHeight={22}
                $opacity="0.5"
                $xl={{ $lineHeight: 17, $size: 12 }}
              >
                {caption}
              </Text>
            </Grid>
          </Grid>
        );
      })}
    </>
  );
};

export const GalleryBlock: FC<BlockProps<BlockType.Gallery>> = ({ compiled: { images, type } }) => {
  const { isArticle, isCard, isNewsFull } = useLayout();

  const [currentImage, setCurrentImage] = useState<number>(0);

  const [imageToShow, setImageToShow] = useState<null | number>(null);
  const [arrowsAvailable, setArrowsAvailable] = useState<ArrowsProps>({
    isNextAvailable: false,
    isPrevAvailable: false,
  });
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isModalViewAvailable, setIsModalViewAvailable] = useState<boolean>(true);

  useEffect(() => {
    if (showModal) document.body.style.overflow = 'hidden';
    if (!showModal) document.body.style.overflow = 'auto';

    if (window.innerWidth < 500) {
      setIsModalViewAvailable(false);
    }
  }, [showModal]);

  // ToDo Воспользоваться обычным массивом и получать доступ по индексу
  const imagesWithId = images.map((imageArr, i) => {
    return { ...imageArr, id: i + 1 };
  });

  const setImage = (id: number) => {
    if (!isModalViewAvailable) return null;
    setImageToShow(id);
    const currentImageIndex = getImageIndex(imagesWithId, id);
    if (typeof currentImageIndex !== 'number') return null;
    const arrowsObj = getAvailableArrows(imagesWithId, currentImageIndex);
    setArrowsAvailable(arrowsObj);
    setShowModal(true);
    return null;
  };

  const onNext = () => {
    if (!imageToShow) return null;
    const currentImageIndex = getImageIndex(imagesWithId, imageToShow);
    if (typeof currentImageIndex !== 'number') return null;
    setImageToShow(imagesWithId[currentImageIndex + 1].id);
    const arrowsObj = getAvailableArrows(imagesWithId, currentImageIndex + 1);
    setArrowsAvailable(arrowsObj);
    return null;
  };

  const onPrev = () => {
    if (!imageToShow) return null;
    const currentImageIndex = getImageIndex(imagesWithId, imageToShow);
    if (typeof currentImageIndex !== 'number') return null;
    setImageToShow(imagesWithId[currentImageIndex - 1].id);
    const arrowsObj = getAvailableArrows(imagesWithId, currentImageIndex - 1);
    setArrowsAvailable(arrowsObj);
    return null;
  };

  const imageSrc = imageToShow ? findImageToShow(imagesWithId, imageToShow)?.imageURL : '';
  const imageCaption = imageToShow ? findImageToShow(imagesWithId, imageToShow)?.caption ?? '' : '';
  const imagesTotal = imagesWithId.length;
  const topLineLength = Math.ceil(imagesTotal / 2);
  const bottomLineLength = Math.floor(imagesTotal / 2);
  const topLine = imagesWithId.slice(0, topLineLength);
  const bottomLine = imagesWithId.slice(topLineLength);

  console.log('images', images);

  const onNextArrowClick = () => {
    if (currentImage + 1 < imagesTotal) {
      setCurrentImage(currentImage + 1);
    }
  };
  const onPrevArrowClick = () => {
    if (currentImage - 1 >= 0) {
      setCurrentImage(currentImage - 1);
    }
  };

  if (images.length === 0 || images[0]?.imageURL?.length === 0) return null;

  switch (type) {
    case GalleryType.Slider:
      return (
        <Grid
          className={styles.slider}
          $margin={isCard ? '0 auto 0' : '30px auto 0'}
          $sm={{ $margin: isCard ? '0 auto' : '30px auto 0' }}
        >
          <Grid>
            <Swiper
              slidesPerView="auto"
              spaceBetween={15}
              pagination={{
                el: '.swiper-pagination',
                formatFractionTotal: () => images.length,
                type: 'fraction',
              }}
              navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              }}
              modules={[Pagination, Navigation]}
              centeredSlides={currentImage !== 0}
              breakpoints={{
                576: {
                  spaceBetween: 20,
                },
              }}
            >
              {imagesWithId.map(({ caption, height, id, imageURL, width }, i) => {
                const handleClick = () => setImage(id);

                const aspectRatio = width / height;

                return (
                  <SwiperSlide key={i}>
                    {({ isActive }) => (
                      <Grid
                        $width={`${aspectRatio * 600}px`}
                        $maxWidth={isCard || isArticle || isNewsFull ? '750px' : '1200px'}
                        $xl={{
                          $width: `calc(${aspectRatio * 381}px)`,
                        }}
                        $sm={{
                          $width: `calc(${aspectRatio * 180}px)`,
                        }}
                      >
                        <>
                          <Grid
                            onClick={handleClick}
                            $cursor="pointer"
                            $zIndex={1}
                            $position="absolute"
                            $width={`${aspectRatio * 600}px`}
                            $maxWidth={isCard || isArticle || isNewsFull ? '750px' : '1200px'}
                            $maxHeight={isCard ? '337px' : '600px'}
                            $height={isCard ? '337px' : '80vh'}
                            $background={!isActive ? 'black-black' : undefined}
                            $opacity={!isActive ? '0.6' : undefined}
                            $transition="all 0.3s ease-in-out"
                            $xl={{
                              $height: '381px',
                              $width: `calc(${aspectRatio * 381}px)`,
                            }}
                            $sm={{
                              $height: '180px',
                              $width: `calc(${aspectRatio * 180}px)`,
                            }}
                          />
                          <Grid
                            $position="relative"
                            $maxWidth={isCard || isArticle || isNewsFull ? '750px' : '1200px'}
                            $maxHeight={isCard ? '337px' : '600px'}
                            $height={isCard ? '337px' : '80vh'}
                            $width={`${aspectRatio * 600}px`}
                            $xl={{
                              $height: '380px',
                              $width: `calc(${aspectRatio * 380}px)`,
                            }}
                            $sm={{
                              $height: '180px',
                              $width: `calc(${aspectRatio * 180}px)`,
                            }}
                          >
                            <Image src={imageURL} alt={caption} style={{ objectFit: 'contain' }} />
                          </Grid>
                        </>
                        <Grid
                          $marginTop="10px"
                          $width={`${aspectRatio * 600}px`}
                          $xl={{ $width: `calc(${aspectRatio * 380}px)` }}
                          $sm={{ $width: `calc(${aspectRatio * 180}px)` }}
                        >
                          <Text
                            $weight={400}
                            $size={16}
                            $lineHeight={22}
                            $textAlign="left"
                            $color={!isActive ? 'gray' : 'black'}
                            $sm={{ $lineHeight: 17, $size: 12 }}
                          >
                            {caption}
                          </Text>
                        </Grid>
                      </Grid>
                    )}
                  </SwiperSlide>
                );
              })}
              <Grid
                $bottom="0"
                $display="flex"
                $width={isCard || isArticle || isNewsFull ? '750px' : '1200px'}
                $margin="0 auto"
                $alignItems="center"
                $justifyContent="space-between"
                $marginTop="28px"
                $xl={{ $maxWidth: '768px' }}
                $md={{ $margin: '10px 0 0', $width: 'unset' }}
              >
                <Grid
                  className="swiper-pagination"
                  $display="flex"
                  $fontWeight="400"
                  $fontSize="24px"
                  $lineHeight="26px"
                />

                <Grid
                  $display="flex"
                  $width="100px"
                  $justifyContent="space-between"
                  $sm={{ $width: '90px' }}
                >
                  <Grid className="swiper-button-prev" $cursor="pointer" onClick={onPrevArrowClick}>
                    <Arrow />
                  </Grid>
                  <Grid
                    $transform="rotate(180deg)"
                    $cursor="pointer"
                    className="swiper-button-next"
                    onClick={onNextArrowClick}
                  >
                    <Arrow />
                  </Grid>
                </Grid>
              </Grid>
            </Swiper>
          </Grid>

          {isModalViewAvailable && (
            <Modal
              onClose={() => setShowModal(false)}
              isVisible={showModal}
              onNext={onNext}
              onPrev={onPrev}
              isNextAvailable={arrowsAvailable.isNextAvailable}
              isPrevAvailable={arrowsAvailable.isPrevAvailable}
            >
              {imageSrc && (
                <Image src={imageSrc} alt={imageCaption} style={{ objectFit: 'contain' }} />
              )}
            </Modal>
          )}
        </Grid>
      );

    case GalleryType.Tiles:
      return (
        <Grid
          $width="100%"
          $margin={isCard ? '0 auto 0' : '30px auto 0'}
          $xl={{ $maxWidth: '768px' }}
          $sm={{ $margin: isCard ? '0 auto' : '30px auto 0' }}
        >
          <Grid
            $width={isCard || isArticle || isNewsFull ? '100%' : '1200px'}
            $margin="0 auto"
            $gap="20px"
            $xl={{ $margin: isCard ? '0' : '0 15px', $width: 'unset' }}
          >
            <Grid
              $gridTemplateColumns={`${'1fr '.repeat(topLineLength)}`}
              $gap="20px"
              $sm={{ $gridTemplateColumns: '1fr' }}
            >
              {galleryLineRenderer({ imagesArr: topLine, isArticle, isCard, isNewsFull, setImage })}
            </Grid>
            <Grid
              $gridTemplateColumns={`${'1fr '.repeat(bottomLineLength)}`}
              $gap="20px"
              $sm={{ $gridTemplateColumns: '1fr' }}
            >
              {galleryLineRenderer({
                imagesArr: bottomLine,
                isArticle,
                isCard,
                isNewsFull,
                setImage,
              })}
            </Grid>
          </Grid>

          {isModalViewAvailable && (
            <Modal
              onClose={() => setShowModal(false)}
              isVisible={showModal}
              onNext={onNext}
              onPrev={onPrev}
              isNextAvailable={arrowsAvailable.isNextAvailable}
              isPrevAvailable={arrowsAvailable.isPrevAvailable}
            >
              {imageSrc && (
                <Image src={imageSrc} alt={imageCaption} style={{ objectFit: 'contain' }} />
              )}
            </Modal>
          )}
        </Grid>
      );

    default:
      return null;
  }
};
