import { Block, BlockCompiled, BlockType } from '../types';
import { CompileOptions } from '../types/common/CompileOptions';
// eslint-disable-next-line import/no-cycle
import { blockTypeContentCompileMap } from './blockTypeContentCompileMap';

export function compileBlock<T extends BlockType>(
  block: Block<T>,
  options: CompileOptions,
): BlockCompiled<T> {
  const data: BlockCompiled<T> = {
    compiled: blockTypeContentCompileMap[block.type](block, options),
    id: block.id,
    type: block.type,
  };

  return data;
}
