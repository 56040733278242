import { PostType } from 'pn-backend';
import { always } from 'ramda';

import { Article, Card, Custom, Longread, Test } from '~postLayouts';
import { PostLayoutComponentMap } from '~types';

export const postLayoutMap: PostLayoutComponentMap = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [PostType.Article]: Article,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [PostType.NewsFull]: Article,
  [PostType.Card]: Card,
  [PostType.Custom]: Custom,
  [PostType.Longread]: Longread,
  // Не может быть открыт как отдельный материал
  [PostType.News]: always(null),
  // Не может быть открыт как отдельный материал
  [PostType.Spec]: always(null),
  [PostType.Test]: Test,
};
