import { BlockType } from 'pn-backend';
import React, { FC } from 'react';

import { Grid, Text } from '~atoms';
import { Tooltips } from '~components';
import { useLayout } from '~hooks';
import { BlockProps } from '~types';

const defaultProps = {
  $margin: 'auto',
  $marginTop: '40px',
  $maxWidth: '750px',
  $width: '100%',
};

const cardProps = {
  $margin: 'auto',
  $marginTop: '0px',
  $maxWidth: '750px',
  $width: '100%',
};

export const TextBlock: FC<BlockProps<BlockType.Text>> = ({ compiled: { html } }) => {
  const { isAbout, isAdvertisers, isCard } = useLayout();

  if (!html) return null;
  let gridProps = defaultProps;
  if (isCard) {
    gridProps = cardProps;
  }

  let marginTop = '40px';
  if (isAbout || isAdvertisers) marginTop = '30px';
  if (isCard) marginTop = '0';

  return (
    <Grid
      {...gridProps}
      $marginTop={marginTop}
      $md={{ $maxWidth: 'unset', $padding: isCard ? '0' : '0 15px' }}
      $sm={{ $marginTop: isCard ? '0' : '30px' }}
    >
      <Tooltips html={html} />
      <Text
        $weight={400}
        $size={20}
        $lineHeight={30}
        $color="black"
        $display="grid"
        $gap="30px"
        $textWithLink
        $lg={{ $gap: '30px' }}
        $sm={{ $lineHeight: 27, $size: 18 }}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </Grid>
  );
};
