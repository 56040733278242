import { Tag } from 'draft-convert';

export function styleToHTML(style: string): Tag {
  const [type, color] = style.split('-');

  switch (type) {
    case 'ITALIC':
      return {
        end: '</em>',
        start: '<em style="font-style: italic;">',
      };
    case 'BOLD':
      return {
        end: '</strong>',
        start: '<strong style="font-weight: bold;">',
      };
    case 'HIGHLIGHT':
      return {
        end: '</span>',
        start: '<span class="highlight">',
      };
    case 'STRIKETHROUGH':
      return {
        end: '</del>',
        start: '<del>',
      };
    case 'SUBSCRIPT':
      return {
        end: '</sub>',
        start: '<sub>',
      };
    case 'SUPERSCRIPT':
      return {
        end: '</sup>',
        start: '<sup>',
      };
    case 'color':
      return {
        end: '</span>',
        start: `<span style="color: #${color};">`,
      };
    default:
      return null;
  }
}
