import { PostType, SectionPublic, TagPublic } from 'pn-backend';

import { PostProps } from '~types/post';

export const getSectionLink = (idOrAlias: SectionPublic['alias']) => `/s/${idOrAlias}`;
export const getTagLink = (alias: TagPublic['alias']) => `/t/${alias}`;

export const getPostLink = (id: PostProps['post']['id'], postType?: PostType) => {
  if (postType === PostType.NewsFull) {
    return `/n/${id}`;
  }

  return `/a/${id}`;
};
