import { BlockType } from 'pn-backend';
import React, { FC, useContext } from 'react';

import { Grid } from '~atoms';
import { CardContext } from '~providers';
import { BlockProps, BlockTypeToBlockComponentMap } from '~types';
import { renderChildBlock } from '~utils';

import { GalleryBlock } from '../GalleryBlock';
import { GifBlock } from '../GifBlock';
import { ImageBlock } from '../ImageBlock';
import { ListBlock } from '../ListBlock';
import { QuoteBlock } from '../QuoteBlock';
import { TextBlock } from '../TextBlock';
import { VideoBlock } from '../VideoBlock';
import { WidgetBlock } from '../WidgetBlock';
import { CardTitleBlock } from './CardTitleBlock';

const childBlockMap: BlockTypeToBlockComponentMap<BlockType.Card> = {
  [BlockType.Image]: ImageBlock,
  [BlockType.H2]: CardTitleBlock,
  [BlockType.Text]: TextBlock,
  [BlockType.Quote]: QuoteBlock,
  [BlockType.Gallery]: GalleryBlock,
  [BlockType.Video]: VideoBlock,
  [BlockType.Gif]: GifBlock,
  [BlockType.Widget]: WidgetBlock,
  [BlockType.List]: ListBlock,
};

export const CardBlock: FC<BlockProps<BlockType.Card>> = ({ compiled: { blocks = [] } }) => {
  const { cardIndex = 0, totalCards = 0 } = useContext(CardContext);
  const [h1Block, ...cardBlocks] = blocks;
  return (
    <Grid $display="flex" $flexFlow="column" $gap="30px">
      <Grid
        $display="flex"
        $flexFlow="row"
        $justifyContent="space-between"
        $fontSize="20px"
        $fontWeight="400"
      >
        {h1Block && [h1Block].map(renderChildBlock(childBlockMap))}
        <Grid $marginLeft="10px">{`${cardIndex + 1}/${totalCards}`}</Grid>
      </Grid>
      {cardBlocks?.map(renderChildBlock(childBlockMap))}
    </Grid>
  );
};
