// eslint-disable-next-line import/no-extraneous-dependencies
import { faker } from '@faker-js/faker';

import { Grid } from '../types';
import { CreateFactoryOptions } from './types/CreateFactoryOptions';
import { Factory } from './types/Factory';

faker.setLocale('ru');

export function createGridFactory(
  options?: CreateFactoryOptions,
): Factory<Grid> {
  let currentId = options?.initId || 0;

  return (data) => {
    const grid: Grid = {
      alias: faker.lorem.word(),
      createdAt: undefined,
      createdBy: undefined,
      createdById: data?.createdBy?.id,
      deletedAt: undefined,
      id: ++currentId,
      title: faker.lorem.sentence(),
      updatedAt: undefined,
      updatedBy: undefined,
      updatedById: data?.updatedBy?.id,
      ...data,
    } as Grid;

    return grid;
  };
}
