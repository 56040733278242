import { BlockType } from 'pn-backend';
import React, { FC } from 'react';

import { Grid, H3 } from '~atoms';
import { Tooltips } from '~components';
import { useLayout } from '~hooks';
import { BlockProps } from '~types';
import { removeParagraph } from '~utils';

const defaultProps = {
  $margin: 'auto',
  $marginBottom: '-10px',
  $marginTop: '50px',
  $maxWidth: '750px',
  $width: '100%',
};

export const H3Block: FC<BlockProps<BlockType.H3>> = ({ compiled: { html } }) => {
  const { layout } = useLayout();

  if (!html) return null;

  switch (layout) {
    case 'card':
      return (
        <Grid>
          <Tooltips html={html} />
          <H3
            $size={16}
            $lineHeight={24}
            $weight={400}
            $lg={{ $lineHeight: 25 }}
            $sm={{ $lineHeight: 20 }}
            dangerouslySetInnerHTML={{ __html: removeParagraph(html) }}
          />
        </Grid>
      );
    default:
      return (
        <Grid
          {...defaultProps}
          $md={{ $maxWidth: 'unset', $padding: '0 15px' }}
          $sm={{ $margin: '50px auto 0' }}
        >
          <Tooltips html={html} />
          <H3
            $sm={{ $lineHeight: 24, $size: 20 }}
            dangerouslySetInnerHTML={{ __html: removeParagraph(html) }}
          />
        </Grid>
      );
  }
};
