// eslint-disable-next-line import/no-extraneous-dependencies
import { faker } from '@faker-js/faker';

import { Tag } from '../types';
import { createSlug } from '../util';
import { CreateFactoryOptions } from './types/CreateFactoryOptions';
import { Factory } from './types/Factory';

faker.setLocale('ru');

export function createTagFactory(options?: CreateFactoryOptions): Factory<Tag> {
  let currentId = options?.initId || 0;

  return (data) => {
    const name = data?.name ?? faker.lorem.word();

    const tag: Tag = {
      alias: data?.alias ?? createSlug(name),
      createdAt: undefined,
      createdBy: undefined,
      createdById: data?.createdBy?.id,
      deletedAt: undefined,
      id: ++currentId,
      name,
      updatedAt: undefined,
      updatedBy: undefined,
      updatedById: data?.updatedBy?.id,
      ...data,
    } as Tag;

    return tag;
  };
}
