import Link from 'next/link';
import React, { FC } from 'react';

import { Grid, Image, Text } from '~atoms';
import { useLayout } from '~hooks';
import { GridPostCardProps } from '~types';
import { getPostPreviewSummary } from '~utils';

import styles from './styles.module.scss';

export const Test: FC<GridPostCardProps> = ({ post }) => {
  const { coverUrl, postLink, title } = getPostPreviewSummary(post);
  const { isBlackScience } = useLayout();

  const border = `3px solid ${isBlackScience ? 'var(--color-white)' : 'var(--color-black)'}`;

  return (
    <Link href={postLink} passHref className={styles.test}>
      <Grid
        $gridTemplateColumns="35% 65%"
        $height="335px"
        $background="black"
        $lg={{ $height: '260px' }}
        $md={{ $height: '205px' }}
        $sm={{ $gridTemplateColumns: '100%', $height: '100%' }}
      >
        <Grid $position="relative" $border={border}>
          {coverUrl && title ? (
            <>
              <Grid
                $zIndex={1}
                $position="absolute"
                $width="100%"
                $height="100%"
                className={styles.imageOverlay}
                $lg={{ $height: '100%' }}
                $sm={{ $height: '275px' }}
              />
              <Grid $height="100%" $lg={{ $height: '100%' }} $sm={{ $height: '275px' }}>
                <Image src={coverUrl.medium} alt={title} />
              </Grid>
            </>
          ) : null}
        </Grid>
        {title && (
          <Grid
            $padding="20px"
            $borderTop={border}
            $borderRight={border}
            $borderBottom={border}
            $lg={{ $padding: '15px' }}
            $sm={{
              $borderBottom: border,
              $borderLeft: border,
              $borderRight: border,
              $borderTop: 'none',
              $maxWidth: '100%',
              $overflow: 'hidden',
              $padding: '10px 15px 40px 15px',
              $position: 'relative',
            }}
          >
            <Grid>
              <Text
                className={styles.title}
                $weight={500}
                $size={44}
                $lineHeight={48}
                $color="white"
                $textTransform="uppercase"
                $htmlLineClamp={5}
                $lg={{ $lineHeight: 32, $size: 30 }}
                $sm={{ $lineHeight: 31, $size: 26 }}
                dangerouslySetInnerHTML={{ __html: title }}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Link>
  );
};
