import { captureException } from '@sentry/nextjs';
import React, { Component, ErrorInfo, PropsWithChildren } from 'react';

import { HttpStatusCode } from '~types';
import { log } from '~utils';

import { ErrorFallback } from '../ErrorFallback';

interface Props {
  fallback?: React.ReactNode;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends Component<PropsWithChildren<Props>, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    captureException(error);
    log('error', 'errorBoundary')({ error, errorInfo });
  }

  render() {
    const { children, fallback } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        fallback || (
          <ErrorFallback
            code={HttpStatusCode.InternalServerError}
            description="что-то пошло не так"
          />
        )
      );
    }

    return children;
  }
}
