import { BlockType, LineType } from 'pn-backend';
import React, { FC } from 'react';

import { Grid } from '~atoms';
import { BlockProps } from '~types';

export const LineBlock: FC<BlockProps<BlockType.Line>> = ({ compiled: { type } }) => {
  if (!type) return null;

  switch (type) {
    case LineType.Solid:
      return (
        <Grid
          $width="500px"
          $height="1px"
          $background="black"
          $margin="40px auto 0"
          $sm={{ $margin: '30px auto 0', $width: '200px' }}
        />
      );

    case LineType.Dots:
      return (
        <Grid
          $width="72px"
          $display="flex"
          $justifyContent="space-between"
          $margin="40px auto 0"
          $sm={{ $margin: '30px auto 0' }}
        >
          <Grid $background="black" $width="8px" $height="8px" $borderRadius="50%" />
          <Grid $background="black" $width="8px" $height="8px" $borderRadius="50%" />
          <Grid $background="black" $width="8px" $height="8px" $borderRadius="50%" />
        </Grid>
      );

    case LineType.Squares:
      return (
        <Grid
          $width="72px"
          $display="flex"
          $justifyContent="space-between"
          $margin="40px auto 0"
          $sm={{ $margin: '30px auto 0' }}
        >
          <Grid $background="black" $width="8.5px" $height="8.5px" $transform="rotate(45deg)" />
          <Grid $background="black" $width="8.5px" $height="8.5px" $transform="rotate(45deg)" />
          <Grid $background="black" $width="8.5px" $height="8.5px" $transform="rotate(45deg)" />
        </Grid>
      );

    default:
      return null;
  }
};
