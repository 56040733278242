import { useRouter } from 'next/router';
import { PostType, SearchModePublic, SearchSortDirection, SearchSortField } from 'pn-backend';
import React, { ChangeEventHandler, FC, KeyboardEventHandler, useEffect, useState } from 'react';

import { Grid } from '~atoms';
import { SearchInput } from '~components';
import { SearchProps } from '~types';
import { isString } from '~utils';

import { SearchSelect, SelectType } from './SearchSelect';

interface Props {
  closeSearch?: () => void;
  dir?: SearchSortDirection;
  isOpen?: boolean;

  mode?: SearchModePublic;

  postTypes?: PostType[];

  setFoundPosts?(posts: SearchProps['posts']): void;

  setMode?: (arg: SearchModePublic) => void;
  setPostTypes?: (arg: PostType[]) => void;

  setSearchTerm?(term: string): void;

  sort?: SearchSortField;
}

export const Search: FC<Props> = ({
  closeSearch,
  dir,
  isOpen,
  mode,
  postTypes = [
    PostType.NewsFull,
    PostType.Article,
    PostType.Card,
    PostType.Longread,
    PostType.Test,
  ],
  setFoundPosts,

  setMode,
  setPostTypes,
  setSearchTerm,
  sort,
}) => {
  const { pathname, push, query: { term: initialTerm = '' } = {}, replace } = useRouter();

  const [currentTerm, setCurrentTerm] = useState(isString(initialTerm) ? initialTerm : '');
  const isSearchPage = pathname.search('search') >= 1;

  useEffect(() => {
    if (isOpen) {
      setCurrentTerm('');
    }
  }, [isOpen]);

  const handleSearch = async () => {
    console.log('handleSearch');

    const trimmedTerm = currentTerm.trim();
    if (!trimmedTerm) return;

    if (setSearchTerm) setSearchTerm(trimmedTerm);

    replace({
      pathname: 'search',
      query: {
        dir,
        mode,
        sort,
        term: trimmedTerm,
        types: postTypes,
      },
    });
  };

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = async (event) => {
    if (currentTerm && event.key === 'Enter' && typeof window !== 'undefined') {
      const trimmedTerm = currentTerm.trim();

      if (!isSearchPage) {
        push({ pathname: '/search', query: { term: trimmedTerm } });
        if (closeSearch) closeSearch();
      } else {
        handleSearch();
      }
    }
  };

  const onChange: ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) => {
    setCurrentTerm(value);
  };

  const onClear = () => {
    setCurrentTerm('');

    if (isSearchPage && setFoundPosts && setSearchTerm) {
      setFoundPosts([]);
      setSearchTerm('');
      replace({ pathname: 'search' });
    }
  };

  const onSimpleClick = () => setMode && setMode(SearchModePublic.Simple);
  const onAllClick = () => setMode && setMode(SearchModePublic.Fulltext);

  const onSelectPostTypes = (types: SelectType[]) => {
    const typesValues = types?.map(({ value }) => {
      return value;
    });

    if (typesValues && setPostTypes) {
      setPostTypes(typesValues);
    }
  };

  useEffect(() => {
    if (pathname === '/search') {
      handleSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dir, sort, mode, postTypes]);

  return (
    <Grid
      $width="100%"
      $margin={isSearchPage ? '0 auto 40px' : '0 auto'}
      $maxWidth="1200px"
      $sm={{
        $margin: '0 auto',
      }}
    >
      <SearchInput
        value={currentTerm}
        autoFocus={isOpen}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
      {isSearchPage && (
        <Grid
          $display="flex"
          $gap="5px"
          $sm={{
            $display: 'grid',
            $gridTemplateAreas: '"button_one button_two" "select select"',
            $marginTop: '10px',
            $rowGap: '20px',
          }}
        >
          <Grid
            $color={SearchModePublic.Fulltext === mode ? 'white' : undefined}
            $display="flex"
            $padding="4px 14px"
            $justifyContent="center"
            $alignItems="center"
            $gap="10px"
            $fontSize="20px"
            $gridArea="button_one"
            $borderRadius="17px"
            $border="1px solid var(--postnews-blue, #1E4FBD)"
            $cursor="pointer"
            $background={SearchModePublic.Fulltext === mode ? '#1E4FBD' : undefined}
            onClick={onAllClick}
          >
            везде
          </Grid>
          <Grid
            $color={SearchModePublic.Simple === mode ? 'white' : undefined}
            $display="flex"
            $padding="4px 14px"
            $justifyContent="center"
            $alignItems="center"
            $gap="10px"
            $fontSize="20px"
            $gridArea="button_two"
            $borderRadius="17px"
            $border="1px solid var(--postnews-blue, #1E4FBD)"
            $cursor="pointer"
            $background={SearchModePublic.Simple === mode ? '#1E4FBD' : undefined}
            onClick={onSimpleClick}
          >
            по заголовкам
          </Grid>

          <Grid
            $height="100%"
            $width="1px"
            $background="#9A9A9A"
            $margin="0 20px"
            $sm={{ $display: 'none' }}
          />

          <Grid $gridArea="select">
            <SearchSelect postTypes={postTypes} onChange={onSelectPostTypes} />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
