import { File, FileType, StorageProviderType } from '../types';

const IMAGE_PROXY_SUPPORTED_FILE_TYPES = [FileType.Image];

export function isFileSupportsImageProxy<D = Date>(file: File<D>): boolean {
  return (
    file &&
    IMAGE_PROXY_SUPPORTED_FILE_TYPES.includes(file.type) &&
    file.provider === StorageProviderType.S3
  );
}
