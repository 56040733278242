import { cond, CondPair, pipe, propEq } from 'ramda';
import { ReactElement } from 'react';

import { TestProps, TestStep, TestStepComponentMap } from '~types';

import { renderTestStepByComponent } from './render';

export const testStepEquals = (testStep: TestStep) =>
  pipe<[TestProps], boolean>(propEq<keyof TestProps>('step', testStep));

export const renderTestStep = (testStepMap: TestStepComponentMap) =>
  cond<[TestProps], ReactElement>([
    ...Object.entries(testStepMap).map(
      ([testStep, Component]) =>
        [testStepEquals(testStep as TestStep), renderTestStepByComponent(Component)] as CondPair<
          [TestProps],
          ReactElement
        >,
    ),
  ]);
