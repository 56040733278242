import classNames from 'classnames';
import Link from 'next/link';
import React, { FC } from 'react';

import { Grid, Image, Tag, Text } from '~atoms';
import { useLayout } from '~hooks';
import { GridPostCardProps } from '~types';
import { getPostPreviewSummary } from '~utils';

import styles from './styles.module.scss';

export const Small: FC<GridPostCardProps> = ({ post }) => {
  const { coverUrl, postLink, tagLink, tagTitle, title } = getPostPreviewSummary(post);
  const { isBlackScience, isSport } = useLayout();

  return (
    <Grid
      // $minHeight="377px"
      // $lg={{ $minHeight: '238px' }}
      // $sm={{ $minHeight: '277px' }}
      $position="relative"
    >
      {tagLink && (
        <Link href={tagLink} passHref legacyBehavior>
          <Tag
            $blackScience={isBlackScience}
            $sport={isSport}
            $top="10px"
            $right="10px"
            $padding="2px 11px"
            $fontSize="16px"
            $lg={{
              $fontSize: '14px',
              $lineHeight: '22px',
              $padding: '3px 10px',
            }}
          >
            {tagTitle}
          </Tag>
        </Link>
      )}
      <Link
        href={postLink}
        passHref
        className={classNames(
          styles.small,
          isBlackScience && styles.blackscience,
          isSport && styles.sport,
        )}
      >
        <Grid $position="relative">
          {coverUrl && title ? (
            <>
              <Grid
                $zIndex={1}
                $position="absolute"
                $width="100%"
                $height="258px"
                className={styles.imageOverlay}
                $lg={{ $height: '200px' }}
                $md={{ $height: '157px' }}
                $sm={{ $height: '227px' }}
              />
              <Grid
                $height="258px"
                $width="100%"
                $position="relative"
                $lg={{ $height: '200px' }}
                $md={{ $height: '157px' }}
                $sm={{ $height: '227px' }}
              >
                {/* Используем medium, т.к. этот блок используется ReadMore, который скейлится */}
                <Image src={coverUrl.medium} alt={title} />
              </Grid>
            </>
          ) : null}
        </Grid>
        {title && (
          <Grid $padding="20px 0 0 0" $lg={{ $padding: '10px 0 0 0' }}>
            <Text
              $weight={600}
              $size={24}
              $lineHeight={26}
              $color={isBlackScience ? 'white' : 'black'}
              className={styles.title}
              $lg={{ $lineHeight: 18, $size: 16 }}
              $sm={{ $lineHeight: 20, $size: 18 }}
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </Grid>
        )}
      </Link>
    </Grid>
  );
};
