import { Tag } from 'draft-convert';
import { RawDraftContentBlock } from 'draft-js';

const defaultBlocks = [
  'unstyled',
  'paragraph',
  'header-one',
  'header-two',
  'header-three',
  'header-four',
  'header-five',
  'header-six',
  'blockquote',
  'ordered-list-item',
  'unordered-list-item',
  'media',
  'atomic',
];

export function blockToHTML(block: RawDraftContentBlock): Tag {
  const { data } = block;

  if (data?.textAlignment) {
    return {
      end: '</p>',
      start: `<p style="text-align: ${data.textAlignment};">`,
    };
  }

  if (defaultBlocks.indexOf(block.type) > -1) {
    return null;
  }

  return {
    end: '</p>',
    start: '<p>',
  };
}
