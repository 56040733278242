import { blockFactoryMap } from './blockFactoryMap';
import { BlockFactories } from './types/BlockFactories';
import { BlockFactory } from './types/BlockFactory';
import { CreateFactoryOptions } from './types/CreateFactoryOptions';

export function createBlockFactory(
  options: CreateFactoryOptions & {
    factories: Omit<BlockFactories, 'createBlock'>;
  },
): BlockFactory {
  let currentId = options?.initId || 0;

  const factories: BlockFactories = {
    ...options.factories,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    createBlock: null,
  };

  const createBlock: BlockFactory = (blockType, data) => {
    return blockFactoryMap[blockType](
      {
        ...data,
        id: ++currentId,
      },
      factories,
    );
  };

  factories.createBlock = createBlock;

  return createBlock;
}
