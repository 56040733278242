import Link from 'next/link';
import { PostType } from 'pn-backend';
import { anyPass, not } from 'ramda';
import React, { FC, useEffect, useRef, useState } from 'react';

import { Grid, Tag, Text } from '~atoms';
import { cardBlockMap } from '~blockMaps';
import { ShareButton, SMI2 } from '~components';
import { HEADER_HEIGHT } from '~constants';
import { usePost } from '~hooks';
import { CardProvider } from '~providers';
import { ReadMore } from '~readMore';
import { PostProps } from '~types';
import {
  formatFullDate,
  getPostSummary,
  isCardBlock,
  isCoverBlock,
  isDefined,
  isEmptyString,
  isH1Block,
  isH2Block,
  isIntroBlock,
  isMetaBlock,
  isSeoTitleBlock,
  renderPostBlock,
} from '~utils';

import style from './styles.module.scss';

const defaultProps = {
  $margin: 'auto',
  $maxWidth: '1200px',
  $minHeight: '50vh',
  $width: '100%',
};

export const Card: FC<PostProps<PostType.Card>> = ({
  nextPosts: readMorePosts,
  post: {
    authors,
    compiled: { blocks },
  },
}) => {
  const refs = useRef([]);
  const { post } = usePost();
  const { isAuthorVisible, isModificationDateVisible, modifiedAt, publishedAt, tagLink, tagTitle } =
    getPostSummary(post);

  const [currentPublishedAt, setCurrentPublishedAt] = useState<Nullable<string>>(null);
  const [currentModifiedAt, setCurrentModifiedAt] = useState<Nullable<string>>(null);

  const blocksBeforeDate = blocks.filter(anyPass([isH1Block, isSeoTitleBlock, isMetaBlock]));
  const blocksAfterDate = blocks.filter(anyPass([isCoverBlock, isIntroBlock]));

  const cardBlocks = blocks.filter(isCardBlock);
  const cardBlocksH2 = cardBlocks.map(({ compiled }) => compiled.blocks.filter(isH2Block));
  const cardBlocksTitles = cardBlocksH2.map((block) => {
    return block[0].compiled?.html;
  });

  const executeScroll = (ref: any) => {
    const { top } = ref.getBoundingClientRect();
    const scrollTop = top + document.documentElement.scrollTop;

    const header = document.getElementsByTagName('header')[0];
    const headerHeight = header.getBoundingClientRect().height;

    window.scrollTo({
      behavior: 'smooth',
      left: 0,
      top: scrollTop - headerHeight,
    });
  };

  useEffect(() => {
    setCurrentPublishedAt(publishedAt);
    setCurrentModifiedAt(modifiedAt);
  }, [publishedAt, modifiedAt]);

  return (
    <Grid $marginBottom="60px" $sm={{ $marginBottom: '40px' }}>
      <Grid
        {...defaultProps}
        $position="relative"
        $gridTemplateColumns="65% 35%"
        $xl={{
          $margin: '0 15px',
          $maxWidth: '100%',
        }}
        $lg={{
          $gridTemplateColumns: '100%',
          $margin: '0 auto',
          $maxWidth: '738px',
        }}
        $md={{
          $margin: '0 15px',
        }}
        $sm={{
          $gridTemplateColumns: 'auto',
          $margin: '0 15px',
          $maxWidth: 'unset',
          $width: 'unset',
        }}
      >
        <Grid
          $borderLeft="1px solid black"
          $borderRight="1px solid black"
          $borderBottom="1px solid black"
          $overflow="hidden"
        >
          <Grid
            $padding="0 50px 50px"
            $display="flex"
            $flexFlow="column"
            $borderBottom="1px solid"
            $lg={{ $padding: '0 20px 30px' }}
            $sm={{ $padding: '0 10px 30px' }}
          >
            {blocksBeforeDate.map(renderPostBlock(cardBlockMap))}
            <Grid
              $marginTop="30px"
              $marginBottom="10px"
              $display="flex"
              $alignItems="center"
              $gap="15px"
              $lg={{ $marginTop: '20px' }}
              $sm={{
                $alignItems: 'flex-start',
                $display: 'flex',
                $flexFlow: 'column-reverse',
                $gap: '20px',
                $marginBottom: '0',
              }}
            >
              {tagLink && (
                <Grid $position="relative" $width="auto" $sm={{ $alignItems: 'flex-start' }}>
                  <Link href={tagLink} passHref legacyBehavior>
                    <Tag
                      $transparent
                      $position="relative"
                      $top="unset"
                      $right="unset"
                      $sm={{ $fontSize: '14px', $padding: '3px 11px' }}
                    >
                      {tagTitle}
                    </Tag>
                  </Link>
                </Grid>
              )}
              <Grid
                $gridAutoFlow="column"
                $alignItems="center"
                $color="gray-dark"
                $gap="5px"
                $sm={{ $gap: '3px', $marginBottom: '0', $width: '100%' }}
              >
                {isDefined(currentPublishedAt) && not(isEmptyString(currentPublishedAt)) && (
                  <Grid>
                    <Text $size={14} $weight={500} $lineHeight={17} $textTransform="uppercase">
                      {formatFullDate(currentPublishedAt)}
                    </Text>
                  </Grid>
                )}
                {isModificationDateVisible &&
                  isDefined(currentModifiedAt) &&
                  not(isEmptyString(currentModifiedAt)) && (
                    <>
                      <Text $display="inline-flex" $alignItems="center">
                        &#8226;
                      </Text>
                      <Text
                        $size={14}
                        $lineHeight={17}
                        $weight={500}
                        $textTransform="uppercase"
                        $maxWidth="min-content"
                      >
                        UPD:
                      </Text>

                      <Grid>
                        <Text $size={14} $weight={500} $lineHeight={17} $textTransform="uppercase">
                          {formatFullDate(currentModifiedAt)}
                        </Text>
                      </Grid>
                    </>
                  )}
              </Grid>
            </Grid>
            {blocksAfterDate.map(renderPostBlock(cardBlockMap))}
          </Grid>
          {cardBlocks?.map((block, index) => {
            const isLast = index === cardBlocks.length - 1;
            return (
              <Grid
                key={block.id}
                ref={(ref) => {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  refs.current[index] = ref;
                }}
                $padding="50px 0"
                $margin="0 50px"
                $display="flex"
                $flexFlow="column"
                $gap="30px"
                $borderBottom={!isLast ? '1px solid' : ''}
                $lg={{ $margin: '0 20px', $padding: '30px 0' }}
                $sm={{ $margin: '0 10px', $padding: '40px 0' }}
              >
                <CardProvider cardIndex={index} totalCards={cardBlocks.length}>
                  <Grid $scrollMarginTop="30vh">{renderPostBlock(cardBlockMap)(block, index)}</Grid>
                </CardProvider>
              </Grid>
            );
          })}
          <Grid $height="1px" $width="100%" $background="black" />
          <Grid
            $maxWidth="750px"
            $width="100%"
            $padding="50px 50px"
            $display="flex"
            $justifyContent="space-between"
            $alignItems="center"
            $lg={{ $padding: '40px 20px' }}
            $sm={{
              $gap: '20px',
              $padding: '30px 10px',
            }}
          >
            {isDefined(authors[0]) && isAuthorVisible && (
              <Text $weight={600} $size={20} $lineHeight={22}>
                {authors[0].name}
              </Text>
            )}
            <ShareButton />
          </Grid>
        </Grid>
        <Grid
          $height={`calc(100vh - ${HEADER_HEIGHT})`}
          $top="70px"
          $position="sticky"
          $display="flex"
          $flexFlow="column"
          $padding="40px 50px"
          $gap="22px"
          $lg={{ $display: 'none' }}
        >
          <Text $weight={600} $size={26} $lineHeight={31} $textTransform="uppercase">
            Содержание:
          </Text>
          <ol className={style.containsList}>
            {cardBlocksTitles.map((title, index) => {
              return (
                <li key={index}>
                  <Text
                    $display="block"
                    $size={16}
                    $lineHeight={19}
                    $weight={600}
                    onClick={() => {
                      executeScroll(refs.current[index]);
                    }}
                    dangerouslySetInnerHTML={{ __html: title }}
                  />
                </li>
              );
            })}
          </ol>
        </Grid>
      </Grid>
      <ReadMore posts={readMorePosts} />
      <Grid
        $background="#F0F0F0"
        $width="100vw"
        $position="relative"
        $left="50%"
        $right="50%"
        $margin="60px -50vw 20px"
        $sm={{
          $margin: '60px -50vw 40px',
        }}
      >
        <SMI2 />
      </Grid>
    </Grid>
  );
};
