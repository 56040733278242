import { BlockChildMap } from './BlockChildMap';
import { BlockType } from './BlockType';

export const blockTypeChildMap: {
  [T in BlockType]: [
    BlockChildMap<T>[], // fixed default
    BlockChildMap<T>[], // default
    BlockChildMap<T>[], // additional
  ];
} = {
  [BlockType.Card]: [
    [BlockType.H2],
    [BlockType.Text],
    [
      BlockType.Text,
      BlockType.Quote,
      BlockType.Image,
      BlockType.Gallery,
      BlockType.Video,
      BlockType.Gif,
      BlockType.Widget,
      BlockType.List,
    ],
  ],
  [BlockType.Columns]: [[], [], []],
  [BlockType.Cover]: [[], [], []],
  [BlockType.Description]: [[], [], []],
  [BlockType.Gallery]: [[], [], []],
  [BlockType.Gif]: [[], [], []],
  [BlockType.H1]: [[], [], []],
  [BlockType.H2]: [[], [], []],
  [BlockType.H3]: [[], [], []],
  [BlockType.Image]: [[], [], []],
  [BlockType.Incut]: [[], [], []],
  [BlockType.Intro]: [[], [], []],
  [BlockType.Line]: [[], [], []],
  [BlockType.List]: [[], [], []],
  [BlockType.Meta]: [[], [], []],
  [BlockType.MoreOnTopic]: [[], [], []],
  [BlockType.NewsSource]: [[], [], []],
  [BlockType.Preview]: [[], [], []],
  [BlockType.Quiz]: [[], [], []],
  [BlockType.Quote]: [[], [], []],
  [BlockType.SeoTitle]: [[], [], []],
  [BlockType.SpecLink]: [[], [], []],
  [BlockType.Subtitle]: [[], [], []],
  [BlockType.Test]: [[], [BlockType.TestQuestion], [BlockType.TestQuestion]],
  [BlockType.TestQuestion]: [[], [], []],
  [BlockType.TestResults]: [[], [], []],
  [BlockType.Text]: [[], [], []],
  [BlockType.Video]: [[], [], []],
  [BlockType.Widget]: [[], [], []],
};
