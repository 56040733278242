import Link from 'next/link';
import React, { FC } from 'react';

import { Chevron } from '~assets';
import { Grid, LinkButton, Text } from '~atoms';
import { ButtonType } from '~types';

type Props = {
  href: string;
  type: ButtonType;
};

export const WatchAll: FC<Props> = ({ href, type }) => {
  return (
    <Link href={href} passHref legacyBehavior>
      <LinkButton $type={type}>
        <Text $size={20} $weight={500} $lineHeight={20} $transition="unset" $sm={{ $size: 16 }}>
          смотреть все
        </Text>

        <Grid
          $transform="rotate(270deg)"
          $width="20px"
          $height="12px"
          $margin="0 -4px"
          $color="inherit"
        >
          <Chevron />
        </Grid>
      </LinkButton>
    </Link>
  );
};
