import { PreviewImageFormat } from '../types';
import { CompileOptions } from '../types/common/CompileOptions';
import { ImageProxyGravity } from '../types/common/ImageProxyGravity';
import { ImageProxyOptions } from '../types/common/ImageProxyOptions';
import { ImageProxyResize } from '../types/common/ImageProxyResize';
import { BlockType } from '../types/db/block/BlockType';
import { CoverFormat } from '../types/db/block/common/CoverFormat';
import { GalleryType } from '../types/db/block/common/GalleryType';
import { ImageFormat } from '../types/db/block/common/ImageFormat';

export function getCompileOptions({
  domain,
}: {
  domain: string;
}): CompileOptions {
  const imageProxyOptions: ImageProxyOptions = {
    domain,
    gravity: ImageProxyGravity.CE,
    quality: 100,
    resize: ImageProxyResize.Auto,
  };

  const moreOnTopicOptions: ImageProxyOptions = {
    ...imageProxyOptions,
    height: 200,
    width: 300,
  };

  const coverFullscreenCaptionOptions: ImageProxyOptions = {
    ...imageProxyOptions,
    height: 700,
    width: 1440,
  };

  const coverFullscreenCaptionRightOptions: ImageProxyOptions = {
    ...imageProxyOptions,
    height: 1000,
    width: 1000,
  };

  const coverCaptionOptions: ImageProxyOptions = {
    ...imageProxyOptions,
    height: 420,
    width: 750,
  };

  const galleryTilesOptions: ImageProxyOptions = {
    ...imageProxyOptions,
    width: 1440,
  };

  const imageFullscreenOptions: ImageProxyOptions = {
    ...imageProxyOptions,
    width: 1440,
  };

  const imageGifOptions: ImageProxyOptions = {
    ...imageProxyOptions,
    width: 750,
  };

  const imageHeaderWidthOptions: ImageProxyOptions = {
    ...imageProxyOptions,
    width: 1200,
  };

  const imageContentWidthOptions: ImageProxyOptions = {
    ...imageProxyOptions,
    width: 750,
  };

  const imageContentHeightOptions: ImageProxyOptions = {
    ...imageProxyOptions,
    height: 750,
  };

  const imageHalfContentHeightOptions: ImageProxyOptions = {
    ...imageProxyOptions,
    height: 450,
  };

  const quizOptions: ImageProxyOptions = {
    ...imageProxyOptions,
    height: 300,
    width: 600,
  };

  const quoteOptions: ImageProxyOptions = {
    ...imageProxyOptions,
    height: 125,
    width: 125,
  };

  const testCoverOptions: ImageProxyOptions = {
    ...imageProxyOptions,
    height: 397,
    width: 738,
  };

  const testAnswersOptions: ImageProxyOptions = {
    ...imageProxyOptions,
    height: 228,
    width: 341,
  };

  const previewBigOptions: ImageProxyOptions = {
    ...imageProxyOptions,
    height: 430,
    width: 760,
  };

  const previewMediumOptions: ImageProxyOptions = {
    ...imageProxyOptions,
    height: 394,
    width: 590,
  };

  const previewMobileOptions: ImageProxyOptions = {
    ...imageProxyOptions,
    height: 227,
    width: 345,
  };

  const previewSmallOptions: ImageProxyOptions = {
    ...imageProxyOptions,
    height: 258,
    width: 388,
  };

  const previewWideOptions: ImageProxyOptions = {
    ...imageProxyOptions,
    height: 394,
    width: 1200,
  };

  const options: CompileOptions = {
    imageProxy: {
      [BlockType.Columns]: imageProxyOptions,
      [BlockType.Cover]: {
        [CoverFormat.FullscreenCaptionOver]: coverFullscreenCaptionOptions,
        [CoverFormat.FullscreenCaptionRight]:
          coverFullscreenCaptionRightOptions,
        [CoverFormat.FullscreenCaptionUnder]: coverFullscreenCaptionOptions,
        [CoverFormat.CaptionAbove]: coverCaptionOptions,
        [CoverFormat.CaptionUnder]: coverCaptionOptions,
      },
      [BlockType.Gallery]: {
        [GalleryType.Slider]: imageProxyOptions,
        [GalleryType.Tiles]: galleryTilesOptions,
      },
      [BlockType.Gif]: imageGifOptions,
      [BlockType.Image]: {
        [ImageFormat.Fullscreen]: imageFullscreenOptions,
        [ImageFormat.ContentWidth]: imageContentWidthOptions,
        [ImageFormat.ContentHeight]: imageContentHeightOptions,
        [ImageFormat.HalfContentHeight]: imageHalfContentHeightOptions,
        [ImageFormat.HeaderWidth]: imageHeaderWidthOptions,
      },
      [BlockType.MoreOnTopic]: moreOnTopicOptions,
      [BlockType.Preview]: {
        [PreviewImageFormat.Big]: previewBigOptions,
        [PreviewImageFormat.Medium]: previewMediumOptions,
        [PreviewImageFormat.Mobile]: previewMobileOptions,
        [PreviewImageFormat.Small]: previewSmallOptions,
        [PreviewImageFormat.Wide]: previewWideOptions,
      },
      [BlockType.Quiz]: quizOptions,
      [BlockType.Quote]: quoteOptions,
      [BlockType.TestQuestion]: {
        answers: testAnswersOptions,
        cover: testCoverOptions,
      },
      [BlockType.TestResults]: {
        average: testCoverOptions,
        maximum: testCoverOptions,
        minimum: testCoverOptions,
      },
      default: imageProxyOptions,
    },
  };

  return options;
}
