export function fromBase64(input: string): string {
  if (typeof Buffer !== 'undefined') {
    return Buffer.from(input, 'base64').toString('ascii');
  }

  if (typeof atob !== 'undefined') {
    return atob(input);
  }

  return input;
}
