// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { FC, useLayoutEffect, useState } from 'react';

type AdbBlockBannerArticleProps = {
  onLoad: () => void;
};

export const AdbBlockBannerArticle: FC<AdbBlockBannerArticleProps> = ({ onLoad }) => {
  const [isBannerExist, setBannerExist] = useState();

  useLayoutEffect(() => {
    if (isBannerExist) return;
    setBannerExist(true);

    // yandex_pn.glavred
    // Площадка: Postnews
    // Тип баннера: 1x1
    // Расположение: верх страницы

    window.yaContextCb.push(() => {
      Ya.adfoxCode.create({
        containerId: 'adfox_170013487293028742',
        onLoad,
        ownerId: 1479532,
        params: {
          p2: 'frfe',
          pp: 'g',
          ps: 'gqer',
        },
      });
    });
  }, [isBannerExist, onLoad]);

  return (
    <div id="PShapedBanner">
      <div id="adfox_170013487293028742" />
    </div>
  );
};
