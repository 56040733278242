import React, { FC } from 'react';

import { Grid } from '~atoms';
import { Test } from '~postCards';
import { GridLayoutProps } from '~types';

export const SomeTests: FC<GridLayoutProps> = ({ posts = [] }) => (
  <Grid
    $marginTop="20px"
    $marginBottom="20px"
    $gap="40px"
    $zIndex="10"
    $lg={{ $gap: '30px', $marginBottom: '0', $marginTop: '0' }}
  >
    {posts.map((post) => (
      <Grid
        key={post.id}
        $margin="auto"
        $maxWidth="1200px"
        $width="100%"
        $display="flex"
        $flexFlow="column"
        $gap="30px"
        $padding="0"
        $xl={{ $maxWidth: '100%', $padding: '0 15px' }}
      >
        <Test post={post} />
      </Grid>
    ))}
  </Grid>
);
