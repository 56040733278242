import React, { FC, useCallback, useLayoutEffect, useRef, useState } from 'react';

import { Grid } from '~atoms';
import { HEADER_HEIGHT } from '~constants';
import { getElemPageY } from '~utils';

type AdbBlockBannerMainProps = {
  onLoad: () => void;
};

export const AdbBlockBannerMain: FC<AdbBlockBannerMainProps> = ({ onLoad }) => {
  const imageRef = useRef(null);
  const [isBannerExist, setBannerExist] = useState(false);
  const [isBannerVisible, setIsBannerVisible] = useState(false);

  const newsBlockTop = getElemPageY(document.getElementById('main_news_block'));
  const imageBlockTop = getElemPageY(imageRef.current);

  const handleLoad = useCallback(() => {
    onLoad();
    setIsBannerVisible(true);
  }, [onLoad]);

  useLayoutEffect(() => {
    if (isBannerExist) return;

    setBannerExist(true);

    window.yaContextCb.push(() => {
      // yandex_pn.glavred
      // Площадка: Postnews
      // Тип баннера: 1x1
      // Расположение: середина страницы

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      Ya.adfoxCode.create({
        containerId: 'adfox_170039302879923754',
        onLoad: handleLoad,
        ownerId: 1479532,
        params: {
          p2: 'frfe',
          pp: 'h',
          ps: 'gqer',
        },
      });
    });
  }, [isBannerExist, handleLoad]);

  return (
    <Grid
      $display={isBannerVisible ? 'grid' : 'none'}
      $position="absolute"
      $width="100%"
      $height={`calc(${newsBlockTop}px - ${imageBlockTop}px)`}
      ref={imageRef}
    >
      <Grid
        $position="sticky"
        $height="fit-content"
        $top={HEADER_HEIGHT}
        // $left={`calc((100% - ${MAX_BANNER_WIDTH}) / 2)`}
        $width="100vw"
        $left="50%"
        $right="50%"
        $margin="0 -50vw 0"
      >
        <div id="PShapedBanner">
          <div id="adfox_170039302879923754" />
        </div>
      </Grid>
    </Grid>
  );
};
