import { BlockType } from 'pn-backend';
import React, { FC } from 'react';

import { usePost } from '~hooks';
import { Meta } from '~meta';
import { BlockProps } from '~types';
import { getFirstString, getPostSummary } from '~utils';

export const DescriptionBlock: FC<BlockProps<BlockType.Description>> = ({ compiled }) => {
  const { post } = usePost();

  if (compiled?.text) {
    const { text } = compiled;
    const { postIntro, postSubtitle, postText } = getPostSummary(post);

    const introFirstString = getFirstString(postIntro).trim();
    const textFirstString = getFirstString(postText).trim();

    const description = text.trim().length > 0 ? text : postSubtitle;
    const ogDescription = postSubtitle.trim() || introFirstString || textFirstString;

    return <Meta description={description} ogDescription={ogDescription} />;
  }
  return null;
};
