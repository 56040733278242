import { PostType } from 'pn-backend';
import React, { createContext, FC, PropsWithChildren, useMemo } from 'react';

import { PostProps } from '~types';

type Context = PostProps;

const initialValues: Context = {
  nextPosts: [],
  post: {
    authors: [],
    compiled: { blocks: [] },
    id: 0,
    isAffiliateArticle: false,
    isAuthorVisible: false,
    isModificationDateVisible: false,
    modifiedAt: '',
    publishedAt: '',
    tags: [],
    type: PostType.Custom,
  },
};

export const PostContext = createContext<Context>(initialValues);

export const PostProvider: FC<PropsWithChildren<Partial<PostProps>>> = ({
  children,
  nextPosts = initialValues.nextPosts,
  post = initialValues.post,
}) => {
  const value = useMemo(() => ({ nextPosts, post }), [nextPosts, post]);
  return <PostContext.Provider value={value}>{children}</PostContext.Provider>;
};
