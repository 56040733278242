import { inc } from 'ramda';
import React, { createContext, FC, PropsWithChildren, useMemo, useReducer } from 'react';

import { TestStep } from '~types';
import { noop } from '~utils';

enum TestAction {
  MoveNextStep = 'MOVE_NEXT_STEP',
  SetAnswer = 'SET_ANSWER',
}

type State = {
  currentQuestion: number;
  currentStep: TestStep;
  isLastQuestion: boolean;
  questionAnswers: { [index: number]: boolean };
  totalQuestions: number;
};

interface Context {
  moveNextStep: () => void;
  setAnswer: (isValid: boolean) => void;
  state: State;
}

interface Props {
  values: Pick<State, 'totalQuestions'>;
}

type Action =
  | {
      payload: { isValid: boolean };
      type: TestAction.SetAnswer;
    }
  | {
      type: TestAction.MoveNextStep;
    };

const initialValues: State = {
  currentQuestion: 0,
  currentStep: TestStep.Start,
  isLastQuestion: false,
  questionAnswers: {},
  totalQuestions: 0,
};

const reducer = (state: State, action: Action) => {
  const { currentQuestion, currentStep, isLastQuestion, questionAnswers, totalQuestions } = state;

  switch (action.type) {
    case TestAction.MoveNextStep: {
      switch (currentStep) {
        case TestStep.Start:
          return {
            ...state,
            currentStep: TestStep.Questions,
          };
        case TestStep.Questions:
          if (isLastQuestion) {
            return {
              ...state,
              currentStep: TestStep.Result,
            };
          }
          return {
            ...state,
            currentQuestion: inc(currentQuestion),
            isLastQuestion: inc(currentQuestion) + 1 === totalQuestions,
          };

        default:
          return state;
      }
    }
    case TestAction.SetAnswer: {
      return {
        ...state,
        questionAnswers: { ...questionAnswers, [currentQuestion]: action.payload.isValid },
      };
    }
    default: {
      return state;
    }
  }
};

export const TestContext = createContext<Context>({
  moveNextStep: noop,
  setAnswer: noop,
  state: initialValues,
});

export const TestProvider: FC<PropsWithChildren<Props>> = ({ children, values }) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialValues,
    ...values,
    isLastQuestion: values.totalQuestions === 1,
  });

  const value: Context = useMemo(
    () => ({
      moveNextStep: () => {
        dispatch({ type: TestAction.MoveNextStep });
      },
      setAnswer: (isValid: boolean) => {
        dispatch({ payload: { isValid }, type: TestAction.SetAnswer });
      },
      state,
    }),
    [dispatch, state],
  );

  return <TestContext.Provider value={value}>{children}</TestContext.Provider>;
};
