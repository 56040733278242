// eslint-disable-next-line import/no-extraneous-dependencies
import { faker } from '@faker-js/faker';

import { Employee, EmployeeRole, EmployeeStatus } from '../types';
import { CreateFactoryOptions } from './types/CreateFactoryOptions';
import { Factory } from './types/Factory';

faker.setLocale('ru');

export function createEmployeeFactory(
  options?: CreateFactoryOptions,
): Factory<Employee> {
  let currentId = options?.initId || 0;

  return (data) => {
    const employee: Employee = {
      authors: [],
      auths: [],
      avatar: undefined,
      avatarId: data?.avatar?.id,
      createdAt: undefined,
      createdBy: undefined,
      createdById: data?.createdBy?.id,
      deletedAt: undefined,
      id: ++currentId,
      name: faker.name.fullName(),
      role: faker.helpers.objectValue(EmployeeRole),
      status: faker.helpers.objectValue(EmployeeStatus),
      updatedAt: undefined,
      updatedBy: undefined,
      updatedById: data?.updatedBy?.id,
      ...data,
    } as Employee;

    return employee;
  };
}
