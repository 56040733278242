// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { FC, useLayoutEffect } from 'react';

import { Grid } from '~atoms';

export const AdvBlock: FC = () => {
  useLayoutEffect(() => {
    window.yaContextCb?.push(() => {
      window.Ya?.adfoxCode.create({
        containerId: 'adfox_16893262372967778',
        ownerId: 1479532,
        params: {
          p1: 'cyqxr',
          p2: 'iluu',
        },
      });
    });
  }, []);

  // yandex_pn.glavred
  // Площадка: Postnews / Главная / Сайдбар - 300х600
  // Категория: <не задана>
  // Тип баннера: Сайдбар - 300х600s

  return (
    <Grid $width="300px" $height="600px" $position="relative" $marginTop="30px">
      <div id="adfox_16893262372967778" />
    </Grid>
  );
};
