import { Tag } from 'draft-convert';
import { RawDraftEntity } from 'draft-js';
import * as uuid from 'uuid';

export function entityToHTML(entity: RawDraftEntity, text: string): Tag {
  if (entity.type === 'LINK') {
    const href = encodeURI(decodeURIComponent(entity.data.url)).replace(
      /'/g,
      '%27',
    );

    return {
      end: '</a>',
      start: `<a href="${href}" target="_blank" rel="noopener noreferrer">`,
    };
  }

  if (entity.type === 'TOOLTIP') {
    const { body } = entity.data;

    const tooltipId = `${uuid.v4()}-tooltip`;

    return {
      end: '</span>',
      start: `<span id="${tooltipId}" class="textTooltip" data-tooltip-content="${body}">`,
    };
  }

  return text;
}
